import React from 'react'
import CornerMenu from '../../Auth/CornerMenu';
import NewCertificateModal from './Modals/NewCertificateModal';
import NewLicenseModal from './Modals/NewLicenseModal';
import NewPositionModal from './Modals/NewPositionModal';
import NewSkillModal from './Modals/NewSkillModal';

const OtherPage = () => {


    return (
      <div className='logged-in-container'>
        <CornerMenu/>
        
        <p className='form-header'>Other Settings</p>

        <br/><br/><hr/>

        <div>
            <p className='input-label'> Positions </p>
            <button data-bs-toggle="modal" data-bs-target="#showNewPositionModal" style={{paddingLeft:20, paddingRight:20}} className='custom_button'>Enter new position</button>
        </div>
        <hr/>
        <div style={{marginTop:30}}>
            <p className='input-label'> Skills </p>
            <button data-bs-toggle="modal" data-bs-target="#showNewSkillModal" style={{paddingLeft:20, paddingRight:20}} className='custom_button'>Enter new skill</button>
        </div>
        <hr/>
        <div style={{marginTop:30}}>
            <p className='input-label'> Certificates </p>
            <button data-bs-toggle="modal" data-bs-target="#showNewCertificateModal" style={{paddingLeft:20, paddingRight:20}} className='custom_button'>Enter new certificate</button>
        </div>
        <hr/>
        <div style={{marginTop:30}}>
            <p className='input-label'> Licenses </p>
            <button data-bs-toggle="modal" data-bs-target="#showNewLicenseModal" style={{paddingLeft:20, paddingRight:20}} className='custom_button'>Enter new license</button>
        </div>

        <NewPositionModal/>
        <NewSkillModal/>
        <NewCertificateModal/>
        <NewLicenseModal/>

      </div>
    )
}

export default OtherPage;