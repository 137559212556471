import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';


export const ApprovedBanner = () => {
    return (
        <div
            style={{
                borderRadius:'8px',
                backgroundColor: '#01b89a',
                color:'#fff',
                textAlign:'center',
                height:100,
                lineHeight:6,
                marginBottom:25

            }}
        >
            <DoneIcon /> Profile Approved
        </div>
    )
}

export const DeclinedBanner = () => {
    return (
        <div
            style={{
                borderRadius:'8px',
                backgroundColor: '#ee5566',
                color:'#fff',
                textAlign:'center',
                height:100,
                lineHeight: 6,
                marginBottom:25
            }}
        >
            <CloseIcon /> Profile Declined
        </div>
    )
}

export const RejectedBanner = () => {
    return (
        <div
            style={{
                borderRadius:'8px',
                backgroundColor: 'black',
                color:'#fff',
                textAlign:'center',
                height:100,
                lineHeight: 6,
                marginBottom:25
            }}
        >
            <CloseIcon /> Profile Rejected
        </div>
    )
}



