const FilterButton = props => {
    return (
        <button style={{
            borderRadius:18,
            border: 'solid 1px #839eaf',
            padding:'6px 15px',
            color: props.active ? 'white' :'#839eaf',
            backgroundColor: props.active ? '#0099ee' :'white',
            marginRight:10
        }} onClick={props.onClick}>
            {props.name}
        </button>
    )
}

export default FilterButton;
