import React, {useState} from 'react';
// import './style.css'

const InviteStaff = ({do_send_invitation}) => {
  // const [start_date, set_start_date ] = useState(new Date('2021/02/22'));

  const [invited_email, set_invited_email] = useState('');
  const [role, set_role] = useState('Recruiter');
  

  const handle_invite =  e => {
    e.preventDefault();

    const payload = {
      email: invited_email,
      role,
    };
    do_send_invitation.mutate(payload);
  };


  return (
    <div
      className="modal fade"
      id="inviteModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div
          style={{borderRadius: 18, marginTop: '20%', padding: 10}}
          className="modal-content">
          <form onSubmit={handle_invite}>
            <div className="modal-body">
              <p className="input-label">Email</p>
              <input
                value={invited_email}
                onChange={e => set_invited_email(e.target.value)}
                style={styles.input_field}
              />

              <p className="input-label">Role</p>
              {/* <input style={styles.input_field} /> */}
              <select
                onChange={e => set_role(e.target.value)}
                value={role}
                style={styles.input_field}>
                <option value="Recruiter">Recruiter</option>
                <option value="Admin">Admin</option>
              </select>
            </div>

            <div className="modal-footer">
              <button
                style={styles.reset}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">
                Close
              </button>
              <button
                disabled={do_send_invitation.isLoading}
                type="submit"
                style={styles.ok}
                className="custom_button">
                {do_send_invitation.isLoading
                  ? 'Loading...'
                  : 'Send Invitation'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  ok: {
    backgroundColor: '#249afa',
    border: 'none',
    width: '50%',
    color: 'white',
    borderRadius: 4,
    fontWeight: 500,
    height: 40,
  },
  info: {
    color: '#445566',
    fontWeight: 'bold',
  },
  input_field: {
    border: 'solid 1px #aabbcc',
    borderRadius: 18,
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 10,
    width: '100%',
    marginBottom: 20,
  },
  reset: {
    background: 'none',
    border: 'none',
    width: '25%',
    fontWeight: 500,
    height: 40,
    color: '#839eaf',
  },
};

export default InviteStaff;
