
import { useMutation } from "react-query"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from "../../global_state";
import { convert_job_seeker_to_company } from "../../services/approvals";


const useConvertoToJP = (user_id) => {
    const set_success = useSetRecoilState(success_message)
    const set_error = useSetRecoilState(error_message)
    const history = useHistory()
    return useMutation( (id)=>convert_job_seeker_to_company(id), {
        onSuccess: data => {
            set_success('Successfully coverted user to company')
            setTimeout(() => {
                set_success('')
              }, 5000);
            history.push(`/js/approvallist/`)
        },
        onError: () => {
            set_error('There was a problem! Please try again later!')
            setTimeout(() => {
              set_error('')
            }, 4000);
          }
    })
}

export default useConvertoToJP;