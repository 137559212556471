import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useJobListingDetail from "../../../../customHooks/Jobs/useJobListingDetail";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import ListingLoading from "../../../Other/Skeletons/ListingLoading";
import useListingSkills from "../../../../customHooks/Jobs/useListingsSkills";
import useListingLanguages from "../../../../customHooks/Jobs/useListingLanguages";
import useTestAlgorithm from "../../../../customHooks/Jobs/useTestAlgorithm";
import CallMade from "@material-ui/icons/CallMade";
import ModalListJS from "./ModalListJS";

const ListingDetailPage = () => {
  const listing_id = useParams().listing_id;
  const [users, set_users] = useState([])
  const [modal_title, set_modal_title] = useState('')

  const { data: listing_detail, isLoading: info_loading } =
    useJobListingDetail(listing_id);

  const { data: skills_data, isLoading: skills_loading } =
    useListingSkills(listing_id);

  const { data: languages_data, isLoading: languages_loading } =
    useListingLanguages(listing_id);

  const { data: matched_job_seekers, refetch: refetch_matched_job_seekers, isLoading: algorithm_loding} =
    useTestAlgorithm(listing_id);

  const history = useHistory();
  if (info_loading || skills_loading || languages_loading) {
    return <ListingLoading />;
  }


  const handle_test_algorithm = (e) => {
    e.preventDefault()
    refetch_matched_job_seekers();
  }


  return (
    <div className="logged-in-container">
      <button
        onClick={(e) => {
          e.preventDefault();
          history.go(-1);
        }}
        className="form-header invisible-button"
      >
        <ArrowBackIcon
          style={{ marginBottom: 4, opacity: 0.6 }}
          fontSize="large"
        />{" "}
        Go back
      </button>

      <table className="job-listing-table">
        <tbody>
          <tr>
            <td  className="form-header">
              Job Posting <hr />
            </td>
            <td  >
            <button className="btn btn-primary" onClick={handle_test_algorithm}>
              <i className="fa fa-play"></i>{' '} Test algorithm
            </button>
              
            </td>
          </tr>
          {matched_job_seekers && 
          <>
          <tr className="bg-success m-2">
            <td className="text-white">{matched_job_seekers.job_seekers_matched.length} Job Seeker(s) would match</td>
            <td></td>
          </tr>
          {
            matched_job_seekers.job_seekers_matched.map(js => {
              return (
                <tr key={js.js_id} className="bg-success  m-2" >
                  <td colSpan={2} className="input-label text-white p-2">
                    {js.full_name}{' '}
                    <a href={`/js/approvallist/${js.js_id}`}  className="text-white" target="__blank"> <CallMade fontSize="small" /> </a> 
                  </td>
                  
                </tr>
              )
            })
          }
          {matched_job_seekers.filters &&
          <tr>
            <td>Details about filtering</td>
            <td className="text-left">
              <ul>
                <li>Interested: {matched_job_seekers.filters.position}</li>
                <li>Approved by system: {matched_job_seekers.filters.approval}</li>
                <li>Available for work: {matched_job_seekers.filters.available}</li>
                <li 
                  onClick={(e)=>{
                    set_users(matched_job_seekers.filters.languages_js);
                    set_modal_title('Passed languages requirement')
                  }} 
                  className="cursor-pointer mt-2 text-primary" data-bs-toggle="modal" data-bs-target="#modalListJS">
                    Passed languages requirement: {matched_job_seekers.filters.languages}
                </li>
                <li 
                  onClick={(e)=>{
                    set_users(matched_job_seekers.filters.rate_per_hour_js);
                    set_modal_title('Passed Rate per hour requirement')
                  }} 
                  className="cursor-pointer mt-2 text-primary" data-bs-toggle="modal" data-bs-target="#modalListJS"
                >
                  Passed Rate per hour requirement: {matched_job_seekers.filters.rate_per_hour}
                </li>
                <li 
                  onClick={(e)=>{
                    set_users(matched_job_seekers.filters.considered_previously_js);
                    set_modal_title('Weren\'t considered before by the company')
                  }} 
                  className="cursor-pointer mt-2 text-primary" data-bs-toggle="modal" data-bs-target="#modalListJS"
                >
                  Weren't considered before by the company: {matched_job_seekers.filters.considered_previously}
                </li>
                <li 
                  onClick={(e)=>{
                    set_users(matched_job_seekers.filters.certificates_js);
                    set_modal_title('Passed Certificates requirement');
                  }} 
                  className="cursor-pointer mt-2 text-primary" data-bs-toggle="modal" data-bs-target="#modalListJS"
                >
                  Passed Certificates requirement: {matched_job_seekers.filters.certificates}
                </li>
                <li 
                  onClick={(e)=>{
                    set_users(matched_job_seekers.filters.licenses_js);
                    set_modal_title('Passed Licenses requirement');
                  }} 
                  className="cursor-pointer mt-2 text-primary" data-bs-toggle="modal" data-bs-target="#modalListJS"
                >
                  Passed Licenses requirement: {matched_job_seekers.filters.licenses}
                </li>
                <li 
                  onClick={(e)=>{
                    set_users(matched_job_seekers.filters.successfully_passed_js);
                    set_modal_title('Passed experience requirement');
                  }}
                  className="cursor-pointer mt-2 text-primary" data-bs-toggle="modal" data-bs-target="#modalListJS"
                >
                  Passed experience requirement: {matched_job_seekers.filters.successfully_passed}
                </li>
                <li 
                  onClick={(e)=>{
                    set_users(matched_job_seekers.filters.address_js);
                    set_modal_title('Passed Address requirement');
                  }}
                  className="cursor-pointer mt-2 text-primary" data-bs-toggle="modal" data-bs-target="#modalListJS"
                >
                  Passed Address requirement - final: {matched_job_seekers.filters.address}
                </li>
              </ul>
            </td>
          </tr>
        }
          </>
          }
          {algorithm_loding && 
          <tr className="bg-success m-2">
            <td className="text-white">Loading...</td>
            <td></td>
           </tr>

          }
          <tr>
            <td className="input-label">Position</td>
            <td className="table-value">
              {listing_detail.position.name} - {listing_detail.company.name}
            </td>
          </tr>

          <tr>
            <td className="input-label">Employment type</td>
            <td className="table-value">{listing_detail.job_type}</td>
          </tr>

          <tr>
            <td className="input-label">Permanent/Temporary</td>
            <td className="table-value">{listing_detail.job_all_length}</td>
          </tr>

          <tr>
            <td className="input-label">Approval</td>
            <td className="table-value">
              {listing_detail.approve_yourself ? "By company" : "By platform"}
            </td>
          </tr>

          <tr>
            <td className="input-label">Number of Employees needed</td>
            <td className="table-value">
              {listing_detail.nr_employees_wanted}
            </td>
          </tr>

          <tr>
            <td className="input-label">Address</td>
            <td className="table-value">
              {listing_detail.address}
              <br />
              {listing_detail.city.name}, {listing_detail.province.name},{" "}
              {listing_detail.zip_code}, {listing_detail.country.name}
            </td>
          </tr>

          <tr>
            <td className="input-label">Permanent/Temporary</td>
            <td className="table-value">{listing_detail.job_all_length}</td>
          </tr>

          <tr>
            <td className="input-label">Shift</td>
            <td className="table-value">{listing_detail.shift}</td>
          </tr>

          <tr>
            <td colSpan="2" className="form-header">
              Job Time Length <hr />
            </td>
          </tr>

          <tr>
            <td className="input-label">Daily Hours</td>
            <td className="table-value"> {listing_detail.daily_hours} </td>
          </tr>

          <tr>
            <td className="input-label">Rate per Hour</td>
            <td className="table-value"> ${listing_detail.rate_per_hour} </td>
          </tr>

          <tr>
            <td className="input-label"> Job length</td>
            <td className="table-value"> {listing_detail.job_time_length}</td>
          </tr>

          <tr>
            <td className="input-label">How many times</td>
            <td className="table-value">
              {" "}
              {listing_detail.job_time_length_quantity}
            </td>
          </tr>

          <tr>
            <td className="input-label">Start date</td>
            <td className="table-value">
              {" "}
              {listing_detail.start_date
                ? new Intl.DateTimeFormat("en-US", {
                    dateStyle: "medium",
                  }).format(Date.parse(listing_detail.start_date))
                : "Not povided"}
            </td>
          </tr>

          {/* Skills */}
          <tr>
            <td colSpan="2" className="form-header">
              Skills and Languages
              <hr />
            </td>
          </tr>

          <tr>
            <td className="input-label">Required Skills</td>
            <td className="table-value">
              {skills_data
                .filter((s) => s.required)
                .map((e) => (
                  <div key={e.skill.id}>
                    <p style={{ margin: 0 }}>{e.skill.name}</p>
                    <p style={{ fontSize: "16px", color: "#839eaf" }}>
                      {e.importance} pts; {e.years_of_experience} years of
                      experience
                    </p>
                  </div>
                ))}
              <hr />
            </td>
          </tr>

          <tr>
            <td className="input-label">Optional Skills</td>
            <td className="table-value">
              {skills_data
                .filter((s) => !s.required)
                .map((e) => (
                  <div key={e.skill.id}>
                    <p style={{ margin: 0 }}>{e.skill.name}</p>
                    <p style={{ fontSize: "16px", color: "#839eaf" }}>
                      {e.importance} pts; {e.years_of_experience} years of
                      experience
                    </p>
                  </div>
                ))}
            </td>
          </tr>
          
          {/* Languags */}
          <tr>
            <td className="input-label">Required Languages</td>
            <td className="table-value">
              {languages_data
                .filter((s) => s.required)
                .map((e) => (
                  <div key={e.language.id}>
                    <p style={{ margin: 0 }}>{e.language.name}</p>
                  </div>
                ))}
              <hr />
            </td>
          </tr>

          <tr>
            <td className="input-label">Optional Languages</td>
            <td className="table-value">
              {languages_data
                .filter((s) => !s.required)
                .map((e) => (
                  <div key={e.language.id}>
                    <p style={{ margin: 0 }}>{e.language.name}</p>
                  </div>
                ))}
            </td>
          </tr>

          {/* Certificates and Licenses */}
          <tr>
            <td className="input-label">Required Certificates</td>
            <td className="table-value">
                {listing_detail?.certifications?.map(e=> 
                    <div key={e.id}>
                        <p className="m-0"  >{e.name}</p>
                    </div>
                  )}
              <hr />
            </td>
          </tr>

          <tr>
            <td className="input-label">Required Licenses</td>
            <td className="table-value">
                {listing_detail?.licenses?.map(e=> 
                    <div key={e.id}>
                        <p className="m-0"  >{e.name}</p>
                    </div>
                  )}
            </td>
          </tr>

          {/* Orientation */}
          <tr>
            <td colSpan="2" className="form-header">
              Orientation
              <hr />
            </td>
          </tr>

          <tr>
            <td className="input-label">
              Required online orientation session before starting
            </td>
            <td className="table-value">
              {" "}
              {listing_detail.online_session ? "Yes" : "No"}{" "}
            </td>
          </tr>

          {/* Instructions */}
          <tr>
            <td colSpan="2" className="form-header">
              Instructions
              <hr />
            </td>
          </tr>

          <tr>
            <td className="input-label">Instructions</td>
            <td className="table-value"> {listing_detail.instructions}</td>
          </tr>
        </tbody>
      </table>

      <ModalListJS modal_title={modal_title} users={users}/>
    </div>
  );
};

export default ListingDetailPage;
