import { EmailOutlined, LocalPhoneOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import useApproveJS from '../../../../../customHooks/Approvals/Decisions/useApproveJS';
import useContinueLater from '../../../../../customHooks/Approvals/Decisions/useContinueLater';
import useDeclineJS from '../../../../../customHooks/Approvals/Decisions/useDeclineJS';
import useRejectJS from '../../../../../customHooks/Approvals/Decisions/useRejectJS';
import useResetRating from '../../../../../customHooks/Approvals/Resume/useResetRating';

import useConvertToJP from '../../../../../customHooks/Approvals/useConvertToJP';
import { format_file } from '../../../../../services/jobs';
import { DeclineButton, ApproveButton, SaveLaterDecision ,ApproveDecision, DeclineDecision, RejectDecision } from '../../../../Other/ApprovalButtons';
import { ApprovedBanner, DeclinedBanner, RejectedBanner } from '../../../../Other/DecisionBanner';
import DeclinedProblems from '../../../../Other/DeclinedProblems';



const InfoListJS = ({
    job_seeker,
    work_experiences,
    educations,
    licenses,
    certifications,
    job_seeker_interested_positions
  }) => {
    const history = useHistory()

    const [note_list, set_note_list] = useState({})
    const do_approve = useApproveJS(note_list)
    const do_decline = useDeclineJS(note_list)
    const do_reject = useRejectJS(note_list)
    const do_continue_later = useContinueLater(note_list)
    const do_convert = useConvertToJP(job_seeker)
    const do_reset_rating = useResetRating(job_seeker)

    const handle_approve = (e) => {
      e.preventDefault()
      do_approve.mutate(job_seeker.id)
      history.push('/js/approvallist')
    }

    const handle_decline = (e) => {
      e.preventDefault()
      do_decline.mutate(job_seeker.id)
      history.push('/js/approvallist')
    }

    const handle_reject = (e) => {
      e.preventDefault()
      do_reject.mutate(job_seeker.id)
      history.push('/js/approvallist')
    }

    const handle_save_later = (e) => {
      e.preventDefault()
      do_continue_later.mutate(job_seeker.user.id)
      history.push('/js/approvallist')
    }

    const handleResetRating = (experience_id) => {
      const ok = window.confirm('Are you sure you want to delete the rating?');
      if (ok)
        do_reset_rating.mutate(experience_id);
    }

    const calculate_approvals = () => {
      // This is a helper method to check the numer
      // of pending, approved or declined sections of js profile
      const total = Object.keys(note_list).length

      let approved = 0
      let declined = 0
      let pending = 0

      for (let [key, value] of Object.entries(note_list)) {
        if (value.status === 1) approved++
        else if (value.status === 0) declined++
        else if (value.status === -1) pending++
      }

      return {
        total,
        approved,
        declined,
        pending
      }
    }

    const valid_for_approval = () => {
      const numbers = calculate_approvals()
      const total = numbers.total
      const approved = numbers.approved
      if (total === approved){
        return true
      }
    }

    const valid_for_decline = () => {
      const numbers = calculate_approvals()
      const pending = numbers.pending
      if (pending === 0){
        return true
      }
    }

    useEffect(()=>{

      if(certifications && job_seeker && work_experiences && licenses && educations) {
        const name = {title:'First and Last name', note:'', status:-1}
        const location = {title:'Location', note:'', status:-1}
        const contact = {title:'Contact', note:'', status:-1}
        const experiences_object = {}
        const licenses_object = {}
        const educations_object = {}
        const certifications_object = {}
        const skills_and_languages = {title:'Skills and languages', note:'', status:-1}
        const interested_positions = {title:'Interested Positions', note:'', status:-1}
        const other = {title:'Other notes', note:'', status:-1}

        work_experiences.forEach(we => {
          const key = `work_experience_${we.id}`
          const title = `Work experience at ${we.company_name} as ${we.position.name}`
          experiences_object[key] = {note:'', title, status: -1}
        })

        licenses.forEach(l => {
          const key = `license_${l.id}`
          const title = `License at ${l.institution} as ${l.title}`
          licenses_object[key] = {note:'', title, status: -1}
        })

        educations.forEach(e => {
          const key = `education_${e.id}`
          const title = `Education at ${e.institution} as ${e.title}`
          educations_object[key] = {note:'', title, status: -1}
        })

        certifications.forEach(c => {
          const key = `certification_${c.id}`
          const title = `Certification at ${c.institution} as ${c.title}`
          certifications_object[key] = {note:'', title, status: -1}
        })

        // iterate throug keys of job_seeker.user.notes
        const temp_note_list = {
          name,
          location,
          contact,
          other,
          interested_positions,
          skills_and_languages,
          ...note_list,
          ...experiences_object,
          ...licenses_object,
          ...educations_object,
          ...certifications_object,
        }
        let to_add_from_database = job_seeker.user.note

        for (let [key, value] of Object.entries(to_add_from_database)) {
          // check if temo_note_list has key
          if (!temp_note_list[key]) {
            // if so, set the status to 1
            console.log(`${key} of old notes was removed`);
            delete to_add_from_database[key]
          }
        }

        set_note_list({
          ...temp_note_list,
          ...to_add_from_database
        })
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[certifications, job_seeker, work_experiences, licenses, educations ])

    if (!job_seeker || !work_experiences || !certifications || !educations || !licenses)return 'Loading...'
    if(do_approve.isLoading || do_decline.isLoading) return 'Candidate approval loading...'


    const convert_company_to_job_seeker = (e) => {
      e.preventDefault()
      const ok = window.confirm("Are you sure you want to convert job seeker to company!");
      if (ok){
        do_convert.mutate(job_seeker.id)
      }
    }

    return (
      <div className='info-list-div'>
          <br/><br/>
          {job_seeker.approved_by_system ==='Approved' && <ApprovedBanner/>}
          {job_seeker.approved_by_system ==='Declined' && <DeclinedBanner/>}
          {job_seeker.approved_by_system ==='Rejected' && <RejectedBanner/>}
          <button className="float-right btn btn-outline-primary" onClick={convert_company_to_job_seeker}>
          <i className="fa fa-repeat"></i>{' '} Convert to company
          </button>
          <br/>
          <br/>
          <div className='info-list-box-div'>
              <img className='profile-pic float-right' alt='Rapihire Profile' src={job_seeker.user.profile_image}  />

              <p className='info-list-box-div_header'> First name and last name </p>
              <span>{job_seeker.user.first_name} {job_seeker.user.last_name}</span>

              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list['name']?.note}
                onChange={(e) => {
                  const note = e.target.value
                  const key = 'name'
                  const title = 'First and Last name'
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID={`name`} set_note_list={set_note_list} note_list={note_list} />
                <ApproveButton ID={`name`} set_note_list={set_note_list} note_list={note_list} />
              </div>

          </div>

          <div className='info-list-box-div'>
              <p className='info-list-box-div_header'>Location</p>
              <span>
                {job_seeker.address}, {job_seeker.city.name}, {job_seeker.province.name}, {' '}
                {job_seeker.zip_code}, {job_seeker.country.name}
              </span>

              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`location`]?.note}
                onChange={(e) => {
                  const note = e.target.value
                  const key = 'location'
                  const title = 'Location'
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID = 'location' set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID = 'location' set_note_list={set_note_list} note_list={note_list}/>
              </div>
          </div>

          {/* Contact */}
          <div className='info-list-box-div'>
              <p className='info-list-box-div_header'>Contact</p>
              <span>
               <EmailOutlined/> {job_seeker.user.email}, <LocalPhoneOutlined/> {job_seeker.telephone}
              </span>

              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`contact`]?.note}
                onChange={(e) => {
                  const note = e.target.value
                  const key = 'contact'
                  const title = 'Contact'
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID = 'contact' set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID = 'contact' set_note_list={set_note_list} note_list={note_list}/>
              </div>
            </div>

          {/* Experiences */}
          {work_experiences.map((we, index) => (
            <div key={we.id} className='info-list-box-div'>
              <p className='info-list-box-div_header'> Work experience {index+1}</p>
              <table style={{width:'100%'}}>
                <tbody>

                  <tr>
                    <td className='info-table-label'>Rating</td>
                    <td className='info-table-info'>{we.rating ?

                      <span>   {we.rating.toFixed(2)}
                        <span
                          className='text-primary cursor-pointer'
                          
                          onClick={()=>handleResetRating(we.id)}
                        >
                         {' '} / Reset
                        </span>
                        </span>
                      :
                      <span> No rating {' '}
                        <a
                          style={{textDecoration:'none'}}
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://app.rapihire.com/reference/rate/${we.id}?js=${job_seeker.user.first_name}`}
                        >
                          / Rate
                        </a>
                        </span>
                    }</td>
                  </tr>

                  <tr>
                    <td className='info-table-label'>Company</td>
                    <td className='info-table-info'>{we.company_name}</td>
                  </tr>

                  <tr>
                    <td className='info-table-label'>Position</td>
                    <td className='info-table-info'> {we.position.name}</td>
                  </tr>

                  <tr>
                    <td className='info-table-label'>Start Date</td>
                    <td className='info-table-info'>{we.start_date ? new Intl.DateTimeFormat('en-US').format(Date.parse(we.start_date) ):'Not provided'}</td>
                  </tr>

                  <tr>
                    <td className='info-table-label'>End Date</td>
                    <td className='info-table-info'>{we.end_date ? new Intl.DateTimeFormat('en-US').format(Date.parse(we.end_date) ) : 'Current Job'}</td>
                  </tr>

                  <tr >
                    <td className='info-table-label'>Reference Name</td>
                    <td className='info-table-info'>{we.contact_name}</td>
                  </tr>

                  <tr >
                    <td className='info-table-label'>Reference Email</td>
                    <td className='info-table-info'>{we.contact_email}</td>
                  </tr>

                  <tr>
                    <td className='info-table-label'>Reference Phone</td>
                    <td className='info-table-info'>{we.contact_phone}</td>
                  </tr>

                </tbody>
              </table>

              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`work_experience_${we.id}`]?.note}
                onChange={(e) => {
                  const note = e.target.value
                  const key = `work_experience_${we.id}`
                  const title = `Work experience at ${we.company_name} as ${we.position.name}`
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID ={`work_experience_${we.id}`} set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID ={`work_experience_${we.id}`} set_note_list={set_note_list} note_list={note_list}/>
              </div>
              {/* } */}
            </div>
          ))}
         {/* Educations */}
         {educations.map((e, index) => (
            <div key={e.id} className='info-list-box-div'>
              <p className='info-list-box-div_header'> Education {index+1}</p>
              <span className='info-table-label' >{e.title}</span> <br/>
              <span className='info-table-info' >{e.year_achieved}</span><br/>
              <span className='info-table-info' >{e.institution}, {e.institution_location}</span><br/>
              {e.document && <span className='info-table-info' ><a rel="noopener noreferrer" target="_blank" href={e.document}>{format_file(e.document)}</a></span>}


              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`education_${e.id}`]?.note}
                onChange={(event) => {
                  const note = event.target.value
                  const key = `education_${e.id}`
                  const title = `Education at ${e.institution} as ${e.title}`
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID={`education_${e.id}`} set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID={`education_${e.id}`} set_note_list={set_note_list} note_list={note_list}/>
              </div>
            </div>
          ))}

           {/* Licenses */}
          {licenses.map((l, index) => (
          <div key={l.id} className='info-list-box-div'>
            <p className='info-list-box-div_header'> License {index+1}</p>
            <table style={{width:'100%'}}>
              <tbody>

                <tr>
                  <td className='info-table-label'>License</td>
                  <td className='info-table-info'>{l.title}</td>
                </tr>

                <tr>
                  <td className='info-table-label'>Year Achieved</td>
                  <td className='info-table-info'> {l.year_achieved}</td>
                </tr>

                <tr >
                  <td className='info-table-label'>Institution</td>
                  <td className='info-table-info'>{l.institution}</td>
                </tr>

                <tr >
                  <td className='info-table-label'>Location of Institution</td>
                  <td className='info-table-info'>{l.institution_location}</td>
                </tr>

                {l.document && <tr >
                  <td className='info-table-label'><a rel="noopener noreferrer" target="_blank" href={l.document}>{format_file(l.document)}</a></td>
                  <td className='info-table-info'></td>
                </tr> }


              </tbody>
            </table>

              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`license_${l.id}`]?.note}
                onChange={(event) => {
                  const note = event.target.value
                  const key = `license_${l.id}`
                  const title = `License at ${l.institution} as ${l.title}`
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID = {`license_${l.id}`} set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID = {`license_${l.id}`} set_note_list={set_note_list} note_list={note_list}/>
              </div>
            {/* } */}
          </div>
        ))}

         {/* Certifications */}
         {certifications.map((c, index) => (
          <div key={c.id} className='info-list-box-div'>
            <p className='info-list-box-div_header'> Certification {index+1}</p>
            <table style={{width:'100%'}}>
              <tbody>

                <tr>
                  <td className='info-table-label'>Certification</td>
                  <td className='info-table-info'>{c.title}</td>
                </tr>

                <tr>
                  <td className='info-table-label'>Year Achieved</td>
                  <td className='info-table-info'> {c.year_achieved}</td>
                </tr>

                <tr >
                  <td className='info-table-label'>Institution</td>
                  <td className='info-table-info'>{c.institution}</td>
                </tr>

                <tr >
                  <td className='info-table-label'>Location of Institution</td>
                  <td className='info-table-info'>{c.institution_location}</td>
                </tr>

                {c.document && <tr >
                  <td className='info-table-label'><a rel="noopener noreferrer" target="_blank" href={c.document}>{format_file(c.document)}</a></td>
                  <td className='info-table-info'></td>
                </tr> }

              </tbody>
            </table>

              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`certification_${c.id}`]?.note}
                onChange={(event) => {
                  const note = event.target.value
                  const key = `certification_${c.id}`
                  const title = `Certification at ${c.institution} as ${c.title}`
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID = {`certification_${c.id}`} set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID = {`certification_${c.id}`} set_note_list={set_note_list} note_list={note_list}/>
              </div>
            {/* } */}
          </div>
        ))}

        <div className='info-list-box-div'>
              <p className='info-list-box-div_header'>Interested Positions</p>
              <br/>
         
              <div>
                <span className='info-table-label'>Interested Positions</span>
                <ul>
                {job_seeker_interested_positions?.positions_interested.map(s => (
                    <li key={s}>{s}</li>
                  ))}
                </ul>
              </div>
              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`interested_positions`]?.note}
                onChange={(e) => {
                  const note = e.target.value
                  const key = 'interested_positions'
                  const title = 'Interested Positions'
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID = 'interested_positions' set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID = 'interested_positions' set_note_list={set_note_list} note_list={note_list}/>
              </div>
        </div>

        <div className='info-list-box-div'>
              <p className='info-list-box-div_header'>Skills and languages</p>
              <br/>
              <div>
                <span className='info-table-label'>Skills</span>
                <ul>
                  {job_seeker?.skills.map(s => (
                    <li key={s.id}>{s.name}</li>
                  ))}
                </ul>
              </div>
              <div>
                <span className='info-table-label'>Languages</span>
                <ul>
                {job_seeker?.languages.map(s => (
                    <li key={s.id}>{s.name}</li>
                  ))}
                </ul>
              </div>
              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`skills_and_languages`]?.note}
                onChange={(e) => {
                  const note = e.target.value
                  const key = 'skills_and_languages'
                  const title = 'Skills and languages'
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right'>
                <DeclineButton ID = 'skills_and_languages' set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID = 'skills_and_languages' set_note_list={set_note_list} note_list={note_list}/>
              </div>
        </div>

        <div className='info-list-box-div'>
              <p className='info-list-box-div_header'>Other notes</p>
              <textarea
                placeholder='Note'
                style={styles.more_info}
                value={note_list[`other`]?.note}
                onChange={(e) => {
                  const note = e.target.value
                  const key = 'other'
                  const title = 'Other notes'
                  set_note_list({
                    ...note_list,
                    [key]:{
                      ...note_list[key],
                      note,
                      title
                    }
                  })
                }}
              />

              <div className='text-right' >
                <DeclineButton ID = 'other' set_note_list={set_note_list} note_list={note_list}/>
                <ApproveButton ID = 'other' set_note_list={set_note_list} note_list={note_list}/>
              </div>
        </div>

        <DeclinedProblems
          notes={note_list}
        />

      {
      job_seeker.approved_by_system ==='Deactivated' ? null :
      <>
        <div className='text-center'>
          <SaveLaterDecision onClick={handle_save_later} />
          <DeclineDecision onClick={handle_decline} valid_for_decline={valid_for_decline} />
          <ApproveDecision onClick={handle_approve} valid_for_approval={valid_for_approval} />
        </div>
        <div className='text-center mt-4'>
          <RejectDecision onClick={handle_reject} valid_for_rejection={job_seeker.approved_by_system !=='Rejected' } />

          </div>
          </>
      }
      </div>
    )
}


const styles = {
  more_info:{
    width:'100%',
    border:'solid 1px #a3bbca',
    borderRadius:'8px',
    padding:15,
    marginTop:20
  }
}


export default InfoListJS;
