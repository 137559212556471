import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import useLogin from '../../customHooks/AuthHooks/useLogin'
import { useField } from '../../customHooks/other/useField'
import {  user_state } from '../../global_state'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import logo_icon from '../../assets/icons/logo.svg'

const Login = () => { 
  const email = useField('email')
  const password = useField('password')

  const history = useHistory()
  const [user] = useRecoilState(user_state)

  const login_mutation = useLogin({email, password})
  const [ show_password, set_show_password ] = useState(true)
  
  const tooglePassword = (e) => {
    e.preventDefault()
    set_show_password(!show_password)
  }

  const handle_login = (e) => {
    e.preventDefault()
    login_mutation.mutate()
  }

    useEffect(()=>{
      if (user) history.push('/')
    },[user, history])


    return (
      <div >
      <img style={{paddingLeft:10, marginRight:60}} alt='raihire logo' src={logo_icon} /> 
        <div className='login-form'>
          {/* {isLoading ? 'logging in...' : null} */}
          <p className="form-header">Sign in to your account</p>
        <br/>
          <form onSubmit={handle_login}>
            <div className="mb-3">

                <label className="input-label">Email address</label>
                <input 
                  onChange={email.onChange}
                  value={email.value}
                  type={email.type} 
                  className="form-control" />
            </div>

            <div className="mb-3">

                <label className="input-label">Password</label>
                <input 
                  className="form-control" 
                  onChange={password.onChange}
                  value={password.value}
                  type={show_password ? password.type  : 'text'}
                />
            {   show_password ? 
              <VisibilityOffIcon onClick={tooglePassword} className='show-password-icon' /> : 
               <VisibilityIcon onClick={tooglePassword} className='show-password-icon'/>}

            </div>
            <a href='https://app.rapihire.com/forgotpassword' style={{textAlign:'right', textDecoration:'none'}}>Forgot Password?</a>
            
            <div style={{textAlign:'center'}}>
            <button style={{marginTop:30, width:'50%'}} type="submit" className="custom_button">Login</button>
            </div>
          </form>
          
          <br/>


        </div>
      </div>
    )
}

export default Login;