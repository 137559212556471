import axios from "axios"
import base_url from "./util"

const get_token = () => {
  const token = window.localStorage.getItem('token')
  return `token ${token}`
}

export const invite_staff = async (payload) => {
    const config = {
      headers: { authorization: get_token()},
    }
    const {data} = await axios.post(`${base_url}accounts/invite_staff`, payload, config)
    return data
}


export const list_invtiations = async (page) => {
    const config = {
        headers: { authorization: get_token()},
      }
    const {data} = await axios.get(`${base_url}invitation_list/?page=${page}`, config)
    return data  
}


export const resend_invite = async (id) => {
    const config = {
        headers: { authorization: get_token()},
      }
    const {data} = await axios.get(`${base_url}accounts/resend_invite_staff/${id}`, config)
    return data  
}

export const edit_staff = async (payload) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}accounts/edit_staff/`, payload, config)
  return data
}

export const deactivate_recruiter = async (email) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}accounts/deactivate-recruiter/`, {email}, config)
  return data
}

export const activate_recruiter = async (email) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}accounts/activate-recruiter/`, {email}, config)
  return data
}
