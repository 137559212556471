import React from 'react'
import useAddPosition from '../../../../customHooks/other/useAddPosition'
import { useField } from '../../../../customHooks/other/useField'


const NewPositionModal = () => {

    const position = useField('text')
    const {mutate: do_add_position, isLoading: adding_position} = useAddPosition()

    const add_position = (e) => {
        e.preventDefault()
        do_add_position(position.value)
        position.setvalue('')
    }
    
   return (
        <div  className="modal fade" id="showNewPositionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="showNewPositionModal" aria-hidden="true">
        <div  className="modal-dialog">
            <div className="modal-content custom-modal-content">

                <div className="modal-body">
                    
                    <p className='form-header'>Add New Position</p>
                    <p className='input-label'>Position name</p>
                    <input {...position} className='custom-modal-input-field'/>
                </div>


                <div className="modal-footer">
                    <button 
                        className='custom-modal-reset-button' 
                        type="button"
                        data-bs-dismiss="modal"
                    >Close</button>
                    <button 
                        className='custom-modal-ok-button' 
                        type="button"
                        // data-bs-dismiss="modal"
                        onClick={add_position}
                        disabled={adding_position}
                    >{adding_position ? 'Adding...' : 'Add' }</button>

                </div>
            </div>
        </div>
        </div>
    )
}


export default NewPositionModal;