import { useQuery } from "react-query"
import { get_unmatched_jobs_jp } from "../../services/jobs";



const useUnmatchedJobsJp = (page, filters, status, company_name, sort_by) => {
    return useQuery(['get_umatched_jobs_jp', page, filters, status, company_name, sort_by],
      () => 
        get_unmatched_jobs_jp(page, filters, status, company_name, sort_by))
}

export default useUnmatchedJobsJp;