import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react'

import TableLoading from '../../Other/Skeletons/TableLoading.js'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import useTimesheets from '../../../customHooks/Timesheet/useTimesheets';
import useUser from '../../../customHooks/AuthHooks/useUser.js';

const styles = {
  sort:{
    backgroundColor:'#fff',
    border:'none',
    margin:0,
    padding:0
  },
  edit_btn: {
    color:'#0099ee'
}
}

const TimesheetTable = ({filters, page, set_page, set_editing }) => {

    const [sort_by, set_sort_by] = useState('-createdAt')
    const {data: user_data} = useUser();
    const staff_level = user_data?.staff_level
    const { data, isLoading, isFetching } = useTimesheets(page, filters, sort_by)
    // const { data: stats_data, isLoading: loadingStats } = useStatsTimesheet()
    if (isLoading || isFetching) return <TableLoading/>

    const handle_page = (e, value) => {
        e.preventDefault()
        set_page(value)
    }
    const handle_editing = (e, value) => {
      e.preventDefault()
      set_editing(value)
    };



    return (
    <div>
        <table className='recruiter-table'>
        <thead>
            <tr>

                <th className='align-left-col'>Matching ID 
                  <button style={styles.sort} 
                    onClick={(e)=>{
                      e.preventDefault();
                      if (sort_by === 'match__id' )set_sort_by('-match__id')
                      else set_sort_by('match__id')
                    }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>Job Seeker 
                   <button style={styles.sort} 
                    onClick={(e)=>{
                      e.preventDefault();
                      if (sort_by === 'match__interested_position__job_seeker__first_name' )set_sort_by('-match__interested_position__job_seeker__first_name')
                      else set_sort_by('match__interested_position__job_seeker__first_name')
                    }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>


                <th className='align-left-col'>Vendor
                  <button style={styles.sort} 
                      onClick={(e)=>{
                        e.preventDefault();
                        if (sort_by === 'match__job_listing__company__name' )set_sort_by('-match__job_listing__company__name')
                        else set_sort_by('match__job_listing__company__name')
                      }}>
                  <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>
                

                <th className='align-left-col'>Period 
                  <button style={styles.sort} 
                        onClick={(e)=>{
                          e.preventDefault();
                          if (sort_by === 'period_start' )set_sort_by('-period_start')
                          else set_sort_by('period_start')
                        }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>Hrs worked 
                  <button style={styles.sort} 
                        onClick={(e)=>{
                          e.preventDefault();
                          if (sort_by === 'total_hours_worked' )set_sort_by('-total_hours_worked')
                          else set_sort_by('total_hours_worked')
                        }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>Rate 
                  <button style={styles.sort} 
                        onClick={(e)=>{
                          e.preventDefault();
                          if (sort_by === 'rate' )set_sort_by('-rate')
                          else set_sort_by('rate')
                        }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>Total 
                  <button style={styles.sort} 
                        onClick={(e)=>{
                          e.preventDefault();
                          if (sort_by === 'total_money' )set_sort_by('-total_money')
                          else set_sort_by('total_money')
                        }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>
                <th className="align-right-col"></th>
            </tr>
        </thead>

        <tbody>
            {data?.results?.timesheet_data.map(match => (
            <tr key={match.id}>
                <td className='align-left-col'>{match.match}</td>
                <td className=''>{match.job_seeker}</td>
                <td className=''>{match.company} </td>
                <td className=''>{new Intl.DateTimeFormat('en-US').format(Date.parse(match.period_start))}  - {new Intl.DateTimeFormat('en-US').format(Date.parse(match.period_end)) }</td>
                <td className=''>{match.total_hours_worked }</td>
                <td className=''>{(match.rate).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}</td>
                <td className=''>
                    {(match.rate * match.total_hours_worked ).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                </td>
                <td className="align-right-col">
                <button
                  style={styles.edit_btn}
                  data-bs-toggle="modal"
                  data-bs-target="#editTimesheetmodal"
                  className='invisible-button'
                  onClick={e => handle_editing(e,match)}>
                  edit
                </button>
              </td>
            </tr>
            ))}
         
        
           {
            !data.results.stats ? 'Loading Statistics...' :
            <tr >
              <td style={{fontWeight:700}} className='align-left-col'>{data.results.stats.total_number_of_timesheets} timesheets</td>
              <td style={{fontWeight:700}}>{data.results.stats.total_number_of_positions} positions</td>
              <td className=''> </td>
              <td className=''> </td>
              <td className=''> {data.results.stats.total_number_of_hours} h  </td>
              <td style={{fontWeight:700}}>
              {data.results.stats.total_number_of_money && (data.results.stats.total_number_of_money).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })} 

            { staff_level > 2 && <a 
                  target="_blank"
                  href='https://api.rapihire.com/api/matches/download/timesheet/'
                  style={{textDecoration:'none', fontWeight:700,color:'#0099ee', float:'right'}}
                >
                  Export csv
                </a>}
              </td>
            </tr>
            }

        </tbody>
        
        </table>        
        <Box 
          className='pagination-div'
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Pagination 
            count={Math.ceil(data.count/10)} 
            page={page} 
            onChange={handle_page}           
          />
        </Box>
    </div>
    )
}

export default TimesheetTable;