import React, { useState } from 'react'
import CornerMenu from '../../../Auth/CornerMenu'
import FilterButton from '../../../Other/FilterButton'
import JobsTableJP from './JobsTableJP'
import AdvancedFilter from '../../../Other/AdvancedFilter'
import FilterSearch from '../../../Other/FilterSearch'
import { useField } from '../../../../customHooks/other/useField'


const JobsPageJP = () => {
    const [filter, set_filter] = useState('ACTIVE')
    const [page, set_page] = useState(1)
    const company_name = useField('text')
    const [position, set_position] = useState('ALL')
    const [hire_rented, set_hire_rented] = useState('ALL')
    const [start_date, set_start_date] = useState('ALL')
    const [end_date, set_end_date] = useState('ALL')
    const [hours_worked_from, set_hours_worked_from] = useState(0)
    const [hours_worked_to, set_hours_worked_to] = useState(6000)
    const [filters, set_filters] = useState({
        position,
        hire_rented,
        start_date,
        end_date,
        hours_worked_from,
        hours_worked_to,
        filter
    })
    
    const handle_advanced_filter = (e) => {
        e.preventDefault()
        set_page(1)
        set_filters({
            position,
            hire_rented,
            start_date,
            end_date,
            hours_worked_from,
            hours_worked_to,
            filter
        });
    }
    const handle_filter = (e,filter_name) => {
        e.preventDefault()
        set_page(1)
        set_filter(filter_name)
        set_filters({...filters, filter: filter_name})
    }

    return (
        <div className='logged-in-container'>
            <CornerMenu/>
            <p className='form-header'>Job Providers</p>
            <br/>
            <div>
                <FilterButton onClick={(e)=>handle_filter(e,'ACTIVE')} active={filter==='ACTIVE'} name='Active'  />
                <FilterButton onClick={(e)=>handle_filter(e,'CLOSED')} active={filter==='CLOSED'} name='Closed' />
                <FilterButton onClick={(e)=>handle_filter(e,'ALL')} active={filter==='ALL'} name='All' />

                <AdvancedFilter 
                    position={position}
                    set_position={set_position}
                    hire_rented={hire_rented}
                    set_hire_rented={set_hire_rented}
                    start_date={start_date}
                    set_start_date={set_start_date}
                    end_date={end_date}
                    set_end_date={set_end_date}
                    hours_worked_from={hours_worked_from}
                    set_hours_worked_from={set_hours_worked_from}
                    hours_worked_to={hours_worked_to}
                    set_hours_worked_to={set_hours_worked_to}
                    handle_advanced_filter={handle_advanced_filter}
                />    
                <FilterSearch name_pattern={company_name}  />
                
            </div>
            <br/><br/>
            <JobsTableJP              
                filters={filters}
                page={page}
                company_name={company_name.value}
                set_page={set_page}
            />
        </div>
    )
}

export default JobsPageJP;