import { useQuery } from "react-query"
import { get_listing_commission_history } from "../../services/jobs";


const useCommissionHistory = (id) => {

    return useQuery(['get_commission_history', id], 
    () => {
        if (id) {
            return get_listing_commission_history(id)
        }
        else {
            return []
        }
    })
}

export default useCommissionHistory;