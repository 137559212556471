import React from 'react'
import { useParams } from 'react-router';
import useJobSeekerDetail from '../../../../../customHooks/Approvals/UseJobSeekerDetail';
import CornerMenu from '../../../../Auth/CornerMenu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import InfoListJS from './InfoListJS';
import useJobSeekerExperiences from '../../../../../customHooks/Approvals/Resume/UseJobSeekerExperiences';
import useJobSeekerEducations from '../../../../../customHooks/Approvals/Resume/UseJobSeekerEducation';
import useJobSeekerLicenses from '../../../../../customHooks/Approvals/Resume/UseJobSeekerLicenses';
import useJobSeekerCertifications from '../../../../../customHooks/Approvals/Resume/UseJobSeekerCertification';
import InfoLoading from '../../../../Other/Skeletons/InfoLoading';
import UseJobSeekerInterestedPositions from '../../../../../customHooks/Approvals/Resume/UseJobSeekerInterestedPositions';

const InfoPageJS = () => {
    const job_seeker_id = useParams().js_id
    const { data: job_seeker_detail, isLoding: loading_info, isFetching} = useJobSeekerDetail(job_seeker_id)
    const { data: job_seeker_experiences, isLoding: loading_experiences, isFetching: fExperience} = useJobSeekerExperiences(job_seeker_id)
    const { data: job_seeker_educations, isLoding: loading_educations, isFetching: fEducation} = useJobSeekerEducations(job_seeker_id)
    const { data: job_seeker_licenses, isLoding: loading_licenses, isFetching: fLicense} = useJobSeekerLicenses(job_seeker_id)
    const { data: job_seeker_certifications, isLoding: loading_certifications, isFetching: fCertification} = useJobSeekerCertifications(job_seeker_id)
    const { data: job_seeker_interested_positions, isLoding: loading_interested_positions, isFetching: fPositions} = UseJobSeekerInterestedPositions(job_seeker_id)
    if(
        loading_info || 
        loading_experiences || 
        loading_educations ||
        loading_licenses ||
        loading_certifications || loading_interested_positions ||
        isFetching || fPositions ||
        fExperience || fEducation || fLicense || fCertification
    ) return <InfoLoading/>

    return (
      <div className='logged-in-container'>
        <CornerMenu/>
        <Link style={{textDecoration:'none'}} to='/js/approvallist' className='form-header'>
           <ArrowBackIcon style={{marginBottom:4, opacity:0.6}} fontSize='large'/> {' '}  
            Profile Approval
        </Link>
       
        <InfoListJS 
            job_seeker={job_seeker_detail}
            work_experiences={job_seeker_experiences}
            educations={job_seeker_educations}
            licenses={job_seeker_licenses}
            certifications={job_seeker_certifications}
            job_seeker_interested_positions = {job_seeker_interested_positions}
        />
      </div>
    )
}


export default InfoPageJS;