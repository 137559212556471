import React, { useState } from 'react'

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate()
      );
    }
    return days;
  }
  
  function getWeekRange(date) {
    return {
      from: moment(date)
        .startOf('week')
        .toDate(),
      to: moment(date)
        .endOf('week')
        .toDate(),
    };
  }

const WeekRange = ({selectedDays, set_selectedDays}) => {
    const [hover_range, set_hover_range] = useState(undefined);
    
    const handleDayChange = date => {
      set_selectedDays(getWeekDays(getWeekRange(date).from))
    }

    const handleWeekClick = (weekNumber, days, e) => {
        set_selectedDays(days);
    }

    const handleDayEnter = date => { 
      set_hover_range(getWeekRange(date))
    }

    const handleDayLeave = () => {
      set_hover_range(undefined)
    }
    
    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hover_range,
        selectedRange: daysAreSelected && {
          from: selectedDays[0],
          to: selectedDays[6],
        },
        hoverRangeStart: hover_range && hover_range.from,
        hoverRangeEnd: hover_range && hover_range.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
      };
    return (            
        <DayPicker
            selectedDays={selectedDays}
            showWeekNumbers
            daySize={7}
            showOutsideDays
            modifiers={modifiers}
            onDayClick={handleDayChange}
            onDayMouseEnter={handleDayEnter}
            onDayMouseLeave={handleDayLeave}
            onWeekClick={handleWeekClick}
        />
    )
}

export default WeekRange;