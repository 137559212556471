import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react'
import TableLoading from '../../../Other/Skeletons/TableLoading.js'
import useMatches from '../../../../customHooks/Timesheet/useMatches.js';


const MatchesTable = ({
  page,
  set_page,
}) => {

    const { data: matches, isLoading, isFetching } = useMatches(page)
    if (isLoading || isFetching) return <TableLoading/>

    const handle_page = (e, value) => {
        e.preventDefault()
        set_page(value)
    }

    return (
    <div>
        <table className='recruiter-table'>
        <thead>
            <tr>
                <th className='align-left-col'>Matching ID </th>
                <th className='align-left-col'>Job Seeker </th>
                <th className='align-left-col'>Vendor </th>
                <th className='align-left-col'>Position</th>
                <th className='align-right-col'>Date matched</th>
            </tr>
        </thead>

        <tbody>
            {matches.results.map(match => (
            <tr key={match.id}>
                <td className='align-left-col'>{match.id}</td>
                <td className=''> <a target='__blank' className='text-decoration-none' href={`/js/approvallist/${match.job_seeker.js_id}`}>{match.job_seeker.name}</a> </td>
                <td className=''>{match.company} </td>
                <td className=''>{match.position }</td>
                <td className='align-right-col' >{new Intl.DateTimeFormat('en-US').format(Date.parse(match.match_time))}</td>
            </tr>
            ))}
        </tbody>
        
        </table>        
        <Box 
          className='pagination-div'
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Pagination 
            count={Math.ceil(matches.count/10)} 
            page={page} 
            onChange={handle_page}           
          />
        </Box>
    </div>
    )
}

export default MatchesTable;