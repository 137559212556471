import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const styles = {
    value:{
      width:150,
      height:30
    }
  }

const InfoLoading = () => {
    const history = useHistory()

    return (
        <div className="logged-in-container">

        <button
          onClick={(e)=>{e.preventDefault();history.go(-1);}}
          className="form-header invisible-button">
          <ArrowBackIcon
            style={{marginBottom: 4, opacity: 0.6}}
            fontSize="large"
          />{' '}
          Go back
        </button>
  
        <table className='job-listing-table'>
          <tbody>
            <tr>
              <td colSpan="2" className='form-header'>
                Job Posting <hr/>
              </td>
            </tr>
            <tr>
              <td className='input-label'>Position</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td className='input-label'>Employment type</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td className='input-label'>Permanent/Temporary</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td className='input-label'>Approval</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td className='input-label'>Number of Employees needed</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td className='input-label'>Address</td>
              <td className='table-value'><Skeleton style={{...styles.value,height:70}}/> </td>
            </tr>
  
            <tr>
              <td className='input-label'>Permanent/Temporary</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td className='input-label'>Shift</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td colSpan="2" className='form-header'>
                Job Time Length <hr />
              </td>
            </tr>
  
            <tr>
              <td className='input-label'>Daily Hours</td>
              <td className='table-value'> <Skeleton style={styles.value}/> </td>
            </tr>
  
            <tr>
              <td className='input-label'>Rate per Hour</td>
              <td className='table-value'> <Skeleton style={styles.value}/> </td>
            </tr>
  
            <tr>
              <td className='input-label'> Job length</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            <tr>
              <td className='input-label'>How many times</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>

            <tr>
              <td className='input-label'>Start Date</td>
              <td className='table-value'><Skeleton style={styles.value}/></td>
            </tr>
  
            
          </tbody>
        </table>
      </div>
    )
}

export default InfoLoading;