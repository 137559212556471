import { Box } from '@material-ui/core'
import { CallEndOutlined, CallMadeOutlined } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useCompanies from '../../../../customHooks/Approvals/UseCompanies'
import TableLoading from '../../../Other/Skeletons/TableLoading'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
 
const approved_color = (approved) => {
    if (approved==='Declined') return '#ee5566'
    if (approved==='Approved') return '#00ccaa'
    if (approved==='Deactivated') return 'black'
     return '#ffbb00'
}
const styles = {
  sort:{
    backgroundColor:'#fff',
    border:'none',
    margin:0,
    padding:0
  }
}

const ApprovalTableJP = ({filter, page, set_page, name_pattern, sort_by, set_sort_by}) => {
  
  const {data: companies_data, isLoading, isFetching } = useCompanies(page, filter, name_pattern, sort_by)

  const handle_page = (e, value) => {
    e.preventDefault()
    set_page(value)
  }

  if (isLoading || isFetching) return <TableLoading/>
  return (
    <div>
        <table className='recruiter-table'>
        <thead>

        <tr>
            <th className='align-left-col'>Name
            <button style={styles.sort} 
                onClick={(e)=>{
                  e.preventDefault();
                  if (sort_by === 'name' )set_sort_by('-name')
                  else set_sort_by('name')
                }}>
                <UnfoldMoreIcon fontSize="inherit"/>
            </button>
            </th>
            <th >Status
            <button style={styles.sort} 
                onClick={(e)=>{
                  e.preventDefault();
                  if (sort_by === 'is_approved' )set_sort_by('-is_approved')
                  else set_sort_by('is_approved')
                }}>
                <UnfoldMoreIcon fontSize="inherit"/>
            </button>
            </th>
            <th className='align-right-col'>Submission date
            <button style={styles.sort} 
                onClick={(e)=>{
                  e.preventDefault();
                  if (sort_by === 'jobprovider__createdAt' )set_sort_by('-jobprovider__createdAt')
                  else set_sort_by('jobprovider__createdAt')
                }}>
                <UnfoldMoreIcon fontSize="inherit"/>
            </button>
            </th>
        </tr>

        </thead>

      <tbody>

        {companies_data.results.map(company => (
          <tr key={company.id}>
            <td className='align-left-col'>
              <Link 
                  to={`/jp/approvallist/${company.id}`} 
                  style={{textDecoration:"none", color:'inherit'}}
                > 
                {company.name}
              </Link>
            </td>
            <td style={{color: approved_color(company.is_approved)}}> 
                {company.is_approved}
                <Link className='td-link' to={`/approvalhistory/${company.jobprovider.id}?user=Company ${company.name}`}>
                 <CallMadeOutlined fontSize="small" />
              </Link>{" "}
            </td>
            <td className='align-right-col'>
                {new Intl.DateTimeFormat('en-US', { dateStyle: 'medium'}).format(Date.parse(company.jobprovider.createdAt))}
            </td>
          </tr>
        ))}
       
      </tbody>
      </table>

        <Box 
          className='pagination-div'
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Pagination 
            count={Math.ceil(companies_data.count/10)} 
            page={page} 
            onChange={handle_page}           
          />
        </Box>
    </div>
    )
}

export default ApprovalTableJP;