

const FilterSearch = props => {
    return (
        <input 
            placeholder={props.placeholder || 'Search' }
            style={{
                backgroundColor:"white",
                border: "solid 1px #839eaf",
                borderRadius: 18,
                padding: '6px 6px',
                float:'right',
                // color:'#839eaf',
                width:250
                
            }}
            onChange={props.name_pattern.onChange}
            value={props.name_pattern.value}
            {...props}
        />
    )
}

export default FilterSearch;