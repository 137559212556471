import React from 'react'


const NotesModal = ({history}) => {

   if (!history) return null
    
   return (
        <div  className="modal fade" id="showApprovalNotesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div  className="modal-dialog">
            <div className="modal-content custom-modal-content">

                <div className="modal-body">
                    
                    <p className='form-header'>Reasons declined</p>
                    {!history?.note?.length && <p>No notes</p>}
                    <ul>
                        {history.note?.map((r,idx) => 
                            <li className='input-label' key={idx}>
                            {r.title}<br/>
                            <span style={{fontWeight: 400}} > - {r.reason}</span>
                        </li>
                        )}
                    </ul>
                    
                </div>


                <div className="modal-footer">
                    <button 
                        className='custom-modal-reset-button' 
                        type="button"
                        data-bs-dismiss="modal"
                    >Close</button>

                </div>
            </div>
        </div>
        </div>
    )
}


const styles = {
    child:{
        flex:1,
    },
    info:{
        color:'#445566',
        fontWeight:'bold'
    },   
}

export default NotesModal;