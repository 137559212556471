import { useQuery } from "react-query"
import { get_job_seekers } from "../../services/approvals";


const useJobSeekers = (
    page,
    filter,
    name_pattern,
    interested_positions,
    countries,
    provinces,
    cities,
    sort_by
) => {
    return useQuery(['get_job_seekers', 
        page,
        filter,
        name_pattern,
        interested_positions,
        countries,
        provinces,
        cities,
        sort_by], 
        ()=>get_job_seekers(
            page,
            filter,
            name_pattern,
            interested_positions,
            countries,
            provinces,
            cities,
            sort_by
        ))
}

export default useJobSeekers;