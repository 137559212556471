import React from 'react'
import useAddSkill from '../../../../customHooks/other/useAddSkill';
import { useField } from '../../../../customHooks/other/useField';


const NewSkillModal = () => {

    const skill = useField('text')
    const {mutate: do_add_skill, isLoading: adding_skill} = useAddSkill()

    const add_skill = (e) => {
        e.preventDefault()
        do_add_skill(skill.value)
        skill.setvalue('')
    }

    return (
        <div  className="modal fade" id="showNewSkillModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="showNewSkillModal" aria-hidden="true">
        <div  className="modal-dialog">
            <div className="modal-content custom-modal-content">

                <div className="modal-body">
                    
                    <p className='form-header'>Add New skill</p>
                    <p className='input-label'>Skill name</p>
                    <input {...skill} className='custom-modal-input-field'/>
                </div>


                <div className="modal-footer">
                    <button 
                        className='custom-modal-reset-button' 
                        type="button"
                        data-bs-dismiss="modal"
                    >Close</button>
                    <button 
                        className='custom-modal-ok-button' 
                        type="button"
                        // data-bs-dismiss="modal"
                        onClick={add_skill}
                        disabled={adding_skill}
                    >{adding_skill ? 'Adding...' : 'Add' }</button>

                </div>
            </div>
        </div>
        </div>
    )
}


export default NewSkillModal;