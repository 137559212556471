import React, { useEffect, useRef, useState } from 'react'
import WeekRange from '../Recuiter/Timesheet/WeekRange'

const normalize_date = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

const styles = {
    drop_filter: {
        float:'right',
        dsiplay:'inline',
        marginLeft:20,
    },
    rented:{
        background:'none',
        borderRadius: 18,
        padding:5,
        border: 'solid 1px #839eaf',
        color:'#839eaf' ,
        paddingLeft:12,
        paddingRight:12,
        marginRight:10
    },
    label:{
        marginBottom:7,
        color:'#445566',
        fontWeight:500
    },
    input_field:{
        border: 'solid 1px #aabbcc',
        borderRadius:18,
        padding:5,
        paddingLeft:12,
        paddingRight:12,
        marginRight:10,
        // color: '#aabbcc'
    },
    dropdown_button:{
        width:200,
        backgroundColor:'#fff',
        border: 'solid 1px #839eaf',
        borderRadius:18,
        color:"#445566"
    }
}

const TimesheetFilter = (props) => {
  const [show_calendar, set_show_calendar] = useState(false)
  const [selectedDays, set_selectedDays] = useState([]);
  const handle_show_calendar = () => set_show_calendar(!show_calendar);
  const filter_button_ref = useRef(null)

  const handle_filter = (e) => {
    props.handle_advanced_filter(e)
    filter_button_ref.current.click()
  }

  const handle_reset = e => {
      e.preventDefault()
      props.set_hours_worked_from(0)
      props.set_hours_worked_to(1000)
      props.set_matching_id('')
      props.set_rate_from(0)
      props.set_rate_to(100)
      props.set_company_name('')
      props.set_js_name('')
      props.set_period_start('')
      set_selectedDays([]);
      set_show_calendar(false)
  }

  useEffect(() => {
      if (selectedDays.length === 7) 
        props.set_period_start(normalize_date(selectedDays[0]))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [selectedDays])

  const valid_filters = () => {
    return (
      (Number(props.matching_id) > 0 || props.matching_id === '') && 
      Number(props.rate_from) >= 0 && props.rate_from !== '' &&
      Number(props.rate_to) >= 0 && props.rate_to !== '' &&
      Number(props.hours_worked_from) >= 0 && props.hours_worked_from !== '' &&
      Number(props.hours_worked_to) >= 0 && props.hours_worked_to !== '' 
    )
  }

  return (
    <div className="dropdown filter" style={styles.drop_filter}>
        <button style={styles.dropdown_button} ref={filter_button_ref} className="btn dropdown-toggle" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            Filter
        </button>

        <div style={{boxShadow: '0 0 10px 0 rgba(68, 85, 102, 0.25)'}}  className="dropdown-menu p-4" aria-labelledby="dropdownMenuClickableInside">

            <label style={styles.label}>Matching ID</label><br/>
            <input 
                style={{width:'100%'}} 
                className='custom-modal-input-field'
                onChange={(e)=>props.set_matching_id(e.target.value)}
                value={props.matching_id}
                placeholder='Search matching' 
            /> <br/><br/>

            <label style={styles.label}>Job Seeker</label><br/>
            <input 
                style={{ width:'100%'}} 
                className='custom-modal-input-field'
                onChange={(e)=>props.set_js_name(e.target.value)}
                value={props.js_name}
                placeholder='Search job seeker' 
            /> <br/><br/>

            <label style={styles.label}>Company</label><br/>
            <input 
                style={{ width:'100%'}} 
                className='custom-modal-input-field'
                onChange={(e)=>props.set_company_name(e.target.value)}
                value={props.company_name}
                placeholder='Search company' 
            /> <br/><br/>

            <div>
            <label style={{...styles.label, backgroundColor:'#fff'}}>Period</label><br/>
                <button onClick={handle_show_calendar} className='custom-modal-input-field'>
                {selectedDays[0]  ?  new Intl.DateTimeFormat('en-US').format(Date.parse(selectedDays[0])) : <span style={{fontSize:14}}>MM/DD/YYYY</span>} 
                {' - '}
                {selectedDays[6]  ?  new Intl.DateTimeFormat('en-US').format(Date.parse(selectedDays[6])) : <span style={{fontSize:14}}>MM/DD/YYYY</span>} 
                </button>
                {show_calendar && <WeekRange set_selectedDays={set_selectedDays} selectedDays={selectedDays}/>}
            </div><br/>

            <label style={{...styles.label, backgroundColor:'#fff'}}>Hours worked</label><br/>

            <div style={{display:'flex'}}>
                <div style={{flex:1}}>
                    <input 
                        style={{width:'150px', flex:1}}  
                        className='custom-modal-input-field'
                        value={props.hours_worked_from}
                        onChange={(e)=>props.set_hours_worked_from(e.target.value)}
                        placeholder='from'
                        type='text'
                    />
                </div>
                <div style={{flex:1}}>
                    <label style={styles.label}></label>
                    <input 
                        value={props.hours_worked_to}
                        className='custom-modal-input-field'
                        placeholder='to'
                        onChange={(e)=>props.set_hours_worked_to(e.target.value)}
                        style={{ width:'150px', flex:1}}  
                        type='text'
                    />
                </div>
            </div><br/>

            <label style={{...styles.label, backgroundColor:'#fff'}}>Rate</label><br/>
            <div style={{display:'flex'}}>
                <div style={{flex:1}}>
                    <input 
                        style={{ width:'150px', flex:1}}  
                        className='custom-modal-input-field'
                        value={props.rate_from}
                        onChange={(e)=>props.set_rate_from(e.target.value)}
                        placeholder='from'
                        type='text'
                    />
                </div>
                <div style={{flex:1}}>
                    <label style={styles.label}></label>
                    <input 
                        value={props.rate_to}
                        className='custom-modal-input-field'
                        placeholder='to'
                        onChange={(e)=>props.set_rate_to(e.target.value)}
                        style={{ width:'150px', flex:1}}  
                        type='text'
                    />
                </div>
            </div><br/>

            
            {!valid_filters() && <p className='error-label'>Please enter valid inputs</p> }
            <div style={{textAlign:'center'}}>
                <button 
                    onClick={handle_reset} 
                    className='custom-modal-reset-button'
                    style={{width:'45%'}}   
                >Reset</button>

                <button 
                    onClick={handle_filter} 
                    disabled={!valid_filters()} 
                    className='custom-modal-ok-button'
                    style={{ width:'45%' ,backgroundColor: valid_filters() ? '#249afa' : 'rgba(36, 154, 250, 0.5)'}}>
                OK</button>
            </div>
        </div>
    </div>
    )
}

export default TimesheetFilter;