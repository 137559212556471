import { useMutation, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from '../../global_state'
import { update_job_listing } from "../../services/jobs";


const useUpdateListing = () => {
  const queryClient = useQueryClient()
  const set_error = useSetRecoilState(error_message)
  const set_success = useSetRecoilState(success_message)

  return  useMutation((payload)=>update_job_listing(payload.job_id, payload.job_data), {
      onSuccess: data => {
        set_success('Successfully updated!')
        setTimeout(() => {
          set_success('')
        }, 4000);
        queryClient.invalidateQueries('get_umatched_jobs_jp')
        queryClient.invalidateQueries('get_commission_history')
      },
      onError: () => {
        set_error('Problem updating!')
        setTimeout(() => {
          set_error('')
        }, 4000);
      }
  })
}

export default useUpdateListing;