/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import CornerMenu from '../../../Auth/CornerMenu'
import MatchesTable from './MatchesTable'

const MatchesPage = () => {

    // Job filtering state
    const [page, set_page] = useState(1)
   
    return (
        <div className='logged-in-container'>
            <CornerMenu/>
            
            <p className='form-header'>Matches List</p>
            <br/>

            <MatchesTable
                page={page}
                set_page={set_page}
            />

        </div>
    )
}

export default MatchesPage;