import React from 'react'
import js_logo from '../../assets/icons/js_logo.png'
import jp_logo from '../../assets/icons/jp_logo.png'

import { Link, useLocation } from 'react-router-dom'
import useUser from '../../customHooks/AuthHooks/useUser'


const LeftSide = () => {
    const path = useLocation().pathname
    const {data: user_data} = useUser();
    if (['/', '/register/'].includes(path)) return null

    return (
        <div className='left-side-nav'>
            <UpperCategory user_data={user_data} path={path} />
            {path.includes('/timesheet') || path.includes('/a/recruiters') || path.includes('/other') || path.includes('/matches')  ? null : <LowerCategory path={path} user_data={user_data}  />}
        </div>
    )
}

const UpperCategory = ({path, user_data}) => {
    const staff_level = user_data?.staff_level
    return (

        <div className='left-nav-upper-category'>
            
            {/* LEVEL 2 AND UP */}
            { staff_level > 1 &&
            <div className='left-nav-main-category'>
                <Link style={{opacity:path.includes('/jp') ? 1 : 0.6,textDecoration:'none'}} to='/jp/approvallist' > 
                    <img 
                        style={{width:40, border: path.includes('/jp')  ? '3px solid #fff' : 'none', borderRadius:5}} 
                        src={jp_logo} 
                        alt='Rapihire JS logo' />
                        <span className='name'>Job Provider</span>
                </Link>
            </div>
            }

            {/* LEVEL 1 AND UP */}
            { staff_level > 0 &&
            <div className='left-nav-main-category'>
                <Link style={{opacity:path.includes('/js') ? 1 : 0.6, textDecoration:'none'}} to='/js/approvallist' > 
                    <img 
                        style={{width:40, border: path.includes('/js')  ? '3px solid #fff' : 'none', borderRadius:5}} 
                        src={js_logo} alt='Rapihire JS logo' />

                    <span className='name'>Job Seeker</span>
                </Link>
            </div>
            }

            {/* LEVEL 1 AND UP */}
            { staff_level > 0 &&
            <div className='left-nav-main-category'>
                <Link 
                    to={'/timesheet'} 
                    style={{color:'white', marginLeft:0,fontWeight: path.includes('/timesheet') ? 800 : 300, textDecoration:'none'}}>
                    Timesheet
                </Link>
            </div>
            }

            {/* LEVEL 2 AND UP */}
            { staff_level > 1 &&
            <div className='left-nav-main-category'>
                <Link 
                    to={'/matches'} 
                    style={{color:'white', marginLeft:0,fontWeight: path.includes('/matches') ? 800 : 300, textDecoration:'none'}}>
                    Matches
                </Link>
            </div>
            }

            {/* LEVEL 3 AND UP */}
            {staff_level > 2 && 
                <div className='left-nav-main-category'>
                    <Link 
                        to={'/a/recruiters'} 
                        style={{color:'white', marginLeft:0,fontWeight: path.includes('/a/recruiters') ? 800 : 300, textDecoration:'none'}}>
                        Admin Settings
                    </Link>
                </div>
            }

            {/* LEVEL 3 AND UP */}
            { staff_level > 2 &&

                <div className='left-nav-main-category'>
                    <Link 
                        to={'/other'} 
                        style={{color:'white', marginLeft:0,fontWeight: path.includes('/other') ? 800 : 300, textDecoration:'none'}}>
                        Other
                    </Link>
                </div>
            }
                
        </div>
    )
    }


const LowerCategory = ({path, user_data}) => {
    const staff_level = user_data?.staff_level

    return (
    <>
    {
        path.includes('/js') 
        ?
        <div className='left-nav-lower-category'>   
            <div className='name'>
                    <Link 
                        to={'/js/jobslist'} 
                        style={{color:'white',textDecoration:'none', fontWeight:path.includes('/js/jobslist') ? 800 : 300}}>
                        Jobs JS
                    </Link>
            </div>

            <div className='name'>
                <Link 
                    to={'/js/approvallist'} 
                    style={{color:'white',textDecoration:'none', fontWeight:path.includes('/js/approvallist')? 800 : 300}}>
                    Approvals
                </Link>
            </div>
            { staff_level > 1 &&
                <div className='name'>
                    <a 
                        href={'/js/communication'} 
                        style={{color:'white',textDecoration:'none', fontWeight:path.includes('/js/communication')? 800 : 300}}>
                        Communication
                    </a>
                </div>
            }

        </div>
        :
        <div className='left-nav-lower-category'>   
            <div className='name'>
                    <Link 
                        to={'/jp/jobslist'} 
                        style={{color:'white',textDecoration:'none', fontWeight:path.includes('/jp/jobslist')? 800 : 300}}>
                        Jobs JP
                    </Link>
            </div>

            <div className='name'>
                    <Link 
                        to={'/jp/unmatched'} 
                        style={{color:'white',textDecoration:'none', fontWeight:path.includes('/jp/unmatched')? 800 : 300}}>
                        Unmatched listings
                    </Link>
            </div>

            <div className='name'>
                <Link 
                    to={'/jp/approvallist'} 
                    style={{color:'white',textDecoration:'none', fontWeight:path.includes('/jp/approvallist')? 800 : 300}}>
                    Approvals
                </Link>
            </div>

        </div>
        }
    </>
)}

export default LeftSide;