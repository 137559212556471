
import { useQuery } from "react-query"
import { get_all_positions } from "../../services/jobs";


const useAllPositions = () => {
return useQuery('get_all_positions', () => get_all_positions())
}

export default useAllPositions;
