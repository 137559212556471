import axios from "axios"
import base_url from "./util"

const get_token = () => {
  const token = window.localStorage.getItem('token')
  return `token ${token}`
}

export const format_file = (file) => {
  // Get the string after the last '/' in the file path
  let filename = file.substr(file.lastIndexOf("/") + 1);
  if (filename.length>15){
    filename = filename.substr(0,15)+'...'
  }
  return filename;
};

export const get_jobs_js = async (page, filters, js_name, sort_by) => {
  const hired_rented = filters.hire_rented
  const position = filters.position
  const hours_from = filters.hours_worked_from
  const hours_to = filters.hours_worked_to
  const start_date = filters.start_date
  const end_date = filters.end_date
  const filter = filters.filter

  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(
    `${base_url}interestedpositions/recruiter/all/?page=${page}&hired_rented=${hired_rented}&position=${position}&hours_from=${hours_from}&hours_to=${hours_to}&start_date=${start_date}&end_date=${end_date}&js_name=${js_name}&sort_by=${sort_by}&filter=${filter}`
    , config)

  return data
}

export const get_jobs_jp = async (page, filters, company_name, sort_by) => {
  const hired_rented = filters.hire_rented
  const position = filters.position
  const hours_from = filters.hours_worked_from
  const hours_to = filters.hours_worked_to
  const start_date = filters.start_date
  const end_date = filters.end_date
  const filter = filters.filter

  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(
    `${base_url}jobopenings/recruiter/all/?page=${page}&hired_rented=${hired_rented}&position=${position}&hours_from=${hours_from}&hours_to=${hours_to}&start_date=${start_date}&end_date=${end_date}&company=${company_name}&sort_by=${sort_by}&filter=${filter}`
    ,config)
  return data
}

export const get_unmatched_jobs_jp = async (page, filters, status, company_name, sort_by) => {
  const hired_rented = filters.hire_rented
  const position = filters.position
  const start_date = filters.start_date
  const end_date = filters.end_date
  const filter = filters.filter

  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(
    `${base_url}jobopenings/recruiter/unmatched/?page=${page}&hired_rented=${hired_rented}&position=${position}&start_date=${start_date}&end_date=${end_date}&company=${company_name}&sort_by=${sort_by}&filter=${filter}&status=${status}`
    ,config)
  return data
}


export const update_job_listing = async (job_id, job_data) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}jobopenings/${job_id}`, job_data, config);
  return data
}

export const run_test_algorithm = async (listing_id) => {
  const {data} = await axios.get(`${base_url}matches/run-algorithm-listing/${listing_id}`)
  return data
}

export const get_stats_js = async () => {
  const {data} = await axios.get(`${base_url}stats/js/`)
  return data
}

export const get_stats_jp = async () => {
  const {data} = await axios.get(`${base_url}stats/jp/`)
  return data
}

export const download_jobs_js = async () => {
  const {data} = await axios.get(`${base_url}download/js/`)
  return data
}

export const download_jobs_jp = async () => {
  const {data} = await axios.get(`${base_url}download/jp/`)
  return data
}

export const get_listing_detail = async (job_id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}jobopenings/${job_id}`, config)
  return data
}

export const get_listing_skills = async (job_id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}listingskills/${job_id}`, config)
  return data
}

export const get_listing_languages = async (job_id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}listinglanguages/${job_id}`, config)
  return data
}


export const get_listing_commission_history = async (job_id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}commission_history/${job_id}`, config)
  return data
}


export const add_new_skill = async (skill_name) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.post(`${base_url}skills/`, {name: skill_name}, config)
  return data
}


export const add_new_position = async (position_name) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.post(`${base_url}positions/`, {name: position_name}, config)
  const skill_res = await axios.post(`${base_url}skills/`, {name: position_name}, config)
  return data
}

export const add_new_certificate = async (certificate_name) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.post(`${base_url}certifcate-autofill/`, {name: certificate_name}, config)
  return data
}

export const add_new_license = async (license_name) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.post(`${base_url}license-autofill/`, {name: license_name}, config)
  return data
}

export const get_all_positions = async () => {
  // const config = {
  //   headers: { authorization: get_token()},
  // }
  const {data} = await axios.get(`${base_url}positions/`)
  return data
}

export const get_all_countries = async () => {
  // const config = {
  //   headers: { authorization: get_token()},
  // }
  const {data} = await axios.get(`${base_url}countries/`)
  return data
}

export const get_all_provinces = async () => {
  // const config = {
  //   headers: { authorization: get_token()},
  // }
  const {data} = await axios.get(`${base_url}provinces/`)
  return data
}

export const get_all_cities = async () => {
  // const config = {
  //   headers: { authorization: get_token()},
  // }
  const {data} = await axios.get(`${base_url}cities/`)
  return data
}

