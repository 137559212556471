import {Box} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import useInvitations from '../../customHooks/Admin/useListInvitations';
import TableLoading from '../Other/Skeletons/TableLoading';

const InviteStaffTable = ({page, set_page, set_editing}) => {
  const {data: invitations, isLoading, isFetching} = useInvitations(page);

  if (isLoading || isFetching) return <TableLoading />;

  const handle_page = (e, value) => {
    e.preventDefault();
    set_page(value);
  };


  const hande_editing = (e, value) => {
    e.preventDefault();
    set_editing(value);
  };

  return (
    <div>
      <table className="recruiter-table">
        <thead>
          <tr>
            <th className="align-left-col">Email</th>
            <th>Level</th>
            <th>Status</th>
            <th className="align-right-col">Date invited</th>
            <th className="align-right-col"></th>
          </tr>
        </thead>

        <tbody>
          {invitations.results.map(invitation => (
            <tr key={invitation.id}>
              <td className="align-left-col">{invitation.email}</td>
              <td >
                 {invitation.user ? `${invitation.user_role} ${invitation.user_role==='Recruiter' && invitation.staff_level} `: `Invited as ${invitation.role}` }
              </td>
              <td style={{color: invitation.user ? 'green' : 'orange' }}>
                {invitation.status}
              </td>
              <td className="align-right-col">
                {moment(invitation.date).format('LL')}
              </td>
              <td className="align-right-col">
                <button
                  style={styles.edit_btn}
                  data-bs-toggle="modal"
                  data-bs-target="#editStaffModal"
                  className='invisible-button'
                  onClick={e => hande_editing(e, invitation)}>
                  edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Box
        className="pagination-div"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Pagination
          count={Math.ceil(invitations.count / 10)}
          page={page}
          onChange={handle_page}
        />
      </Box>
    </div>
  );
};

const styles = {
  edit_btn: {
    color:'#0099ee',

}
}
export default InviteStaffTable;
