import React, {useEffect, useState} from 'react';
import useUser from '../../../../customHooks/AuthHooks/useUser';
import { useField } from '../../../../customHooks/other/useField';
import CornerMenu from '../../../Auth/CornerMenu';
import FilterButton from '../../../Other/FilterButton';
import FilterSearch from '../../../Other/FilterSearch';
import ApprovalTableJP from './ApprovalTableJP';

const ApprovalPageJP = () => {
  const [page, set_page] = useState(1);
  const [filter, set_filter] = useState('PENDING');
  const name_pattern = useField('text')
  const [sort_by, set_sort_by] = useState('jobprovider__createdAt')
  const {data: user_data} = useUser();
  const staff_level = user_data?.staff_level

  const handle_filter = (e, filter_name) => {
    e.preventDefault();
    set_page(1);
    set_filter(filter_name);

    if (filter_name==='PENDING'){
        set_sort_by('jobprovider__createdAt');
    }else{
        set_sort_by('-jobprovider__createdAt')
    }

    
  };

  useEffect(()=>{
    // Check from localstorage if we have some filtering or page 
    // already taking place
    const ls_filter = window.localStorage.getItem('jp_filter');
    const ls_sort_by = window.localStorage.getItem('jp_sort_by');
    const ls_page = window.localStorage.getItem('jp_page');
    if (ls_filter){
      set_filter(ls_filter);
    }

    if (ls_sort_by){
      set_sort_by(ls_sort_by);
    }

    if (ls_page){
      set_page(Number(ls_page));
    }
  },[])

  useEffect(()=>{
    window.localStorage.setItem('jp_filter', filter);
    window.localStorage.setItem('jp_sort_by', sort_by);
    window.localStorage.setItem('jp_page', page);
  },[filter, sort_by, page])

  return (
    <div className="logged-in-container">
      <CornerMenu />

      <p style={{display: 'block', marginRight: 30}} className="form-header">
        Job Providers
      </p>
      <div style={{display: 'inline'}}>
        <FilterButton
          onClick={e => handle_filter(e, 'PENDING')}
          active={filter === 'PENDING'}
          name="Pending"
        />

        {staff_level > 1 && 
        (
          <>
            <FilterButton
              onClick={e => handle_filter(e, 'DECLINED')}
              active={filter === 'DECLINED'}
              name="Declined"
            />
            <FilterButton
              onClick={e => handle_filter(e, 'APPROVED')}
              active={filter === 'APPROVED'}
              name="Approved"
            />
            <FilterButton
              onClick={e => handle_filter(e, 'DEACTIVATED')}
              active={filter === 'DEACTIVATED'}
              name="Deactivated"
            />
            <FilterButton
              onClick={e => handle_filter(e, 'ALL')}
              active={filter === 'ALL'}
              name="All"
            />
        </>
        )}
        <FilterSearch name_pattern={name_pattern}  />
      </div>

      <br />
      <br />
      <br />
      <ApprovalTableJP 
        filter={filter} 
        page={page} 
        set_page={set_page}
        name_pattern={name_pattern.value}
        sort_by={sort_by}
        set_sort_by={set_sort_by}
      />
    </div>
  );
};

export default ApprovalPageJP;
