import { useQuery } from "react-query"
import { get_jobs_jp } from "../../services/jobs";


const useJobsJP = (page, filters, company_name, sort_by) => {
    return useQuery(['get_jobs_jp', page, filters, company_name, sort_by],
      () => 
        get_jobs_jp(page, filters, company_name, sort_by))
}

export default useJobsJP;