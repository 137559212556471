import React, { useEffect, useState } from 'react'
import './style.css'
import WeekRange from './WeekRange';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useMatchDetail from '../../../customHooks/Timesheet/useMatchDetail';
import useCreateTimesheet from '../../../customHooks/Timesheet/useCreateTimesheet';

const normalize_date = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

const CreateTimesheet = () => {

    
    const [selectedDays, set_selectedDays] = useState([]);
    const [show_calendar, set_show_calendar] = useState(false);
    const handle_show_calendar = () => set_show_calendar(!show_calendar);
    const [error, set_error] = useState(false)

    const [match_id, set_match_id] = useState('');
    const [total_hours_worked, set_total_hours_worked] = useState(0);

    const [filters, set_filters] = useState({});

    const {data: match_data, isFetching} = useMatchDetail(filters)
    const do_create_timesheet = useCreateTimesheet()
    const [new_rate, set_new_rate] = useState(0);

    const handle_filter = (e) => {
        e.preventDefault();
        set_filters({
            id:match_id
        })
    }
    // one week before today
    const start_date = new Date();
    start_date.setDate(start_date.getDate() - 7);
   
    const check_form_valid = () => {
        if (
            selectedDays.length === 7 
            && total_hours_worked > 0 
            && new_rate > 0 
            && new_rate < 10000 
            && total_hours_worked < 169
            
        )
            if ( 
                selectedDays[0].getTime() < start_date.getTime()
                && selectedDays[6].getTime() > new Date(match_data.match_time).getTime()
                ) {
                // set_error(false)
                return true;
            }
        return false;
    }


    const handle_create_timesheet = e => {
        e.preventDefault();
        if (!check_form_valid()) {
            set_error(true)
        }
        else {
            set_error(false)
            const payload = {
                match_id: match_id,
                total_hours_worked,
                period_start: normalize_date(selectedDays[0]),
                period_end: normalize_date(selectedDays[6]),
                total_money: new_rate * total_hours_worked,
                rate: new_rate
            }
            do_create_timesheet.mutate(payload);
            do_reset()
        }
        
    }

    const do_reset = () => {
        set_match_id('');
        set_total_hours_worked(0);
        set_selectedDays([]);
        set_show_calendar(false);
        set_filters({});
        set_error(false)
    }

    const reset_form = (e) => {
        e.preventDefault();
        do_reset()
    }

    useEffect(() => {
        if (match_data) {
            set_new_rate(match_data.rate);
        }
    }, [match_data]);
    
    if (!match_data){

        return (
        <div  className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div  className="modal-dialog">
            <div className="modal-content custom-modal-content">
                <form onSubmit={handle_filter}>

                <div className="modal-body">
                    <div>
                        <p className='input-label'>Matching ID</p>
                        <input 
                          className='custom-modal-input-field'
                          value={match_id} 
                          onChange={(e)=>set_match_id(e.target.value)}
                        />
                    </div>  
                    {isFetching && <span style={{float:'right'}} className='error-label'>ID not valid.</span>}
                </div>
                <p></p>
                <div className="modal-footer">
                    <button 
                        className='custom-modal-reset-button' 
                        type="button"
                        data-bs-dismiss="modal"
                    >Close</button>

                    <button 
                        type="submit"  
                        className='custom-modal-ok-button' 
                    >Search</button>
                </div>
            </form>
            </div>
        </div>
        </div>)
    }

    else {

      return ( 

        <div  className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div  className="modal-dialog">
            <div className="modal-content custom-modal-content">
            
            <form onSubmit={handle_create_timesheet}>

            <div className="modal-body">
                {/* given */}
                <div style={{display:'flex'}}>

                    <div style={styles.child}>
                        <p>Job Seeker</p>
                        <span style={styles.info}>{match_data.job_seeker.id}</span> <br/>
                        <span style={styles.info}>{match_data.job_seeker.name}</span> 
                    </div>

                    <div style={styles.child}>
                        <p>Vendor</p>
                        <span style={styles.info}>{match_data.company.id}</span> <br/>
                        <span style={styles.info}>{match_data.company.name}</span> 
                    </div>

                    <div style={styles.child}>
                        <p>Current Rate</p>
                        <span style={{fontSize:24, fontWeight:"bold", color:'#445566'}} >${match_data.rate}/h</span>
                    </div>
                </div>
                <hr/>
                {/* inputs */}
                <div style={{display:'flex'}}>

                    <div style={styles.child}>
                        <p className='input-label'>Matching ID</p>
                        <input disabled className='custom-modal-input-field' onChange={null} value={match_data.id}/>
                    </div>

                    <div style={styles.child}>
                        <p className='input-label' >Week period </p>
                        <p onClick={handle_show_calendar}>
                           {selectedDays[0]  ?  new Intl.DateTimeFormat('en-US').format(Date.parse(selectedDays[0])) : <span style={{fontSize:14}}>MM/DD/YYYY</span>} 
                           {' - '}
                           {selectedDays[6]  ?  new Intl.DateTimeFormat('en-US').format(Date.parse(selectedDays[6])) : <span style={{fontSize:14}}>MM/DD/YYYY</span>} 

                           {!show_calendar ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/> }
                        </p>
                        {show_calendar && <WeekRange set_selectedDays={set_selectedDays} selectedDays={selectedDays}/>}
                    </div>

                </div><br/>

                <div style={{display:'flex'}}>
                    <div style={styles.child}>
                        <p className='input-label'>Hours worked</p>
                        <input className='custom-modal-input-field'  type='number' value={total_hours_worked} onChange={e=>set_total_hours_worked(e.target.value)} />
                    </div>

                    <div style={{...styles.child, textAlign:'right' }}>
                        <p className='input-label'>New Rate</p>
                        <input className='custom-modal-input-field' type='number' value={new_rate} onChange={e=>set_new_rate(e.target.value)}  />
                    </div>
                </div><hr/>

                <div >
                    <div style={{textAlign:'right'}}>
                        <p className='input-label'>Total </p>
                        <span>
                            {(new_rate * total_hours_worked).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })} 
                        </span>
                    </div>
                </div><br/>

                {error && <p className='error-label'>Please choose week period and hours worked and make sure you enter a valid period</p>}
                <hr/>
                <div style={{textAlign:'right'}}>
                    <button 
                        onClick={reset_form} 
                        type="button" 
                        className="custom-modal-reset-button" 
                        data-bs-dismiss="modal"
                    >Close</button>
                    <button 
                        style={{
                            backgroundColor: !check_form_valid() ? 'rgba(36, 154, 250, 0.5' : '#249afa'
                        }} 
                        className="custom-modal-ok-button" 
                        type='submit'
                        // onClick={handle_create_timesheet}
                    > Submit
                    </button>
                </div>
           </div>
           </form>


            </div>
        </div>
        </div>
      )
    }
}


const styles = {
    child:{
        flex:1,
    },
    info:{
        color:'#445566',
        fontWeight:'bold'
    },   
}

export default CreateTimesheet;