import React, {useState} from 'react';
import useUser from '../../customHooks/AuthHooks/useUser';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Button, Menu, MenuItem} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import {useHistory} from 'react-router-dom';

const CornerMenu = () => {
  const {data: user_data, isLoading} = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = e => {
    e.preventDefault();
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <div style={{float: 'right'}}>
      <img
        alt="Rapihire profile"
        src={user_data?.profile_image}
        style={{
          width: 25,
          height: 25,
          borderRadius: 20,
          marginRight: 10,
          marginBottom: 5,
        }}
      />
      {isLoading ? '....' : user_data?.first_name}

      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}>

        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default CornerMenu;
