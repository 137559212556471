/* eslint-disable no-undef */
import React, { useRef, useState } from 'react'
import useAllPositions from '../../../customHooks/Jobs/useAllPositions';
import useAllCountries from '../../../customHooks/other/useAllCountries';
import useFilterJSCommunication from '../../../customHooks/other/useFilterJSCommunication';
import { get_autocomplete_certifications, get_autocomplete_cities, get_autocomplete_languages, get_autocomplete_licenses, get_autocomplete_provinces } from '../../../services/communication';
import CornerMenu from '../../Auth/CornerMenu';
import MultiSelect from '../../Other/MultiSelect';
import AsyncSelect from 'react-select/async';
// import EmailsModal from './Modals/EmailsModal';
// import { Select } from '@material-ui/core';
import Select from 'react-select';
import useSendEmailJS from '../../../customHooks/other/useSendEmailJS';
import { error_message, success_message } from '../../../global_state';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';


const load_cities = async (provinces, city_pattern) => {
  try {
    const data = await get_autocomplete_cities(provinces, city_pattern);
    const cities_options = data.cities.map((option) => {
      return { value: option.id, label: option.name };
    })
    return cities_options
  }
  catch (err){
    console.log(err);
    return []
  }
}


const load_provinces = async (countries, province_pattern) => {
  try {
    const data = await get_autocomplete_provinces(countries, province_pattern);
    const provinces_options = data.provinces.map((option) => {
      return { value: option.id, label: option.name };
    })
    return provinces_options
  }
  catch (err){
    console.log(err);
    return []
  }
}

const load_languages = async (language_pattern) => {
  try {
    const data = await get_autocomplete_languages(language_pattern);
    const languages_options = data.languages.map((option) => {
      return { value: option.id, label: option.name };
    })
    return languages_options
  }
  catch (err){
    console.log(err);
    return []
  }
}

const load_certifications = async (certification_pattern) => {
  try {
    const data = await get_autocomplete_certifications(certification_pattern);
    const certifications_options = data.certificates.map((option) => {
      return { value: option.id, label: option.name };
    })
    return certifications_options
  }
  catch (err){
    console.log(err);
    return []
  }
}

const load_licenses = async (license_pattern) => {
  try {
    const data = await get_autocomplete_licenses(license_pattern);
    const licenses_options = data.licenses.map((option) => {
      return { value: option.id, label: option.name };
    })
    return licenses_options
  }
  catch (err){
    console.log(err);
    return []
  }
}

const fieldOptions = [
  { value: '{{first_name}}', label: 'First Name'},
  { value: '{{last_name}}', label: 'Last Name'},
  { value: '{{email}}', label: 'Email' },
  { value: '{{zip_code}}', label: 'ZIP Code' },
  { value: '{{address}}', label: 'Address' },
  { value: '{{city}}', label: 'City' },
  { value: '{{province}}', label: 'Province' },
  { value: '{{country}}', label: 'Country' },
]

const filterOptions = [
  // { value: 'identity', label: 'Identity'},
  // { value: 'general', label: 'General'},
  { value: 'interested_position', label: 'Interested Position'},
  { value: 'address', label: 'Address'},
  { value: 'languages', label: 'Languages'},
  { value: 'certifications', label: 'Certifications'},
  { value: 'licenses', label: 'Licenses'},
  { value: 'status', label: 'Status'},
]

const statusOptions = [
  // { value: 'identity', label: 'Identity'},
  // { value: 'general', label: 'General'},
  { value: 'All', label: 'All'},
  { value: 'Approved', label: 'Approved'},
  { value: 'Pending', label: 'Pending'},
  { value: 'Declined', label: 'Declined'},
  { value: 'Rejected', label: 'Rejected'},

]


const CommunicationJS = () => {

    const set_success = useSetRecoilState(success_message)
    const set_error = useSetRecoilState(error_message)
    const history = useHistory()
    // IDENTITY
    const [identity_condition, set_identity_condition] = useState('AND')
    const [first_name, set_first_name] = useState('')
    const [last_name, set_last_name] = useState('')
    const [js_email, set_js_email] = useState('')
    const [js_phone, set_js_phone] = useState('')

    const [email_subject, set_email_subject] = useState('')

    // GENERAL
    const [general_condition, set_general_condition] = useState('AND')
    const [available_for_work, set_available_for_work] = useState(true)
    const [job_distance, set_job_distance] = useState(60)
    const [job_distance_comparison, set_job_distance_comparison] = useState('equal_to')
    const [commute_time, set_commute_time] = useState(30)
    const [commute_time_comparison, set_commute_time_comparison] = useState('equal_to')
    

    // INTERESTED POSITION
    const [position_condition, set_position_condition] = useState('AND')
    const [interested_positions, set_interested_positions] = useState([])
    const [salary_comparison, set_salary_comparison] = useState('equal_to')
    const [salary_value, set_salary_value] = useState(20)

    // ADDRESS
    const [countries, set_countries] = useState([])
    const [provinces, set_provinces] = useState([])
    const [cities, set_cities] = useState([])
    const [address_condition, set_address_condition] = useState('AND')
    const [email, set_email] = useState('')
    const cities_ref = useRef()
    const provinces_ref = useRef()


    // LANGUAGES
    const [languages_condition, set_languages_condition] = useState('AND')
    const [selected_languages, set_selected_languages] = useState([])
    const [language_pattern, set_language_pattern] = useState('')
    const languages_ref = useRef()
    const handleLanguageChange = selectedOptions => {
      set_selected_languages(selectedOptions.map(i=>i.value))
    };

    // CERTIFICATIONS
    const [certifications_condition, set_certifications_condition] = useState('AND')
    const [selected_certifications, set_selected_certifications] = useState([])
    const [certification_pattern, set_certification_pattern] = useState('')
    const certifications_ref = useRef()
    const handleCertificationChange = selectedOptions => {
      set_selected_certifications(selectedOptions.map(i=>i.value))
    };

    // LICENSES
    const [licenses_condition, set_licenses_condition] = useState('AND')
    const [selected_licenses, set_selected_licenses] = useState([])
    const [license_pattern, set_license_pattern] = useState('')
    const licenses_ref = useRef()
    const handleLicenseChange = selectedOptions => {
      set_selected_licenses(selectedOptions.map(i=>i.value))
    };

    // STATUS
    const [selected_status, set_selected_status] = useState('')

    // WORK EXPERIENCE
    const [selected_experience_condition, set_selected_experience_condition] = useState('')

    const {data: filtered_js_data, isLoading: loading_js, isFetching } = useFilterJSCommunication(
        // interested positions
        position_condition,
        interested_positions,
        salary_comparison,
        salary_value,
        // address
        address_condition,
        cities,
        provinces,
        countries,
        // languages
        selected_languages,
        languages_condition,
        // certifications
        selected_certifications,
        certifications_condition,
        // licenses
        selected_licenses,
        licenses_condition,
        // status
        selected_status,
        selected_experience_condition
      )

    const {mutate: do_send_emails, isLoading: sending_emails} = useSendEmailJS()
    const handle_email = (e) => {
        e.preventDefault();
        const email_body = document.getElementById('summernoteJS').value;
        // console.log(email_body);
        if (email_body){
            const filter_payload = {
              email_body,
              email_subject,
              position_condition,
              interested_positions,
              salary_comparison,
              salary_value,
              address_condition,
              cities,
              provinces,
              countries,
              // languages
              selected_languages,
              languages_condition,
              // certifications
              selected_certifications,
              certifications_condition,
              // licenses
              selected_licenses,
              licenses_condition,
              // status
              selected_status,
              selected_experience_condition
            }
            // console.log('sending');
            do_send_emails(filter_payload)
            set_success('Sending messages!')
            history.push('/')
            setTimeout(() => {
              set_success('')
            }, 4000);
        }
        else{
          set_error('Empty message, please check the email body!')
            setTimeout(() => {
              set_error('')
            }, 4000);
        }
        
        // document.getElementById('summernoteJS').value = ''
    }

    const positionList = useAllPositions()
    const countriesList = useAllCountries()
  
    const handlePositionChange = selectedOptions => {
      set_interested_positions(selectedOptions.map(i=>i.value))
    };
    const handleCountryChange = selectedOptions => {
      set_provinces([])
      set_cities([])
      cities_ref.current.clearValue();
      provinces_ref.current.clearValue();
      set_countries(selectedOptions.map(i=>i.value))
    };
    const handleProvinceChange = selectedOptions => {
      set_cities([])
      cities_ref.current.clearValue();
      set_provinces(selectedOptions.map(i=>i.value))
    };
    const handleCityChange = selectedOptions => {
      set_cities(selectedOptions.map(i=>i.value))
    };

    let positions_options = {}
    let countries_options = {}

    if(positionList.data){
      positions_options = positionList.data.map((option) => {
            return { value: option.id, label: option.name };
        })
    }
    if(countriesList.data){
      countries_options = countriesList.data.map((option) => {
            return { value: option.id, label: option.name };
        })
    }

    const [city_pattern, set_city_pattern] = useState('')
    const [province_pattern, set_province_pattern] = useState('')

    const [filters, set_filters] = useState([])

    const handleFilterCange = selectedOptions => {
      set_filters(selectedOptions.map(i=>i.value))

      // Remove the filters after deleting some filter categories

      const curr_options = selectedOptions.map(i=>i.value);
      if (!curr_options.includes('interested_position')){
        set_interested_positions([]);
      }
      if (!curr_options.includes('address')){
        set_countries([]);
        set_provinces([]);
        set_cities([]);
      }
      if (!curr_options.includes('languages')){
        set_selected_languages([]);
      }
      if (!curr_options.includes('certifications')){
        set_selected_certifications([]);
      }
      if (!curr_options.includes('licenses')){
        set_selected_licenses([]);
      }
      if (!curr_options.includes('status')){
        set_selected_status('');
        set_selected_experience_condition('');

      }
    };
    
    

    return (
      <div className='logged-in-container'>
        <CornerMenu/>
        <p className='form-header'>Communication page</p>
        <p className='h6 text-dark'>Please filter the receipents</p>

        <div className='card p-2 mb-4'>
          <p className='h6'>Filters</p>
          <MultiSelect
              options = {filterOptions}
              onChange = {handleFilterCange}
              placeholder={"Choose filters..."}
          />
        </div>
        {
        filters.includes('identity')
        &&
        <div className='card mt-4'>
          <p className='fw-bold m-3 mb-0'>Identity</p>
          <div className='d-flex p-2 pt-0'>  
          <div className='m-2'>
            <span>Condition</span>
            <Select
              options = {[{value:'OR', label:'OR'},{value:'AND', label:'AND'}]}
              defaultValue={{value:'AND', label:'AND'}}
              onChange={(s) => set_identity_condition(s.value)}
            />
          </div>        
          <div className='m-2'>
            <span>First Name</span>
            <input value={first_name} onChange={e=>set_first_name(e.target.value)} className='form-control' />
          </div>
          
          <div className='m-2'>
            <span>Last Name</span>
            <input value={last_name} onChange={e=>set_last_name(e.target.value)}  className='form-control' />
          </div>

          <div className='m-2'>
            <span>Email</span>
            <input value={js_email} onChange={e=>set_js_email(e.target.value)}  className='form-control' />
          </div>

          <div className='m-2'>
            <span>Phone</span>
            <input value={js_phone} onChange={e=>set_js_phone(e.target.value)}  className='form-control' />
          </div>
      
          </div>
        </div>
        }
        {
        filters.includes('general')
        &&
        <div className='card mt-4'>
          <p className='fw-bold m-3 mb-0'>Genral</p>
          <div className='d-flex p-2 pt-0'>  
          <div className='m-2'>
            <span>Condition</span>
            <Select
              options = {[{value:'OR', label:'OR'},{value:'AND', label:'AND'}]}
              defaultValue={{value:'AND', label:'AND'}}
              onChange={(s) => set_general_condition(s.value)}
            />
          </div>        
          <div className='m-2'>
            <span>Available for work</span> <br/>
            <input checked={available_for_work} onChange={e=>set_available_for_work(!available_for_work)} type='checkbox' className='checkbox' />
          </div>
          
          <div className='m-2'>
            <span>Job Distance</span>
            <div className='d-flex'>
              <select
                className='form-control'
                onChange={e => set_job_distance_comparison(e.target.value)}
               >
                <option  value="equal_to">equal to</option>
                <option value="less_than">less than</option>
                <option value="bigger_than">bigger than</option>
              </select>
              <div className="input-group">
                <input 
                  value={job_distance} 
                  onChange={e=>set_job_distance(e.target.value)} 
                  type="number" 
                  className="form-control" 
                />
                <span className="input-group-text"> miles </span>
              </div>
            </div>
          </div>


          <div className='m-2'>
            <span>Commute time</span>
            <div className='d-flex'>
              <select
                className='form-control'
                onChange={e => set_commute_time_comparison(e.target.value)}
               >
                <option  value="equal_to">equal to</option>
                <option value="less_than">less than</option>
                <option value="bigger_than">bigger than</option>
              </select>
              <div className="input-group">
                <input 
                  value={commute_time} 
                  onChange={e=>set_commute_time(e.target.value)} 
                  type="number" 
                  className="form-control" 
                />
                <span className="input-group-text"> minutes</span>
              </div>
            </div>
          </div>
       
          </div>
        </div>
        }
        {
        filters.includes('interested_position')
          &&
        <div className='card'>
          <p className='fw-bold m-3 mb-0'>Interested Position</p>
          <div className='d-flex p-2 pt-0'>
          <div className='m-2'>
            <span>Condition</span>
            <Select 
              options = {[{value:'OR', label:'OR'},{value:'AND', label:'AND'}]}
              defaultValue={{value:'AND', label:'AND'}}
              onChange={(s) => set_position_condition(s.value)}
            />
          </div>
          
          <div className='m-2'>
            <span >Positions</span>
            <MultiSelect
              options = {positions_options}
              onChange = {handlePositionChange}
              placeholder={"Filter By Interested Position"}
            />
          </div>
          <div className='m-2'>
            <span>Desired Salary</span>
            <div className='d-flex'>
              <select
                className='form-control'
                onChange={e => set_salary_comparison(e.target.value)}
               >
                <option  value="equal_to">equal to</option>
                <option value="less_than">less than</option>
                <option value="bigger_than">bigger than</option>
              </select>
              <div className="input-group">
                <span className="input-group-text">$</span>
                <input value={salary_value} onChange={e=>set_salary_value(e.target.value)} type="number" className="form-control" />
                <span className="input-group-text">/ hour</span>
              </div>
            </div>
          </div>
        </div>
        </div>
        }
        
        {
        filters.includes('address')
        &&
        <div className='card mt-4'>
          <p className='fw-bold m-3 mb-0'>Address</p>
          <div className='d-flex p-2 pt-0'>  
          <div className='m-2'>
            <span>Condition</span>
            <Select
              options = {[{value:'OR', label:'OR'},{value:'AND', label:'AND'}]}
              defaultValue={{value:'AND', label:'AND'}}
              onChange={(s) => set_address_condition(s.value)}
            />
          </div>        
          <div className='m-2'>
            <span>Country</span>
            <MultiSelect
              options = {countries_options}
              onChange = {handleCountryChange}
              placeholder={"Filter By Country"}
            /> 
          </div>
          <div className='m-2'>
            <span>Province</span>
            <AsyncSelect
              ref={provinces_ref}
              isMulti
              onChange = {handleProvinceChange}
              placeholder={"Start typing for province..."}
              loadOptions = {()=>load_provinces(countries, province_pattern)}
              onInputChange = {(inp) => set_province_pattern(inp)}
            />
          </div>
          <div className='m-2'>
            <span>City</span>
            <AsyncSelect
              ref={cities_ref}
              isMulti
              onChange = {handleCityChange}
              placeholder={"Start typing for city..."}
              loadOptions = {()=>load_cities(provinces, city_pattern)}
              onInputChange = {(inp) => set_city_pattern(inp)}
            />
          </div>
        </div>
        </div>
        }
        <br/>
        {
        filters.includes('languages')
        &&
        <div className='card mt-4'>
          <p className='fw-bold m-3 mb-0'>Languages</p>
          <div className='d-flex p-2 pt-0'>  
          <div className='m-2'>
            <span>Condition</span>
            <Select
              options = {[{value:'MISSING', label:'MISSING'},{value:'AND', label:'AND'}]}
              defaultValue={{value:'AND', label:'AND'}}
              onChange={(s) => set_languages_condition(s.value)}
            />
          </div>
          <div className='m-2'>
            <span>Languages</span>
            <AsyncSelect
              ref={languages_ref}
              isMulti
              onChange = {handleLanguageChange}
              placeholder={"Start typing for language..."}
              loadOptions = {()=>load_languages(language_pattern)}
              onInputChange = {(inp) => set_language_pattern(inp)}
            />
          </div>
      
        </div>
        </div>
        }
        <br/>

        {/* CERTIFICATIONS */}
        {
        filters.includes('certifications')
        &&
        <div className='card mt-4'>
          <p className='fw-bold m-3 mb-0'>Certifications</p>
          <div className='d-flex p-2 pt-0'>  
          <div className='m-2'>
            <span>Condition</span>
            <Select
              options = {[{value:'MISSING', label:'MISSING'},{value:'AND', label:'AND'}]}
              defaultValue={{value:'AND', label:'AND'}}
              onChange={(s) => set_certifications_condition(s.value)}
            />
          </div>
          <div className='m-2'>
            <span>Certifications</span>
            <AsyncSelect
              ref={certifications_ref}
              isMulti
              onChange = {handleCertificationChange}
              placeholder={"Start typing for language..."}
              loadOptions = {()=>load_certifications(certification_pattern)}
              onInputChange = {(inp) => set_certification_pattern(inp)}
            />
          </div>
    
        </div>
        </div>
        }
        <br/>

        {/* LICENSES */}
        {
        filters.includes('licenses')
        &&
        <div className='card mt-4'>
          <p className='fw-bold m-3 mb-0'>Licenses</p>
          <div className='d-flex p-2 pt-0'>  
          <div className='m-2'>
            <span>Condition</span>
            <Select
              options = {[{value:'MISSING', label:'MISSING'},{value:'AND', label:'AND'}]}
              defaultValue={{value:'AND', label:'AND'}}
              onChange={(s) => set_licenses_condition(s.value)}
            />
          </div>
          <div className='m-2'>
            <span>Licenses</span>
            <AsyncSelect
              ref={licenses_ref}
              isMulti
              onChange = {handleLicenseChange}
              placeholder={"Start typing for language..."}
              loadOptions = {()=>load_licenses(license_pattern)}
              onInputChange = {(inp) => set_license_pattern(inp)}
            />
          </div>
    
        </div>
        </div>
        }
        <br/>
        {/* STATUS */}
        {
        filters.includes('status')
        &&
        <div className='card mt-4'>
          <p className='fw-bold m-3 mb-0'>Status</p>
          <div className='d-flex p-2 pt-0'>  
          <div className='m-2'>
            <span>Status</span>
            <Select
              options = {statusOptions}
              defaultValue={statusOptions[0]}
              onChange={(s) => set_selected_status(s.value)}
            />
          </div>
          <div className='m-2'>
            <span>Added Work Experience</span>
            <Select
              options = {[{value:'YES', label:'YES'},{value:'NO', label:'NO'}]}
              defaultValue={{value:'YES', label:'YES'}}
              onChange={(s) => set_selected_experience_condition(s.value)}
            />
          </div>
      
        </div>
        </div>
        }
        <br/>        
        {(filtered_js_data && !loading_js)
        ? 
          <span className='badge bg-success'>
            {filtered_js_data.total} total users filtered!
          </span>
        : null}
       
       {loading_js && 
          <span className='badge bg-info'>
            Filtering users...
          </span>
       }
        <br/>
        <br/>
        <Select 
          placeholder={"Add field"}
          options={fieldOptions}
          onChange={(e)=> {
            $('#summernoteJS').summernote('saveRange')
            $('#summernoteJS').summernote('restoreRange')
            $('#summernoteJS').summernote('focus')
            $('#summernoteJS').summernote('insertText', e.value)
          }}
        />
        <br/>
        <input 
          className='form-control' 
          placeholder='Email Subject'
          value={email_subject}
          onChange={(e)=>set_email_subject(e.target.value)}
        />
        <form onSubmit={handle_email}>
            <textarea 
              value={email} 
              id="summernoteJS" 
              // onChange={(e)=>console.log()} 
              type='hidden' 
              name="editordata"
            >
            </textarea>
            <button className='btn btn-primary mt-2 w-25' type='submit'>Send email</button>
        </form>

      {/* <EmailsModal emails={filtered_js_data.emails} /> */}
      </div>
    )
}

export default CommunicationJS;
