import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

const styles = {
  row:{
    backgroundColor:'#fff', 
    border:'0.6px solid #a3bbca',
    height:60
  }
}

const TableLoading = () => {
    return (
    <div>
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
        <Skeleton style={styles.row} variant="text"  />
    </div>
    )
}

export default TableLoading;