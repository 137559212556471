import axios from "axios"
import base_url from "./util"

const get_token = () => {
  const token = window.localStorage.getItem('token')
  return `token ${token}`
}


export const handle_login = async (payload) => {
  payload = {
    email: payload.email.value,
    password: payload.password.value
  }
  const {data} = await axios.post(`${base_url}accounts/staff_token/`, payload)
  return data
}


export const get_user_info = async () => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}accounts/me`, config)
  return data
}

export const handle_register_recruiter = async (payload) => {
  const {data} = await axios.post(`${base_url}accounts/create/recruiter/`, payload)
  return data
}

export const handle_register_admin = async (payload) => {
  const {data} = await axios.post(`${base_url}accounts/create/admin/`, payload)
  return data
}