import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from '../../global_state'
import { add_new_skill } from "../../services/jobs";


const useAddSkill = () => {
  const set_error = useSetRecoilState(error_message)
  const set_success = useSetRecoilState(success_message)

  return  useMutation((skill_name)=>add_new_skill(skill_name), {
      onSuccess: data => {
        set_success('Successfully added skill!')
        setTimeout(() => {
          set_success('')
        }, 4000);
      },
      onError: () => {
        set_error(`Skill already exists!`)
        setTimeout(() => {
          set_error('')
        }, 4000);
      }
  })
}

export default useAddSkill;
