import { useMutation, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from '../../global_state'
import { edit_staff } from "../../services/admin";


const  useEditStaff = () => {
  const queryClient = useQueryClient()
  const set_error = useSetRecoilState(error_message)
  const set_success = useSetRecoilState(success_message)

  return  useMutation((payload)=>edit_staff(payload), {
      onSuccess: data => {
        set_success('Successfully edited!')
        setTimeout(() => {
          set_success('')
        }, 4000);
        queryClient.invalidateQueries('get_invitations')
      },
      onError: () => {
        set_error('Problem editing!')
        setTimeout(() => {
          set_error('')
        }, 4000);
      }
  })
}

export default useEditStaff;