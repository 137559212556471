import React from 'react'
import useAddLicense from '../../../../customHooks/other/useAddLicense'
import { useField } from '../../../../customHooks/other/useField'


const NewLicenseModal = () => {

    const license = useField('text')
    const {mutate: do_add_license, isLoading: adding_license} = useAddLicense()

    const add_license = (e) => {
        e.preventDefault()
        do_add_license(license.value)
        license.setvalue('')
    }
    
   return (
        <div  className="modal fade" id="showNewLicenseModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="showNewLicenseModal" aria-hidden="true">
        <div  className="modal-dialog">
            <div className="modal-content custom-modal-content">

                <div className="modal-body">
                    
                    <p className='form-header'>Add New License</p>
                    <p className='input-label'>License name</p>
                    <input {...license} className='custom-modal-input-field'/>
                </div>


                <div className="modal-footer">
                    <button 
                        className='custom-modal-reset-button' 
                        type="button"
                        data-bs-dismiss="modal"
                    >Close</button>
                    <button 
                        className='custom-modal-ok-button' 
                        type="button"
                        // data-bs-dismiss="modal"
                        onClick={add_license}
                        disabled={adding_license}
                    >{adding_license ? 'Adding...' : 'Add' }</button>

                </div>
            </div>
        </div>
        </div>
    )
}


export default NewLicenseModal;