
import { useMutation } from "react-query"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from "../../global_state";
import { convert_company_to_job_seeker } from "../../services/approvals";


const useConvertoToJS = (user_id) => {
    const set_success = useSetRecoilState(success_message)
    const set_error = useSetRecoilState(error_message)
    const history = useHistory()
    return useMutation( (id)=>convert_company_to_job_seeker(id), {
        onSuccess: data => {
            set_success('Successfully coverted user to job seeker')
            setTimeout(() => {
                set_success('')
              }, 5000);
            history.push(`/jp/approvallist/`)
        },
        onError: () => {
            set_error('There was a problem! Please try again later!')
            setTimeout(() => {
              set_error('')
            }, 4000);
          }
    })
}

export default useConvertoToJS;