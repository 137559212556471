import { Box } from '@material-ui/core';
import { CallMadeOutlined } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import React from 'react'
import { Link } from 'react-router-dom';
import useJobSeekers from '../../../../customHooks/Approvals/UseJobSeekers';
import TableLoading from '../../../Other/Skeletons/TableLoading';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import useUser from '../../../../customHooks/AuthHooks/useUser';

const approved_color = (approved) => {
    if (approved==='Declined') return '#ee5566'
    if (approved==='Approved') return '#00ccaa'
    if (approved==='Deactivated') return 'black'
    return '#ffbb00'
}

const styles = {
  sort:{
    backgroundColor:'#fff',
    border:'none',
    margin:0,
    padding:0
  }
}

const ApprovalTableJS = ({
  filter, 
  page,
  set_page,
  name_pattern,
  sort_by,
  interested_positions,
  countries,
  provinces,
  cities,
  set_sort_by
}) => {
  
  const {data: job_seekers_data, isLoading, isFetching } = useJobSeekers(
    page,
    filter,
    name_pattern,
    interested_positions,
    countries,
    provinces,
    cities,
    sort_by
  )
  const {data: user_data} = useUser();
  const staff_level = user_data?.staff_level

  if (isLoading || isFetching) return <TableLoading/>

  const handle_page = (e, value) => {
    e.preventDefault()
    set_page(value)
  }

  return (
    <div>

        <table className='recruiter-table'>
        <thead>
        
        <tr>
            <th className='align-left-col'>Name
            <button style={styles.sort} 
                onClick={(e)=>{
                  e.preventDefault();
                  if (sort_by === 'user__first_name' )set_sort_by('-user__first_name')
                  else set_sort_by('user__first_name')
                }}>
                <UnfoldMoreIcon fontSize="inherit"/>
            </button>
            </th>
            <th >Status
            <button style={styles.sort} 
                  onClick={(e)=>{
                    e.preventDefault();
                    if (sort_by === 'approved_by_system' )set_sort_by('-approved_by_system')
                    else set_sort_by('approved_by_system')
                  }}>
                  <UnfoldMoreIcon fontSize="inherit"/></button>
            </th>
            <th className='align-right-col'>Submission date
            <button style={styles.sort} 
                  onClick={(e)=>{
                    e.preventDefault();
        
                    if (sort_by === 'user__createdAt' )set_sort_by('-user__createdAt')
                    else set_sort_by('user__createdAt')
                  }}>
                  <UnfoldMoreIcon fontSize="inherit"/></button>
            </th>
        </tr>

        </thead>

        <tbody>
        {job_seekers_data.results.map(js => (
          <tr key={js.id}>
            <td className='align-left-col'>
              <Link 
                to={`/js/approvallist/${js.id}`} 
                style={{textDecoration:"none", color:'inherit'}}
              > 
                {js.user.first_name} {js.user.last_name} 
              </Link> 
            </td>
            <td style={{color: approved_color(js.approved_by_system)}}> 
              {js.approved_by_system} 
              <Link className='td-link' to={`/approvalhistory/${js.user.id}?user=Job Seeker ${js.user.first_name} ${js.user.last_name}`}>
                 <CallMadeOutlined fontSize="small" />
              </Link>{" "}
            </td>
            <td className='align-right-col'>{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium'}).format(Date.parse(js.user.createdAt))}</td>
          </tr>
        ))}

        <tr>
          <td></td>
          <td></td>
          <td>
            { staff_level > 2 && 
            <a
              target="_blank"
              href="https://api.rapihire.com/api/download/job-seekers-info/"
              // href="http://localhost:8000/api/download/job-seekers-info/"
              style={{
                textDecoration: "none",
                fontWeight: 700,
                color: "#0099ee",
                float: "right",
              }}
            >
              Export all job seekers as csv
            </a>
          }
          </td>

        </tr>
        
        
        </tbody>
        </table>
        
        <Box 
          className='pagination-div'
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Pagination 
            count={Math.ceil(job_seekers_data.count/10)} 
            page={page} 
            onChange={handle_page}           
          />
        </Box>
          
    </div>
    )
}



export default ApprovalTableJS;