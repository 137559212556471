import React, { useEffect, useRef, useState } from 'react'
import { useField } from '../../../../customHooks/other/useField'
import CornerMenu from '../../../Auth/CornerMenu'
import FilterButton from '../../../Other/FilterButton'
import FilterSearch from '../../../Other/FilterSearch'
import ApprovalTableJS from './ApprovalTableJS'
import MultiSelect from '../../../Other/MultiSelect'
import useAllPositions  from '../../../../customHooks/Jobs/useAllPositions';
import { get_autocomplete_cities, get_autocomplete_provinces } from '../../../../services/communication'
import useAllCountries from '../../../../customHooks/other/useAllCountries'
import AsyncSelect from 'react-select/async';
import useUser from '../../../../customHooks/AuthHooks/useUser'


const load_cities = async (provinces, city_pattern) => {
  try {
    const data = await get_autocomplete_cities(provinces, city_pattern);
    const cities_options = data.cities.map((option) => {
      return { value: option.id, label: option.name };
    })
    return cities_options
  }
  catch (err){
    console.log(err);
    return []
  }
}


const load_provinces = async (countries, province_pattern) => {
  try {
    const data = await get_autocomplete_provinces(countries, province_pattern);
    const provinces_options = data.provinces.map((option) => {
      return { value: option.id, label: option.name };
    })
    return provinces_options
  }
  catch (err){
    console.log(err);
    return []
  }
}

const ApprovalPageJS = () => {
    const [filter, set_filter] = useState('PENDING')
    const [page, set_page] = useState(1)
    const name_pattern = useField('text')
    const [sort_by, set_sort_by] = useState('user__createdAt')
    const [interested_positions, set_interested_positions] = useState([])
    const {data: user_data} = useUser();
    const staff_level = user_data?.staff_level

    // Maybe this can be refactored
    const positionList = useAllPositions()
    let positions = {}

    if(positionList.data){
      positions = positionList.data.map((option) => {
            return { value: option.id, label: option.name };
        })
    }

    // ADDRESS
    const countriesList = useAllCountries()
    const [countries, set_countries] = useState([])
    const [provinces, set_provinces] = useState([])
    const [cities, set_cities] = useState([])
    const cities_ref = useRef()
    const provinces_ref = useRef()
    const [city_pattern, set_city_pattern] = useState('')
    const [province_pattern, set_province_pattern] = useState('')
    let countries_options = {}

    if(countriesList.data){
      countries_options = countriesList.data.map((option) => {
            return { value: option.id, label: option.name };
        })
    }

    const handleCountryChange = selectedOptions => {
      set_provinces([])
      set_cities([])
      cities_ref.current.clearValue();
      provinces_ref.current.clearValue();
      set_countries(selectedOptions.map(i=>i.value))
    };
    const handleProvinceChange = selectedOptions => {
      set_cities([])
      cities_ref.current.clearValue();
      set_provinces(selectedOptions.map(i=>i.value))
    };
    const handleCityChange = selectedOptions => {
      set_cities(selectedOptions.map(i=>i.value))
    };


    const handle_filter = (e,filter_name) => {
        e.preventDefault()
        set_page(1)
        set_filter(filter_name)
        if (filter_name==='PENDING'){
            set_sort_by('user__createdAt');
        }else{
            set_sort_by('-user__createdAt')
        }
    }

    useEffect(()=>{
        // Check from localstorage if we have some filtering or page

        // already taking place
        const ls_filter = window.localStorage.getItem('js_filter');
        const ls_sort_by = window.localStorage.getItem('js_sort_by');
        const ls_page = window.localStorage.getItem('js_page');
        if (ls_filter){
          set_filter(ls_filter);
        }

        if (ls_sort_by){
          set_sort_by(ls_sort_by);
        }


        if (ls_page){
          set_page(Number(ls_page));
        }
      },[])

    useEffect(()=>{
        window.localStorage.setItem('js_filter', filter);
        window.localStorage.setItem('js_sort_by', sort_by);
        window.localStorage.setItem('js_page', page);
      },[filter, sort_by, page])

    const handleChange = selectedOptions => {
        set_interested_positions(selectedOptions.map(i=>i.value))
    };


    return (
        <div className='logged-in-container'>
            <CornerMenu/>
            <p style={{display:'block', marginRight:30}} className='form-header'>Job Seekers</p>
            <div style={{display:'block'}}>
                <FilterButton onClick={(e)=>handle_filter(e,'PENDING')} active={filter==='PENDING'} name='Pending'  />
                {staff_level > 1 && 
                (<>
                  <FilterButton onClick={(e)=>handle_filter(e,'DECLINED')} active={filter==='DECLINED'} name='Declined' />
                  <FilterButton onClick={(e)=>handle_filter(e,'APPROVED')} active={filter==='APPROVED'} name='Approved' />
                  <FilterButton onClick={(e)=>handle_filter(e,'DEACTIVATED')} active={filter==='DEACTIVATED'} name='Deactivated' />
                  <FilterButton onClick={(e)=>handle_filter(e,'REJECTED')} active={filter==='REJECTED'} name='Rejected' />
                  <FilterButton onClick={(e)=>handle_filter(e,'ALL')} active={filter==='ALL'} name='All' />
                </>
                )}
                <FilterSearch placeholder="Name, email or id" name_pattern={name_pattern}  />
            <div
              className='float-right'
              style={{ width:350}}
            >
            {/* <span>Country</span> */}
            <MultiSelect
              options = {countries_options}
              onChange = {handleCountryChange}
              placeholder={"Filter By Country"}
            /> 
            {/* <span>Province</span> */}
            <AsyncSelect
              ref={provinces_ref}
              isMulti
              onChange = {handleProvinceChange}
              placeholder={"Start typing for province..."}
              loadOptions = {()=>load_provinces(countries, province_pattern)}
              onInputChange = {(inp) => set_province_pattern(inp)}
            />
            {/* <span>City</span> */}
            <AsyncSelect
              ref={cities_ref}
              isMulti
              onChange = {handleCityChange}
              placeholder={"Start typing for city..."}
              loadOptions = {()=>load_cities(provinces, city_pattern)}
              onInputChange = {(inp) => set_city_pattern(inp)}
            />

            <MultiSelect
              options = {positions}
              onChange = {handleChange}
              placeholder={"Filter By Interested Position"}
            />
            </div>

            </div>
            <br/><br/><br/>
            <ApprovalTableJS
                filter={filter}
                page={page}
                set_page={set_page}
                name_pattern={name_pattern.value}
                sort_by = {sort_by}
                interested_positions={interested_positions}
                countries={countries}
                provinces={provinces}
                cities={cities}
                set_sort_by = {set_sort_by}
            />
        </div>
    )
}

export default ApprovalPageJS;
