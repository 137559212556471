import React, { useRef, useState } from 'react'


const styles = {
    drop_filter: {
        float:'right',
        dsiplay:'inline',
        marginLeft:20,
    },
    rented:{
        background:'none',
        borderRadius: 18,
        padding:5,
        border: 'solid 1px #839eaf',
        color:'#839eaf' ,
        paddingLeft:12,
        paddingRight:12,
        marginRight:10
    },
    label:{
        marginBottom:7,
        color:'#445566',
        fontWeight:500
    },
 
    dropdown_button:{
        width:200,
        backgroundColor:'#fff',
        border: 'solid 1px #839eaf',
        borderRadius:18,
        color:"#445566"
    }
}

const UnmatchedJobsFilter = (props) => {

  const filter_button_ref = useRef(null)
  
  const handle_reset = e => {
      e.preventDefault()
      props.set_position('ALL')
      props.set_hire_rented('ALL')
      props.set_start_date('ALL')
      props.set_end_date('ALL')
  }

  const handle_filter = (e) => {
      props.handle_advanced_filter(e)
      filter_button_ref.current.click()
  }


  return (
    <div className="dropdown filter" style={styles.drop_filter}>
      <button style={styles.dropdown_button} ref={filter_button_ref}  className="btn dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            Filter
      </button>
        <div style={{boxShadow: '0 0 10px 0 rgba(68, 85, 102, 0.25)'}} className="dropdown-menu p-4" aria-labelledby="dropdownMenuClickableInside">

            <label style={styles.label}>Position</label><br/>
            <input 
                style={{ width:'100%'}}
                className='custom-modal-input-field' 

                onChange={(e)=>props.set_position(e.target.value)}
                value={props.position}
                placeholder='Search position' 
            /> <br/><br/>

            <div>
                <label style={styles.label}>Show results:</label>
                <div>
                    <button 
                        onClick={(e)=>{e.preventDefault(); props.set_hire_rented('Hire')}}
                        style={{...styles.rented, 
                            backgroundColor: props.hire_rented==='Hire' ? '#249afa' : '#fff',
                            color: props.hire_rented==='Hire' ? '#fff' : '#839eaf'}}>
                        Hired
                    </button> {' '}

                    <button 
                        onClick={(e)=>{e.preventDefault(); props.set_hire_rented('Rent')}}
                        style={{...styles.rented, 
                            backgroundColor: props.hire_rented==='Rent' ? '#249afa' : '#fff',
                            color: props.hire_rented==='Rent' ? '#fff' : '#839eaf'}}>
                        Rented
                    </button>{' '}
                    
                    <button 
                        onClick={(e)=>{e.preventDefault(); props.set_hire_rented('Either Rent or Hire')}}
                        style={{...styles.rented, 
                            backgroundColor: props.hire_rented==='Either Rent or Hire' ? '#249afa' : '#fff',
                            color: props.hire_rented==='Either Rent or Hire' ? '#fff' : '#839eaf'}}>
                        Both
                    </button>

                </div>
            </div><br/>

            <div style={{display:'flex'}}>
                <div style={{flex:1}}>
                  <label style={styles.label}>Start date</label>
                  <input 
                    className='custom-modal-input-field' 
                   
                    onChange={(e) => props.set_start_date(e.target.value)}
                    value={props.start_date}
                    type='date'/>
                </div>
                <div style={{flex:1}}>
                  <label style={styles.label}>End date</label>
                  <input 
                    className='custom-modal-input-field' 
                    onChange={(e) => props.set_end_date(e.target.value)}
                    value={props.end_date}
                    type='date'/>
                </div>
            </div><br/>


            <br/>

            <div style={{textAlign:'center'}} >

                <button 
                    onClick={handle_reset} 
                    className="custom-modal-reset-button"
                    style={{width:'45%'}}
                >Reset
                </button>

                <button 
                    onClick={handle_filter} 
                    className="custom-modal-ok-button"
                    style={{width:'45%'}}
                >OK
                </button>

            </div>
        </div>
    </div>
    )
}

export default UnmatchedJobsFilter;