import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useApproveJP from '../../../../../customHooks/Approvals/Decisions/useApproveJP';
import useContinueLater from '../../../../../customHooks/Approvals/Decisions/useContinueLater';
import useDeclineJP from '../../../../../customHooks/Approvals/Decisions/useDeclineJP';
import useConvertoToJS from '../../../../../customHooks/Approvals/useConvertToJS';
import {
  DeclineButton,
  ApproveButton,
  ApproveDecision,
  DeclineDecision,
  SaveLaterDecision,
} from '../../../../Other/ApprovalButtons';
import {ApprovedBanner, DeclinedBanner} from '../../../../Other/DecisionBanner';
import DeclinedProblems from '../../../../Other/DeclinedProblems';
import InfoLoading from '../../../../Other/Skeletons/InfoLoading';


const InfoListJP = ({company}) => {
  const history = useHistory();

  const [note_list, set_note_list] = useState({})
  const do_approve = useApproveJP(note_list);
  const do_decline = useDeclineJP(note_list);
  const do_continue_later = useContinueLater(note_list)
  const do_convert = useConvertoToJS(company.jobprovider.id);
  
  const handle_approve = e => {
    e.preventDefault();
    do_approve.mutate(company.id);
    history.push('/jp/approvallist');
  };

  const handle_decline = e => {
    e.preventDefault();
    console.log('Declining');
    do_decline.mutate(company.id);
    history.push('/jp/approvallist');
  };

  const handle_save_later = (e) => {
    e.preventDefault()
    do_continue_later.mutate(company.jobprovider.id)
    history.push('/jp/approvallist')
  }

  const calculate_approvals = () => {
    // This is a helper method to check the numer
    // of pending, approved or declined sections of company profile
    const total = Object.keys(note_list).length

    let approved = 0
    let declined = 0
    let pending = 0

    for (let [key, value] of Object.entries(note_list)) {
      if (value.status === 1) approved++
      else if (value.status === 0) declined++
      else if (value.status === -1) pending++
    }

    return {
      total,
      approved,
      declined,
      pending
    }
  }

  const valid_for_approval = () => {
    const numbers = calculate_approvals()
    const total = numbers.total
    const approved = numbers.approved
    if (total === approved){
      return true
    }
  }

  const valid_for_decline = () => {
    const numbers = calculate_approvals()
    const pending = numbers.pending
    if (pending === 0){
      return true
    }
  }


  const convert_company_to_job_seeker = (e) => {
    e.preventDefault()
    const ok = window.confirm("Are you sure you want to convert the company to job seeker!");
    if (ok){
      do_convert.mutate(company.id)
    }
  }
  

  useEffect(() => {
    if (company){
      const name = {title:'Company Name', note:'', status:-1}
      const location = {title:'Location', note:'', status:-1}
      const business_number = {title:'Business Number', note:'', status:-1}
      const payment_method = {title:'Payment Method', note:'', status:-1}
      const email = {title:'Email', note:'', status:-1}
      const phone = {title:'Phone Number', note:'', status:-1}
      const other = {title:'Other notes', note:'', status:-1}
      const temp_note_list = {
        name,
        location,
        email,
        other,
        phone,
        business_number,
        payment_method,
        // ...company.jobprovider.note
      }

      let to_add_from_database = company.jobprovider.note

      for (let [key, value] of Object.entries(to_add_from_database)) {
        // check if temo_note_list has key
        // console.log();
        if (!temp_note_list[key]) {
          // if so, set the status to 1
          console.log(`${key} of old notes was removed`);
          delete to_add_from_database[key]
        }
      }
      set_note_list({
        ...temp_note_list,
        ...to_add_from_database
      })
    }
  }, [company])

  if (!company) return <InfoLoading />;

  if (do_approve.isLoading || do_decline.isLoading)
    return 'Candidate approval loading...';

  return (
    <div className="info-list-div">
      <br />
      <br />

      {company.is_approved === 'Approved' && <ApprovedBanner />}
      {company.is_approved === 'Declined' && <DeclinedBanner />}
      <button className="float-right btn btn-outline-primary" onClick={convert_company_to_job_seeker}>
      <i className="fa fa-repeat"></i>{' '} Convert to job seeker
      </button>
      <br/>
      <br/>

      <div className="info-list-box-div">
        <img className='profile-pic float-right' alt='Rapihire Profile' src={company.jobprovider.profile_image}  />
        <p className="info-list-box-div_header">Company legal name</p>
        <span className="info-table-info">{company.name}</span>
        <br />

        <textarea
          placeholder='Note'
          style={styles.more_info}
          value={note_list['name']?.note}
          onChange={(e) => {
            const note = e.target.value
            const key = 'name'
            const title = 'Company Name'
            set_note_list({
              ...note_list,
              [key]:{
                ...note_list[key],
                note,
                title
              }
            })
          }}
        />

        <div style={{textAlign: 'right'}}>
          <DeclineButton
            ID = {`name`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
          <ApproveButton
            ID = {`name`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
        </div>
        {/* } */}
      </div>

      <div className="info-list-box-div">
        <p className="info-list-box-div_header">Location</p>
        <span className="info-table-info">
          {company.address}, {company.city.name}, {company.province.name},{' '}
          {company.zip_code}, {company.country.name}
        </span>
        <br />

        <textarea
          placeholder='Note'
          style={styles.more_info}
          value={note_list['location']?.note}
          onChange={(e) => {
            const note = e.target.value
            const key = 'location'
            const title = 'Location'
            set_note_list({
              ...note_list,
              [key]:{
                ...note_list[key],
                note,
                title
              }
            })
          }}
        />

        <div style={{textAlign: 'right'}}>
          <DeclineButton
            ID = {`location`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
          <ApproveButton
            ID = {`location`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
        </div>
        {/* } */}
      </div>

      <div className="info-list-box-div">
        <p className="info-list-box-div_header">Registered business number</p>
        <span className="info-table-info">{company.business_number || 'Not provided'}</span>
        <br />

        <textarea
          placeholder='Note'
          style={styles.more_info}
          value={note_list['business_number']?.note}
          onChange={(e) => {
            const note = e.target.value
            const key = 'business_number'
            const title = 'Business Number'
            set_note_list({
              ...note_list,
              [key]:{
                ...note_list[key],
                note,
                title
              }
            })
          }}
        />

        {/* {company.is_approved === 'Pending' && */}
        <div style={{textAlign: 'right'}}>
          <DeclineButton
            ID = {`business_number`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
          <ApproveButton
            ID = {`business_number`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
        </div>
        {/* } */}
      </div>

      <div className="info-list-box-div">
        <p className="info-list-box-div_header">Contact Person</p>
        <span className="info-table-info">
          {
          company.contact_name && company.contact_email
          ? <span> {company.contact_name}, {company.contact_email}</span> 
          : <span> {company.jobprovider.first_name}, {company.jobprovider.email}</span>
          }
        </span>
        <br />

        <textarea
          placeholder='Note'
          style={styles.more_info}
          value={note_list['email']?.note}
          onChange={(e) => {
            const note = e.target.value
            const key = 'email'
            const title = 'Email'
            set_note_list({
              ...note_list,
              [key]:{
                ...note_list[key],
                note,
                title
              }
            })
          }}
        />

        <div style={{textAlign: 'right'}}>
          <DeclineButton
            ID = {`email`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
          <ApproveButton
            ID = {`email`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
        </div>
      </div>

      <div className="info-list-box-div">
        <p className="info-list-box-div_header">Company phone</p>
        <span className="info-table-info">{company.contact_phone}</span>
        <br />

        <textarea
          placeholder='Note'
          style={styles.more_info}
          value={note_list['phone']?.note}
          onChange={(e) => {
            const note = e.target.value
            const key = 'phone'
            const title = 'Phone Number'
            set_note_list({
              ...note_list,
              [key]:{
                ...note_list[key],
                note,
                title
              }
            })
          }}
        />

        <div style={{textAlign: 'right'}}>
          <DeclineButton
            ID = {`phone`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
          <ApproveButton
            ID = {`phone`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
        </div>

      </div>

      <div className="info-list-box-div">
        <p className="info-list-box-div_header">Payment method</p>
        <span className="info-table-info">Credit Card</span>
        <br />
        <span className="info-table-info">12345 **** **** ****</span>
        <br />
        <span className="info-table-info">Google Inc.</span>
        <br />
        <span className="info-table-info">Exp. 01.2025</span>
        <br />

        <textarea
          placeholder='Note'
          style={styles.more_info}
          value={note_list['payment_method']?.note}
          onChange={(e) => {
            const note = e.target.value
            const key = 'payment_method'
            const title = 'Payment Method'
            set_note_list({
              ...note_list,
              [key]:{
                ...note_list[key],
                note,
                title
              }
            })
          }}
        />

        <div style={{textAlign: 'right'}}>
          <DeclineButton
            ID = {`payment_method`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
          <ApproveButton
            ID = {`payment_method`}
            set_note_list={set_note_list}
            note_list={note_list}
          />
        </div>

      </div>

      <div className='info-list-box-div'>
          <p className='info-list-box-div_header'>Other notes</p>
          <textarea
            placeholder='Note'
            style={styles.more_info}
            value={note_list[`other`]?.note}
            onChange={(e) => {
              const note = e.target.value
              const key = 'other'
              const title = 'Other notes'
              set_note_list({
                ...note_list,
                [key]:{
                  ...note_list[key],
                  note,
                  title
                }
              })
            }}
          />

          <div style={{textAlign:'right'}}>
            <DeclineButton ID = 'other' set_note_list={set_note_list} note_list={note_list}/>
            <ApproveButton ID = 'other' set_note_list={set_note_list} note_list={note_list}/>
          </div>
        </div>

      <DeclinedProblems
        notes={note_list}
      />
      {company.is_approved === 'Deactivated' ? null :

      <div style={{textAlign: 'center'}}>
        <SaveLaterDecision
          onClick={handle_save_later}
        />
        <DeclineDecision
          onClick={handle_decline}
          valid_for_decline={valid_for_decline}
        />
        <ApproveDecision
          onClick={handle_approve}
          valid_for_approval={valid_for_approval}
        />
      </div>
}
    </div>
  );
};

const styles = {
  more_info:{
    width:'100%',
    border:'solid 1px #a3bbca',
    borderRadius:'8px',
    padding:15,
    marginTop:20
  }
}

export default InfoListJP;
