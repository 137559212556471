import axios from "axios"
import base_url from "./util"

const get_token = () => {
  const token = window.localStorage.getItem('token')
  return `token ${token}`
}

export const get_timesheets = async (page, filters, sort_by) => {
  const match_id = filters.matching_id
  const js_name = filters.js_name
  const c_name = filters.company_name
  const h_from = filters.hours_worked_from
  const h_to = filters.hours_worked_to
  const r_from = filters.rate_from
  const r_to = filters.rate_to
  const p_start = filters.period_start

  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(
    `${base_url}matches/timesheet?page=${page}&match_id=${match_id}&js_name=${js_name}&c_name=${c_name}&h_from=${h_from}&h_to=${h_to}&r_from=${r_from}&r_to=${r_to}&p_start=${p_start}&sort_by=${sort_by}`,
     config)
  return data
}

export const get_matches = async (page) => {

  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}matches/all/?page=${page}`, config)
  return data
}

export const create_new_timesheet = async (payload) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.post(`${base_url}matches/timesheet/create/`, payload, config)
  return data
}

export const edit_timesheet = async (payload,id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  console.log(payload, id);
  const {data} = await axios.patch(`${base_url}matches/timesheet/detail/${id}`, payload, config)
  return data
}

export const get_stats_timesheet = async () => {
  const {data} = await axios.get(`${base_url}matches/stats/timesheet/`)
  return data
}

export const get_matching = async (filter) => {
  const id = filter.id
  if (!id) return null
  const config = {
    headers: { authorization: get_token()},
  }

  const {data} = await axios.get(`${base_url}matches/match/${id}`, config)
  return data
}
