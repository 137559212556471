/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import CornerMenu from '../../Auth/CornerMenu'
import TimesheetTable from './TimesheetTable'
import CreateTimesheet from './CreateTimesheet'
import TimesheetFilter from '../../Other/TimesheetFilter'
import EditTimesheet from './EditTimesheet'
import moment from 'moment';

const selectedWeek = (date) => {
    const newDate = new Date(date)
    const days = [newDate];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(newDate)
        .add(i, 'days')
        .toDate()
        );
      }
      return days;
    }

    
const TimesheetPage = () => {

    // Job filtering state
    const [page, set_page] = useState(1)
    const [period_start, set_period_start] = useState('')
    const [matching_id, set_matching_id] = useState('')
    const [js_name, set_js_name] = useState('')
    const [editing, set_editing] = useState({})
    const [company_name, set_company_name] = useState('')
    const [hours_worked_from, set_hours_worked_from] = useState(0)
    const [hours_worked_to, set_hours_worked_to] = useState(1000)
    const [rate_from, set_rate_from] = useState(0)
    const [rate_to, set_rate_to] = useState(1000)

    const [filters, set_filters] = useState({
        period_start,
        matching_id,
        js_name,
        company_name,
        hours_worked_from,
        hours_worked_to,
        rate_from,
        rate_to
    })

    const handle_advanced_filter = (e) => {
        e.preventDefault()

        set_page(1)
        set_filters({
            period_start,
            matching_id,
            js_name,
            company_name,
            hours_worked_from,
            hours_worked_to,
            rate_from,
            rate_to
        });
    }
   
    return (
        <div className='logged-in-container'>
            <CornerMenu/>
            
            <p className='form-header'>Timesheet</p>
            <br/>
            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{paddingLeft:20, paddingRight:20}} className='custom_button'>Enter new time sheet</button>
            
            <TimesheetFilter
                matching_id={matching_id}
                set_matching_id={set_matching_id}
                js_name={js_name}
                set_js_name={set_js_name}
                company_name={company_name}
                set_company_name={set_company_name}
                hours_worked_from={hours_worked_from}
                set_hours_worked_from={set_hours_worked_from}
                hours_worked_to={hours_worked_to}
                set_hours_worked_to={set_hours_worked_to}
                rate_from={rate_from}
                set_rate_from={set_rate_from}
                rate_to={rate_to}
                set_rate_to={set_rate_to}
                period_start={period_start}
                set_period_start={set_period_start}
                handle_advanced_filter={handle_advanced_filter}
            />
            <br/><br/><br/>

            <TimesheetTable
                page={page}
                set_page={set_page}
                filters={filters}
                set_editing={set_editing}
                
            />


        <EditTimesheet 
        timesheet={editing} 
        totalHours ={editing.total_hours_worked} 
        rate ={editing.rate} 
        selected_week ={selectedWeek(editing.period_start)}

        />
        <CreateTimesheet/>

        </div>
    )
}

export default TimesheetPage;