import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {useField} from '../../customHooks/other/useField';
import {user_state} from '../../global_state';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import logo_icon from '../../assets/icons/logo.svg';
import useRegisterRecruiter from '../../customHooks/AuthHooks/useRegisterRecruiter';
import useRegisterAdmin from '../../customHooks/AuthHooks/useRegisterAdmin';

const Registration = () => {
  const password = useField('password');
  const repeat_password = useField('password');
  const first_name = useField('text');
  const last_name = useField('text');
  const [show_password, set_show_password] = useState(true);
  const [error, set_error] = useState(false);
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('invited');
  const role = new URLSearchParams(search).get('role');

  const history = useHistory();
  const [user] = useRecoilState(user_state);

  const do_register_recruiter = useRegisterRecruiter();
  const do_register_admin = useRegisterAdmin();

  if (do_register_recruiter.isSuccess || do_register_admin.isSuccess) {
    history.push('/');
  }

  const tooglePassword = e => {
    e.preventDefault();
    set_show_password(!show_password);
  };

  const handle_register = e => {
    e.preventDefault();
    const payload = {
      email,
      first_name: first_name.value,
      last_name: last_name.value,
      password: password.value,
    };
    const is_form_valid = form_valid();
    set_error(!is_form_valid);

    if (form_valid()) {
      if (role === 'Recruiter') {
        do_register_recruiter.mutate(payload);
      } else if (role === 'Admin') {
        do_register_admin.mutate(payload);
      }
    }
  };

  useEffect(() => {
    if (user) history.push('/');
  }, [user, history]);

  const form_valid = () => {
    return (
      first_name.value.length > 1 &&
      last_name.value.length > 1 &&
      password.value.length > 5 &&
      repeat_password.value === password.value
    );
  };

  return (
    <div>
      <img
        style={{paddingLeft: 10, marginRight: 60}}
        alt="raihire logo"
        src={logo_icon}
      />
      <div className="login-form">
        {/* {isLoading ? 'logging in...' : null} */}
        <p className="form-header">You have been invited to Rapihire</p>
        <br />
        <form onSubmit={handle_register}>
          <div className="mb-3">
            <label style={{marginBottom: 10}} className="input-label">
              Email address
            </label>
            <p className="input-label">{email}</p>
            <br />
          </div>

          <div
            style={{display: 'flex', justifyContent: 'space-between'}}
            className="mb-3">
            <div>
              <label style={{marginBottom: 10}} className="input-label">
                First name
              </label>
              <input
                className="form-control"
                onChange={first_name.onChange}
                value={first_name.value}
              />
              {error && first_name.value.length < 2 && (
                <p className="error-label">Required</p>
              )}
            </div>

            <div>
              <label style={{marginBottom: 10}} className="input-label">
                Last name
              </label>
              <input
                className="form-control"
                onChange={last_name.onChange}
                value={last_name.value}
              />
              {error && last_name.value.length < 2 && (
                <p className="error-label">Required</p>
              )}
            </div>
          </div>

          <div className="mb-3">
            <br />
            <label style={{marginBottom: 10}} className="input-label">
              Password
            </label>
            <input
              className="form-control"
              onChange={password.onChange}
              value={password.value}
              type={show_password ? password.type : 'text'}
            />
            {error && password.value.length < 6 && (
              <p className="error-label">
                Password should be at least 6 characters
              </p>
            )}

            <br />
            <label style={{marginBottom: 10}} className="input-label">
              Repeat Password
            </label>
            <input
              className="form-control"
              onChange={repeat_password.onChange}
              value={repeat_password.value}
              type={show_password ? repeat_password.type : 'text'}
            />
            {error && repeat_password.value !== password.value && (
              <p className="error-label">Passwords should match</p>
            )}

            {show_password ? (
              <VisibilityOffIcon
                onClick={tooglePassword}
                className="show_password_icon"
              />
            ) : (
              <VisibilityIcon
                onClick={tooglePassword}
                className="show_password_icon"
              />
            )}
          </div>

          <div style={{textAlign: 'right'}}>
            <button
              style={{marginTop: 30, width: '50%'}}
              type="submit"
              className="custom_button">
              Register
            </button>
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};

export default Registration;
