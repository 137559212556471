import React, { useState } from 'react'
import CornerMenu from '../../../Auth/CornerMenu'
import FilterSearch from '../../../Other/FilterSearch'
import { useField } from '../../../../customHooks/other/useField'
import UnmatchedTable from './UnmatchedTable'
import UnmatchedJobsFilter from '../../../Other/UnmatchedJobsFilter'
import EditListingModal from './EditListingModal'
import FilterButton from '../../../Other/FilterButton'


const UnmatchedPageJP = () => {
    const [page, set_page] = useState(1)
    const company_name = useField('text')
    const [position, set_position] = useState('ALL')
    const [hire_rented, set_hire_rented] = useState('ALL')
    const [start_date, set_start_date] = useState('ALL')
    const [end_date, set_end_date] = useState('ALL')
    const [status, set_status] = useState('ACTIVE')

    const [editing, set_editing] = useState({});

    const [filters, set_filters] = useState({
        position,
        hire_rented,
        start_date,
        end_date,
    })
    
    const handle_advanced_filter = (e) => {
        e.preventDefault()
        set_page(1)
        set_filters({
            position,
            hire_rented,
            start_date,
            end_date,
        });
    }

    const handle_status = (e, val) => {
        e.preventDefault()
        set_status(val)
    }

    return (
        <div className='logged-in-container'>
            <CornerMenu/>
            <p className='form-header'>Unmatched Listings</p><br/>

            <div style={{display: 'inline'}}>
            <FilterButton
                onClick={e => handle_status(e, 'ACTIVE')}
                active={status === 'ACTIVE'}
                name="Active"
            />
            <FilterButton
                onClick={e => handle_status(e, 'CLOSED')}
                active={status === 'CLOSED'}
                name="Closed"
            />

            <FilterButton
                onClick={e => handle_status(e, 'ALL')}
                active={status === 'ALL'}
                name="All"
            />
        </div>

            <UnmatchedJobsFilter 
                position={position}
                set_position={set_position}
                hire_rented={hire_rented}
                set_hire_rented={set_hire_rented}
                start_date={start_date}
                set_start_date={set_start_date}
                end_date={end_date}
                set_end_date={set_end_date}
                handle_advanced_filter={handle_advanced_filter}
            />

            <FilterSearch 
                name_pattern={company_name}
                placeholder='Company name...' 
            />
    
            <br/><br/>
            <UnmatchedTable              
                filters={filters}
                page={page}
                company_name={company_name.value}
                set_page={set_page}
                set_editing={set_editing}
                status={status}
                set_status={set_status}
            />

            <EditListingModal curr_listing={editing}/>
        </div>
    )
}

export default UnmatchedPageJP;
