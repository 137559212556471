import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react'
import useJobsJS from '../../../../customHooks/Jobs/useJobsJS';
import TableLoading from '../../../Other/Skeletons/TableLoading.js'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import useUser from '../../../../customHooks/AuthHooks/useUser';

const styles = {
  sort:{
    backgroundColor:'#fff',
    border:'none',
    margin:0,
    padding:0
  }
}

const JobsTableJS = ({
  filters,
  page,
  set_page,
  js_name
}) => {

    const [sort_by, set_sort_by] = useState('-job_listing__start_date')
    
    const { data: jobs_data, isLoading, isFetching } = useJobsJS(page, filters, js_name, sort_by)
    const {data: user_data} = useUser();
    const staff_level = user_data?.staff_level
    if (isLoading || isFetching) return <TableLoading/>
    const handle_page = (e, value) => {
        e.preventDefault()
        set_page(value)
    }

    return (
    <div>
        <table className='recruiter-table'>
        <thead>
            <tr>

                <th className='align-left-col'>Name 
                  <button style={styles.sort} 
                    onClick={(e)=>{
                      e.preventDefault();
                      if (sort_by === 'interested_position__job_seeker__first_name' )set_sort_by('-interested_position__job_seeker__first_name')
                      else set_sort_by('interested_position__job_seeker__first_name')
                    }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>Position 
                   <button style={styles.sort} 
                    onClick={(e)=>{
                      e.preventDefault();
                      if (sort_by === 'interested_position__position__name' )set_sort_by('-interested_position__position__name')
                      else set_sort_by('interested_position__position__name')
                    }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className=''>Hire/Rented 
                <button style={styles.sort} 
                      onClick={(e)=>{
                        e.preventDefault();
                        if (sort_by === 'job_listing__job_type' )set_sort_by('-job_listing__job_type')
                        else set_sort_by('job_listing__job_type')
                      }}>
                  <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>Start
                  <button style={styles.sort} 
                      onClick={(e)=>{
                        e.preventDefault();
                        if (sort_by === 'start_date' )set_sort_by('-start_date')
                        else set_sort_by('start_date')
                      }}>
                  <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>
                
                <th className=''>End
                <button style={styles.sort} 
                      onClick={(e)=>{
                        e.preventDefault();
                        if (sort_by === 'end_date' )set_sort_by('-end_date')
                        else set_sort_by('end_date')
                      }}>
                  <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>YTD hours worked 
                  <button style={styles.sort} 
                        onClick={(e)=>{
                          e.preventDefault();
                          if (sort_by === 'interested_position__ytd_hours' )set_sort_by('-interested_position__ytd_hours')
                          else set_sort_by('interested_position__ytd_hours')
                        }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>

                <th className='align-left-col'>YTD amount
                  <button style={styles.sort} 
                        onClick={(e)=>{
                          e.preventDefault();
                          if (sort_by === 'ytd_amount' )set_sort_by('-ytd_amount')
                          else set_sort_by('ytd_amount')
                        }}>
                    <UnfoldMoreIcon fontSize="inherit"/></button>
                </th>
            </tr>
        </thead>

        <tbody>
            {jobs_data.results.jobs_data.map(job => (
            <tr key={job.id}>
                <td className='align-left-col'>{job.job_seeker.first_name} {' '} {job.job_seeker.last_name}</td>
                <td className=''>{job.position.name} 
                 {/* <Link style={{ color: "black" }} to={`/listing/${job.id}`}>
                  <CallMadeIcon fontSize="small" />
                </Link> */}
                </td>
                <td className=''>{job.hired_rented}</td>
                <td className=''>{job.start_date ? new Intl.DateTimeFormat('en-US', { dateStyle: 'medium'}).format(Date.parse(job.start_date)) : '-' }</td>
                <td className=''>{job.end_date ? new Intl.DateTimeFormat('en-US', { dateStyle: 'medium'}).format(Date.parse(job.end_date)) : '-'}</td>
                <td className=''>{job.ytd_hours }</td>
                <td className=''>{(job.ytd_amount).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })} </td>
            </tr>
            ))}

           {
            !jobs_data?.results.stats ? 'Loading Statistics...' :
            <tr >
              <td style={{fontWeight:700}} className='align-left-col'>{jobs_data.results.stats.number_of_job_seekers} job seekers</td>
              <td style={{fontWeight:700}}>{jobs_data.results.stats?.number_of_positions} positions</td>
              <td className=''></td>
              <td className=''> </td>
              <td className=''>  </td>
              <td style={{fontWeight:700}}>
                {jobs_data?.results.stats.total_ytd_hours}h 

                { staff_level > 2 && 
                <a 
                    target="_blank"
                    href="https://api.rapihire.com/api/download/js/"
                    style={{textDecoration:'none', fontWeight:700,color:'#0099ee', float:'right'}}
                  >
                    Export csv
                  </a>
                }
              </td>
            </tr>
            }

        </tbody>
        
        </table>        
        <Box 
          className='pagination-div'
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Pagination 
            count={Math.ceil(jobs_data.count/10)} 
            page={page} 
            onChange={handle_page}           
          />
        </Box>
    </div>
    )
}

export default JobsTableJS;