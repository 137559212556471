import React from 'react'
import { Redirect, Route, Switch } from "react-router"
import { useRecoilState, useRecoilValue } from "recoil"
import InviteStaffPage from "./components/Admin/InviteStaffPage"
import Login from "./components/Auth/Login"
import Registration from "./components/Auth/Registration"
import LeftSide from "./components/Navbar/LeftSide"
import { SuccessMessage, ErrorMessage } from "./components/Other/SystemMessage"
import InfoPageJP from "./components/Recuiter/JobProvider/Approval/ApprovalDetail/InfoPageJP"
import ApprovalPageJP from "./components/Recuiter/JobProvider/Approval/ApprovalPageJP"
import JobsPageJP from "./components/Recuiter/JobProvider/Jobs/JobsPageJP"
import UnmatchedPageJP from './components/Recuiter/JobProvider/UnmatchedJobs/UnmatchedPage'
import InfoPageJS from "./components/Recuiter/JobSeeker/Approval/ApprovalDetail/InfoPageJS"
import ApprovalPageJS from "./components/Recuiter/JobSeeker/Approval/ApprovalPageJS"
import JobsPageJS from "./components/Recuiter/JobSeeker/Jobs/JobsPageJS"
import MatchesPage from './components/Recuiter/Timesheet/Matches/Matches'
import TimesheetPage from "./components/Recuiter/Timesheet/TimesheetPage"
import { error_message, success_message, user_state } from "./global_state"
import ListingDetailPage from './components/Recuiter/JobProvider/Jobs/ListingDetailPage'

import './styles/modal.scss'
import './styles/index.scss'
import './styles/tables.scss'
import './styles/left-side-navbar.scss'
import './styles/system-messages.scss'
import './styles/auth.scss'
import './styles/listing-detail.scss'
import ApprovalHistory from './components/Recuiter/ApprovalHistory/ApprovalHistory'
import OtherPage from './components/Recuiter/Other/OtherPage'
import CommunicationJS from './components/Recuiter/Other/Communication'


const NeedToAuthenticateRoute = ({ path, component}) => {
  const user = useRecoilValue(user_state)
  if(!user)
    return <Redirect to='/'/>
  return <Route exact path={path} component={component}/>
}

const LoggedOutRoute = ({path, component}) => {
  const [user] = useRecoilState(user_state)
  if(!user)
    return <Route exact path={path} component={component}/>
  return <Redirect to='/js/approvallist'/>
}


const App = () => {
  const error = useRecoilValue(error_message)
  const success = useRecoilValue(success_message)

  return (
    <div style={{display:'flex'}} >
      <LeftSide />

      <div className='content'>

        { success && <SuccessMessage text={success} /> }
        { error && <ErrorMessage text={error} /> }
      
        <Switch>
          <LoggedOutRoute exact path={'/'} component={Login} />
          <LoggedOutRoute exact path={'/register'} component={Registration} />
          <NeedToAuthenticateRoute exact path={'/js/communication'} component={CommunicationJS} />
          <NeedToAuthenticateRoute exact path={'/js/approvallist'} component={ApprovalPageJS} />
          <NeedToAuthenticateRoute exact path={'/js/approvallist/:js_id'} component={InfoPageJS} />
          <NeedToAuthenticateRoute exact path={'/js/jobslist'} component={JobsPageJS} />
          <NeedToAuthenticateRoute exact path={'/jp/approvallist'} component={ApprovalPageJP} />
          <NeedToAuthenticateRoute exact path={'/jp/approvallist/:company_id'} component={InfoPageJP} />
          <NeedToAuthenticateRoute exact path={'/jp/jobslist'} component={JobsPageJP} />
          <NeedToAuthenticateRoute exact path={'/jp/unmatched'} component={UnmatchedPageJP} />
          <NeedToAuthenticateRoute exact path={'/a/recruiters'} component={InviteStaffPage} />
          <NeedToAuthenticateRoute exact path={'/timesheet'} component={TimesheetPage} />
          <NeedToAuthenticateRoute exact path={'/matches'} component={MatchesPage} />
          <NeedToAuthenticateRoute exact path={'/listing/:listing_id'} component={ListingDetailPage} />
          <NeedToAuthenticateRoute exact path={'/approvalhistory/:user_id'} component={ApprovalHistory} />
          <NeedToAuthenticateRoute exact path={'/other'} component={OtherPage} />
        </Switch>

      </div>
    </div>
  );
}

export default App;
