
import { useMutation, useQueryClient } from "react-query"
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from "../../../global_state";
import {reject_job_seeker } from "../../../services/approvals";


const useRejectJS = (notes) => {
    const set_success = useSetRecoilState(success_message)
    const set_error = useSetRecoilState(error_message)
    const queryClient = useQueryClient()
    return useMutation( (id) =>  reject_job_seeker(id, notes) , {
        onSuccess: data => {
           queryClient.invalidateQueries('get_job_seekers')
            set_success('Profile rejected')
            setTimeout(() => {
                set_success('')
              }, 5000);
        },
        onError: () => {

            set_error('Problem rejecting Candidate!')
            setTimeout(() => {
              set_error('')
            }, 4000);
          }
    })
}

export default useRejectJS;
