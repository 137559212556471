import { Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import useJobsJP from "../../../../customHooks/Jobs/useJobsJP";
import TableLoading from "../../../Other/Skeletons/TableLoading";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { Link } from "react-router-dom";
import CallMadeIcon from "@material-ui/icons/CallMade";
import useUser from "../../../../customHooks/AuthHooks/useUser";

const styles = {
  sort: {
    backgroundColor: "#fff",
    border: "none",
    margin: 0,
    padding: 0,
  },
};

const JobsTableJP = ({ filters, page, set_page, company_name }) => {
  const [sort_by, set_sort_by] = useState("-start_date");
  // const { data: stats_data, isLoading: loadingStats } = useStatsJP()
  const {
    data: jobs_data,
    isLoading,
    fetchingJobs,
  } = useJobsJP(page, filters, company_name, sort_by);
  const {data: user_data} = useUser();
  const staff_level = user_data?.staff_level
  if (isLoading || fetchingJobs) return <TableLoading />;

  const handle_page = (e, value) => {
    e.preventDefault();
    set_page(value);
  };

  return (
    <div>
      <table className="recruiter-table">
        <thead>
          <tr>
            <th className="align-left-col">
              Name
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "company__name")
                    set_sort_by("-company__name");
                  else set_sort_by("company__name");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th className="align-left-col">Hires </th>

            <th className="align-left-col">
              Position
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "position__name")
                    set_sort_by("-position__name");
                  else set_sort_by("position__name");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th className="align-left-col">
              Hire/Rented
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "job_type") set_sort_by("-job_type");
                  else set_sort_by("job_type");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th className="">
              Start
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "start_date") set_sort_by("-start_date");
                  else set_sort_by("start_date");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>
            {/* <th className=''>End</th> */}
            <th className="">
              End
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "end_date") set_sort_by("-end_date");
                  else set_sort_by("end_date");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th className="align-left-col">
              YTD hours worked
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "ytd_hours") set_sort_by("-ytd_hours");
                  else set_sort_by("ytd_hours");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>
            <th className="align-left-col">
              YTD amount
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "ytd_amount") set_sort_by("-ytd_amount");
                  else set_sort_by("ytd_amount");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>
          </tr>
        </thead>

        <tbody>
          {jobs_data.results.jobs_data.map((job) => (
            <tr key={job?.id}>
              <td className="align-left-col">
                <a className="text-decoration-none text-dark" href={`approvallist/${job?.company.id}`} target="_blank">
                  {job?.company.name}
                </a>
              </td>
              <td className="">
                {job?.nr_matches}/{job?.nr_employees_wanted}
              </td>
              <td className="">
                {job?.position.name}{" "}
                <Link style={{ color: "black" }} to={`/listing/${job.id}`}>
                  <CallMadeIcon fontSize="small" />
                </Link>{" "}
              </td>
              <td className="">{job.job_type}</td>
              <td className="">
                {" "}
                {job?.start_date
                  ? new Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    }).format(Date.parse(job.start_date))
                  : "-"}
              </td>
              <td className="">
                {" "}
                {job?.end_date !== ""
                  ? new Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    }).format(Date.parse(job.end_date))
                  : "-"}
              </td>
              <td className="">{job.ytd_hours}</td>
              <td className="">
                {(job?.ytd_amount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
              </td>
            </tr>
          ))}

          {!jobs_data?.results.stats ? (
            "Loading Statistics..."
          ) : (
            <tr>
              <td style={{ fontWeight: 700 }} className="align-left-col">
                {jobs_data?.results.stats.number_of_companies} companies
              </td>
              <td style={{ fontWeight: 700 }}>
                {jobs_data?.results.stats.number_of_positions} positions
              </td>
              <td className=""></td>
              <td className=""> </td>
              <td className=""> </td>
              <td className=""> </td>
              <td style={{ fontWeight: 700 }}>
                {jobs_data?.results.stats.total_ytd_hours}h
                { staff_level > 2 && 
                <a
                  target="_blank"
                  href="https://api.rapihire.com/api/download/jp/"
                  style={{
                    textDecoration: "none",
                    fontWeight: 700,
                    color: "#0099ee",
                    float: "right",
                  }}
                >
                  Export csv
                </a>}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Box
        className="pagination-div"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Pagination
          count={Math.ceil(jobs_data.count / 10)}
          page={page}
          onChange={handle_page}
        />
      </Box>
    </div>
  );
};

export default JobsTableJP;
