import React from 'react';
import {useParams} from 'react-router';
import CornerMenu from '../../../../Auth/CornerMenu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Link} from 'react-router-dom';
import useCompanyDetail from '../../../../../customHooks/Approvals/useCompanyDetail';
import InfoListJP from './InfoListJP';
import InfoLoading from '../../../../Other/Skeletons/InfoLoading';

const InfoPageJP = () => {
  const company_id = useParams().company_id;
  const {
    data: company_data,
    isLoading,
    isFetching,
  } = useCompanyDetail(company_id);

  if (isLoading || isFetching) return <InfoLoading />;
  return (
    <div className="logged-in-container">
      <CornerMenu />
      <Link
        style={{textDecoration: 'none'}}
        to="/jp/approvallist"
        className="form-header">
        <ArrowBackIcon
          style={{marginBottom: 4, opacity: 0.6}}
          fontSize="large"
        />{' '}
        Company profile Approval
      </Link>

      <InfoListJP company={company_data} />
    </div>
  );
};

export default InfoPageJP;
