import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';


export const DeclineButton = ({ ID, note_list, set_note_list }) => {

    const handle_click = (e) => {
      e.preventDefault()
      set_note_list({
        ...note_list,
        [ID]:{
          ...note_list[ID],
          status: 0
        }
      })
    }
    return (
      <button 
        style={{
          backgroundColor: note_list[ID]?.status === 0 ? 'rgb(221, 96,105)' : 'white'
        }} 
        onClick={handle_click} 
        className='approval-button'>   
       <CloseIcon 
          style={{color: note_list[ID]?.status === 0 ? 'white' : 'rgb(190, 188, 188)'}}
       />
      </button>
    )
}


export const ApproveButton = ({ID,  note_list, set_note_list}) => {


    const handle_click = (e) => {
      e.preventDefault()
      set_note_list({
        ...note_list,
        [ID]:{
          ...note_list[ID],
          status: 1
        }
      })
    }
  
    return (
      <button 
        style={{
          backgroundColor: note_list[ID]?.status===1 ? 'rgb(92,200,171)' : 'white'
        }} 
        onClick={handle_click} 
        className='approval-button'> 
        <DoneIcon 
          style={{color:note_list[ID]?.status===1 ? 'white' : 'rgb(190, 188, 188)'}}
        />
      </button>
    )
}


export const ApproveDecision = ({valid_for_approval, ...props}) => {
    return (
        <button 
            style={{
              backgroundColor: valid_for_approval() ? 'rgb(92,200,171)' : 'rgb(148,222,208)'
            }}
            onClick={props.onClick}
            className='desision-button'
            disabled={!valid_for_approval()}
            >
            Approve
          </button>
    )
}


export const DeclineDecision = ({valid_for_decline, ...props}) => {
    return (
      <button 
        style={{
              backgroundColor: valid_for_decline() ?'rgb(221, 96,105)' : ' rgb(231,168,175)',
              marginRight:'10%'
        }} 
        onClick={props.onClick}
        className='desision-button'
        disabled={!valid_for_decline()}
        >
        Decline
      </button>
    )
}

export const SaveLaterDecision = ({ ...props}) => {
  return (
    <button 
      style={{
            backgroundColor: '#249afa',
            marginRight:'10%'
      }} 
      onClick={props.onClick}
      className='desision-button'
      >
      Save for later
    </button>
  )
}

export const RejectDecision = ({valid_for_rejection, ...props}) => {
  return (
    <button 
      style={{
            backgroundColor: valid_for_rejection ? 'black' : 'gray',
            marginRight:'10%'
      }} 
      onClick={props.onClick}
      className='desision-button'
      disabled={!valid_for_rejection}
      >
      Reject
    </button>
  )
}
