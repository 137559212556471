import Select from 'react-select'

const MultiSelect = props => {
return(
     <Select
       options={props.options}
       isMulti
       name="positionList"
       backgroundColor= 'red'
       borderRadius= '36px'
       placeholder={props.placeholder}
       className="basic-multi-select"
       classNamePrefix="select"
       onChange={props.onChange}
       {...props}
     />
)

}

export default MultiSelect;
