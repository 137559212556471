import { useMutation } from "react-query"
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from "../../global_state";
import { send_emails_job_seekers } from "../../services/communication";


const useSendEmailJS = () => {
    const set_error = useSetRecoilState(error_message)
    const set_success = useSetRecoilState(success_message)

    return  useMutation((filter_payload)=>send_emails_job_seekers(filter_payload), {
        onSuccess: data => {
          set_success('Successfully sent emails!')
          setTimeout(() => {
            set_success('')
          }, 4000);
        },
        onError: () => {
          set_error(`Problem sending emails!`)
          setTimeout(() => {
            set_error('')
          }, 4000);
        }
    })
}

export default useSendEmailJS;
