
import { useMutation, useQueryClient } from "react-query"
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from "../../../global_state";
import { decline_company } from "../../../services/approvals";


const useDeclineJP = (notes) => {
    const set_success = useSetRecoilState(success_message)
    const set_error = useSetRecoilState(error_message)
    const queryClient = useQueryClient()
    return useMutation( (id)=>decline_company(id, notes), {
        onSuccess: data => {
            queryClient.invalidateQueries('get_companies')
            set_success('Company declined')
            setTimeout(() => {
                set_success('')
              }, 5000);
        },
        onError: () => {

            set_error('Problem declining company!')
            setTimeout(() => {
              set_error('')
            }, 4000);
          }
    })
}

export default useDeclineJP;