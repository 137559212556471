import { ArrowBackOutlined } from '@material-ui/icons';
import Visibility from '@material-ui/icons/Visibility';
import moment from 'moment';
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useApprovalHistory from '../../../customHooks/Approvals/useApprovalHistory';
import CornerMenu from '../../Auth/CornerMenu';
import TableLoading from '../../Other/Skeletons/TableLoading';
import NotesModal from './NotesModal';

const ApprovalHistory = () => {

    const user_id = useParams().user_id
    const history = useHistory()
    const [curr_history, set_curr_history] = useState([])
    const search = useLocation().search;
    const user = new URLSearchParams(search).get('user');
    const {data: histories, isLoading, isFetching } = useApprovalHistory(user_id)

  

    const handle_view = (history_viewing) => {
      set_curr_history(history_viewing)
    }

    return (
        <div className='logged-in-container'>
        <button
          onClick={(e) => {
            e.preventDefault();
            history.go(-1);
          }}
          className="form-header invisible-button"
        >
          <ArrowBackOutlined
            style={{ marginBottom: 4, opacity: 0.6 }}
            fontSize="large"
          />{" "}
          Go back
        </button>
        <CornerMenu/>

        <p style={{display:'block', margin:20}} className='form-header'>
          Approval history for {user}
        </p> 
        { (isLoading || isFetching) ? <TableLoading/> :       
        <table className='recruiter-table'>
            <thead>
              <tr>
                <td>
                  <b>Date</b>
                </td>
                <td>
                  <b>Action</b>
                </td>
                <td>
                  <b>Recruiter</b>
                </td>
                <td>
                  <b>Notes</b>
                </td>
              </tr>
            </thead>

            <tbody>
              {!histories.length && (
                <tr>
                  <td>No approval history for this user</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
              {histories.map(history => (
                  <tr key={history.id}>
                      <td>{moment(history.date).format('lll')}</td>
                      <td>{history.decision}</td>
                      <td>{history.staff ? history.staff : '-'}</td>
                      <td>
                        {history.staff ? 
                        <Visibility  
                          data-bs-toggle="modal"
                          data-bs-target="#showApprovalNotesModal"
                          onClick={()=>handle_view(history)} 
                          style={{cursor:'pointer'}}  
                        /> : '-'}
                      </td>
                  </tr>
              ))}
            </tbody>
          </table>
        }

      
        <NotesModal history={curr_history} />

        </div>
    )
}

export default ApprovalHistory;
