import { useQuery } from "react-query"
import { run_test_algorithm } from "../../services/jobs";


const useTestAlgorithm = (listing_id) => {

    return useQuery(
        ['run_test_algorithm', listing_id], 
        ()=>run_test_algorithm(listing_id),
        {enabled: false}
    )
}

export default useTestAlgorithm;