import React, { useEffect , useState } from 'react';
import useEditTimesheet from '../../../customHooks/Timesheet/useEditTimesheets';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import WeekRange from './WeekRange';
import useMatchDetail from '../../../customHooks/Timesheet/useMatchDetail';


const EditTimesheet = ({timesheet,totalHours,rate,selected_week}) => {
  
  const do_edit_timesheet = useEditTimesheet();
  
  const [selectedDays, set_selectedDays] = useState([]);
  const [show_calendar, set_show_calendar] = useState(false);
  const handle_show_calendar = () => set_show_calendar(!show_calendar);
  const {data: match_data} = useMatchDetail({id: timesheet.match})
  const [total_hours_worked, set_total_hours_worked] = useState(0);
  const [new_rate, set_new_rate] = useState(0);
  const [error, set_error] = useState(false)

    
    
  const do_reset = () => {
    set_total_hours_worked(0);
    set_new_rate(0);
    set_selectedDays([]);
    set_show_calendar(false);
    set_error(false)
  }
  
  const reset_form = (e) => {
    e.preventDefault();
    document.getElementById("editTimesheetForm").reset();
    do_reset()
  };  
  
  const selectedWeek = (days,a,s) => {
    
    if(days.length !== 0){
      handle_change()
    }
    
  }
  const handle_change = (e) => {
   
    // e.preventDefault();
  
    if(!new_rate){
      set_new_rate(rate)
    }  
    if(!total_hours_worked){
      set_total_hours_worked(totalHours)
    }
    if(selectedDays.length === 0 ){
      set_selectedDays(selected_week)
    }
    check_form_valid()
  };



const normalize_date = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};
  // Timesheet object will have the following info:
  //   > match
  //   > start_date, end_date
  //   > total_hours_worked
  //   > rate
  //   > total_money

  // + Modal should look the same as in CreateTimesheet
  // + See also components/Admin/EditStaff on how to pass info from table to the modal

    const handle_edit = e => {
      e.preventDefault();
      if (!check_form_valid()) {
        set_error(true)
    }else{
      
      const payload = {
        total_hours_worked: total_hours_worked ? total_hours_worked : timesheet.total_hours_worked,
        period_start: normalize_date(selectedDays[0]),
        period_end: normalize_date(selectedDays[6]),
        rate: new_rate ? new_rate : timesheet.rate,
        total_money: (new_rate ? new_rate : timesheet.rate) * (total_hours_worked ? total_hours_worked : timesheet.total_hours_worked)
      }
      // This should print the above payload.
      console.log(timesheet.id);
      do_edit_timesheet.mutate({payload, id: timesheet.id})
    }
    };

//   DON'T FORGET TO VALIDATE BEFORE SUBMISSION

// one week before today
const start_date = new Date();
start_date.setDate(start_date.getDate() - 7);

const period_start_date = new Date ()
period_start_date.setDate(period_start_date.getDate() -1)


const match_time = match_data ? match_data.match_time : period_start_date

const check_form_valid = () => {

  if (
    selectedDays.length === 7 
    && total_hours_worked > 0 
    && new_rate > 0 
    && new_rate < 10000 
    && total_hours_worked < 169
    
    )
    if ( 
      selectedDays[0].getTime() < start_date.getTime()
      && selectedDays[6].getTime() > new Date(match_time).getTime()
      ) {
        // set_error(false)
        return true;
        
      }
    return false;
    }
 


  return (
    <div
      className="modal fade"
      id="editTimesheetmodal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div
          style={{borderRadius: 18, marginTop: '20%', padding: 10}}
          className="modal-content">
          <form id="editTimesheetForm" onChange={handle_change} onSubmit={handle_edit}>
          <div className="modal-body">
          <div style={{display:'flex'}}>
                <div style={styles.child}>
                    <p>Job Seeker</p>
                    <span style={styles.info}>{timesheet.job_seeker}</span> 
                </div>

                <div style={styles.child}>
                    <p>Vendor</p>
                    <span style={styles.info}>{timesheet.company}</span> 
                </div>

                <div style={styles.child}>
                    <p>Current Rate</p>
                    <span style={{fontSize:24, fontWeight:"bold", color:'#445566'}} >${timesheet.rate}/h</span>
                </div>
                </div>
                <hr/>
                   {/* inputs */}
                   <div style={{display:'flex'}}>
              <div>
                    <p className='input-label'>Matching ID</p>
                    <input 
                      className='timesheet custom-modal-input-field'
                      value={timesheet.match} 
                      disabled={true}
                      readOnly
                    />
                </div>  

                    <div>
                        <p className='input-label' >Week period </p>
                        <p onClick={handle_show_calendar}>
                          {(selectedDays[0] ) ?  new Intl.DateTimeFormat('en-US').format(Date.parse(selectedDays[0])) : <span style={{fontSize:14}}>{timesheet.period_start}</span>} 
                          {' - '}
                          {selectedDays[6]  ?  new Intl.DateTimeFormat('en-US').format(Date.parse(selectedDays[6])) : <span style={{fontSize:14}}>{timesheet.period_end}</span>} 

                          {!show_calendar ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/> }
                        </p>
                        {show_calendar && <WeekRange handleWeekClick = {selectedWeek(selectedDays)} set_selectedDays={set_selectedDays} selectedDays={selectedDays}/> }
                    </div>

                    </div><br/>

                    <div style={{display:'flex'}}>
                    <div >
                        <p className='input-label'>Hours worked</p>
                        <input className='timesheet custom-modal-input-field' type='number' defaultValue={timesheet.total_hours_worked} onChange={e=>set_total_hours_worked(e.target.value)} />
                    </div>

                    <div style={{textAlign:'right' }}>
                        <p className='input-label'>New Rate</p>
                        <input className='timesheet custom-modal-input-field' type='number' defaultValue={timesheet.rate} onChange={e=>set_new_rate(e.target.value)}  />
                    </div>
                    </div><hr/>

                <div >
                    <div style={{textAlign:'right'}}>
                        <p className='input-label'>Total </p>
                        <span>
                            {((new_rate ? new_rate : timesheet.rate) * (total_hours_worked ? total_hours_worked : timesheet.total_hours_worked)).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })} 
                        </span>
                    </div>
                </div><br/>
                {/* Write the code here to load the fields to edit in timesheet */}

                {/*   
                    Fields to edit:
                        - total_hours_worked
                        - rate (in $)
                        - total_money will be autocalculated (total_hours_worked * rate)
                        - start and end date (from the calendar week selection)
                    See /CreateTimesheet.js for reference
                */}
            </div>
            {error && <p className='error-label'>Please choose week period and hours worked and make sure you enter a valid period</p>}

            <div className="modal-footer">
              <button
                style={styles.reset}
                type="button"
                className="btn btn-secondary"
                onClick={reset_form} 
                data-bs-dismiss="modal"
                >
                Close
              </button>

              {/* When user clicks save - then handle_edit function will be executed */}
              <button type="submit" 
              style={{  
                // backgroundColor: '#249afa'
                backgroundColor: !check_form_valid() ? 'rgba(36, 154, 250, 0.5' : '#249afa'
                }} 
              className="custom_button"
              // disabled= {!check_form_valid()}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  ok: {
    backgroundColor: '#249afa',
    border: 'none',
    width: '50%',
    color: 'white',
    borderRadius: 4,
    fontWeight: 500,
    height: 40,
  },
  info: {
    color: '#445566',
    fontWeight: 'bold',
  },  
  disabled: {
    color: '#9ca5ae',
    // fontWeight: 'bold',
  },
  input_field: {
    border: 'solid 1px #aabbcc',
    borderRadius: 18,
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 10,
    width: '100%',
    marginBottom: 20,
  },
  reset: {
    background: 'none',
    border: 'none',
    width: '25%',
    fontWeight: 500,
    height: 40,
    color: '#839eaf',
  },
  child:{
    flex:1,
},
};

export default EditTimesheet;
