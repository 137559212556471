import { Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import TableLoading from "../../../Other/Skeletons/TableLoading";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import useUnmatchedJobsJp from "../../../../customHooks/Jobs/useUnmatchedJobsJP";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { Link } from "react-router-dom";

const styles = {
  sort: {
    backgroundColor: "#fff",
    border: "none",
    margin: 0,
    padding: 0,
  },
};

const format_job_time_length = (jtl, jtl_qtty) => {
  if (jtl === "Piece of Work") {
    return "Piece of Work";
  }
  if (jtl_qtty === 1) {
    return `${1} ${jtl}`;
  } else return `${jtl_qtty} ${jtl}s`;
};

const UnmatchedTable = ({
  filters,
  page,
  set_page,
  company_name,
  status,
  set_status,
  ...props
}) => {
  const [sort_by, set_sort_by] = useState("-start_date");

  const {
    data: jobs_data,
    isLoading,
    fetchingJobs,
  } = useUnmatchedJobsJp(page, filters, status, company_name, sort_by);
  if (isLoading || fetchingJobs) return <TableLoading />;

  const handle_page = (e, value) => {
    e.preventDefault();
    set_page(value);
  };

  return (
    <div>
      <table className="recruiter-table">
        <thead>
          <tr>
            <th className="align-left-col">
              {" "}
              Position
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "position__name")
                    set_sort_by("-position__name");
                  else set_sort_by("position__name");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th className="align-left-col">
              {" "}
              Company
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "company__name")
                    set_sort_by("-company__name");
                  else set_sort_by("company__name");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>
            <th className="align-left-col"> # of Hires </th>

            <th className="">
              {" "}
              Start
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "start_date") set_sort_by("-start_date");
                  else set_sort_by("start_date");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th className="">Job Length</th>

            <th>
              {" "}
              Date posted
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "created_at")
                    set_sort_by("-created_at");
                  else set_sort_by("created_at");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th>
              {" "}
              Hourly Rate
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "rate_per_hour")
                    set_sort_by("-rate_per_hour");
                  else set_sort_by("rate_per_hour");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th>
              {" "}
              Commission
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "platform_commision")
                    set_sort_by("-platform_commision");
                  else set_sort_by("platform_commision");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th className="align-left-col">
              {" "}
              Hire/Rented
              <button
                style={styles.sort}
                onClick={(e) => {
                  e.preventDefault();
                  if (sort_by === "job_type") set_sort_by("-job_type");
                  else set_sort_by("job_type");
                }}
              >
                <UnfoldMoreIcon fontSize="inherit" />
              </button>
            </th>

            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {jobs_data.results.jobs_data.map((job) => (
            <tr key={job.id}>
              <td className="font-14"> 
                <a className="text-decoration-none text-dark" href={`/listing/${job.id}`} target="_blank">
                  {job.position.name} 
                </a>
                </td>
              <td className="align-left-col font-14">
                <a className="text-decoration-none text-dark" href={`approvallist/${job.company.id}`} target="_blank">
                  {job.company.name} 
                </a>
              </td>
              <td className="font-14">
                {" "}
                {job.nr_matches}/{job.nr_employees_wanted}
              </td>
              <td className="font-14">
                {" "}
                {job.start_date
                  ? new Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    }).format(Date.parse(job.start_date))
                  : "-"}
              </td>
              <td className="font-14">
                {" "}
                {format_job_time_length(
                  job.job_time_length,
                  job.job_time_length_quantity
                )}{" "}
              </td>
              <td className="font-14">
                {" "}
                {job.created_at
                  ? new Intl.DateTimeFormat("en-US", {
                      dateStyle: "medium",
                    }).format(Date.parse(job.created_at))
                  : "-"}
              </td>
              <td className="font-14">
                {job.rate_per_hour.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              <td className="font-14">
                {" "}
                {(job.platform_commision * 100).toFixed(1)}%{" "}
              </td>
              <td className="font-14"> {job.job_type}</td>
              <td className="font-14">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#editListingModal"
                  className="invisible-button"
                  onClick={(e) => {
                    e.preventDefault();
                    props.set_editing(job);
                  }}
                >
                  <EditIcon />
                </button>

                <Link style={{ color: "black" }} to={`/listing/${job.id}`}>
                  <CallMadeIcon fontSize="small" />
                </Link>
              </td>
            </tr>
          ))}

          {!jobs_data?.results.stats ? (
            "Loading Statistics..."
          ) : (
            <tr>
              <td style={{ fontWeight: 700 }} className="align-left-col">
                {jobs_data?.results.stats.number_of_companies} companies
              </td>
              <td style={{ fontWeight: 700 }}>
                {jobs_data?.results.stats.number_of_positions} positions
              </td>
              <td className=""></td>
              <td className=""></td>
              <td className=""></td>
              <td className=""></td>
            </tr>
          )}
        </tbody>
      </table>
      <Box
        className="pagination-div"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Pagination
          count={Math.ceil(jobs_data.count / 10)}
          page={page}
          onChange={handle_page}
        />
      </Box>
    </div>
  );
};

export default UnmatchedTable;
