import axios from "axios"
import base_url from "./util"

const get_token = () => {
  const token = window.localStorage.getItem('token')
  return `token ${token}`
}

export const get_job_seekers = async (
  page,
  filter,
  name_pattern,
  interested_positions,
  countries,
  provinces,
  cities,
  sort_by
) => {
    const config = {
      headers: { authorization: get_token()},
    }
    const {data} = await axios.get(
      `${base_url}jobseekers/?page=${page}&approved_by_system=${filter}&name=${name_pattern}&interested_positions=${interested_positions}&countries=${countries}&provinces=${provinces}&cities=${cities}&sort_by=${sort_by}`, 
      config)
    return data
}

export const get_job_seeker_detail = async (id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}jobseekers/${id}`, config)
  return data
}

export const get_job_seeker_experiences = async (id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}approvalexperience/${id}`, config)
  return data
}

export const get_job_seeker_educations = async (id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}approvaleducation/${id}`, config)
  return data
}

export const get_job_seeker_licenses = async (id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}approvallicense/${id}`, config)
  return data
}

export const get_job_seeker_interested_positions = async (id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}approvalinterestedpositions/${id}`, config)
  return data
}

export const get_job_seeker_certifications = async (id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}approvalcertification/${id}`, config)
  return data
}

export const get_companies = async (page, filter, name_pattern, sort_by) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}allcompanies/?page=${page}&is_approved=${filter}&name=${name_pattern}&sort_by=${sort_by}`, config)
  return data
}

export const get_company_detail= async (id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.get(`${base_url}approvalcompany/${id}`, config)
  return data
}

export const approve_job_seeker = async (id, notes) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}jobseekers/approve/${id}`,{notes},config)
  return data
}

export const decline_job_seeker = async (id, notes) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}jobseekers/decline/${id}`,{notes},config)
  return data
}

export const reject_job_seeker = async (id, notes) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}jobseekers/reject/${id}`,{notes},config)
  return data
}

export const approve_company = async (id, notes) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}company/approve/${id}`,{notes},config)
  return data
}

export const decline_company = async (id, notes) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}company/decline/${id}`,{notes},config)
  return data
}

export const continue_later = async (id,notes) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.patch(`${base_url}continue_later/`,{candidate_id:id,notes},config)
  return data
}

export const get_approval_history =  async (user_id) => {
  const config = {
    headers: { authorization: get_token()},
  }

  const {data} = await axios.get(`${base_url}accounts/approval-history/${user_id}`, config)
  return data
}

export const convert_company_to_job_seeker = async (company_id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.post(`${base_url}recruiters/convert-company-to-job-seeker/`,{company_id},config)
  return data
}

export const convert_job_seeker_to_company = async (js_id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.post(`${base_url}recruiters/convert-job-seeker-to-company/`,{js_id},config)
  return data
}

export const reset_rating = async (experience_id) => {
  const config = {
    headers: { authorization: get_token()},
  }
  const {data} = await axios.delete(`${base_url}reset-experience-rating/${experience_id}`,config)
  return data
}

