import axios from "axios"
import base_url from "./util"

const get_token = () => {
  const token = window.localStorage.getItem('token')
  return `token ${token}`
}

export const get_job_seekers_communication = async (
    position_condition,
    interested_positions,
    salary_comparison,
    salary_value,
    // address
    address_condition,
    cities,
    provinces,
    countries,
    // languages
    selected_languages,
    languages_condition,
    // certifications
    selected_certifications,
    certifications_condition,
    // licenses
    selected_licenses,
    licenses_condition,
    // status,
    selected_status,
    selected_experience_condition
  ) => {
    const config = {
      headers: { authorization: get_token()},
    }
    const {data} = await axios.get(
      `${base_url}recruiters/communication-filter-jobseekers/?interested_positions=${interested_positions}&position_condition=${position_condition}&salary_comparison=${salary_comparison}&salary_value=${salary_value || 0}&address_condition=${address_condition}&cities=${cities}&provinces=${provinces}&countries=${countries}&selected_languages=${selected_languages}&languages_condition=${languages_condition}&selected_certifications=${selected_certifications}&certifications_condition=${certifications_condition}&selected_licenses=${selected_licenses}&licenses_condition=${licenses_condition}&selected_status=${selected_status}&selected_experience_condition=${selected_experience_condition}`, 
      config)
    return data
}

export const send_emails_job_seekers = async (filter_payload) => {
  const config = {
    headers: { authorization: get_token()},
  }
  // console.log(filter_payload.position_condition);
  const {data} = await axios.post(
    `${base_url}recruiters/send-emails-job-seekers/?interested_positions=${filter_payload.interested_positions}&position_condition=${filter_payload.position_condition}&salary_comparison=${filter_payload.salary_comparison}&salary_value=${filter_payload.salary_value || 0}&address_condition=${filter_payload.address_condition}&cities=${filter_payload.cities}&provinces=${filter_payload.provinces}&countries=${filter_payload.countries}&selected_languages=${filter_payload.selected_languages}&languages_condition=${filter_payload.languages_condition}&selected_certifications=${filter_payload.selected_certifications}&certifications_condition=${filter_payload.certifications_condition}&selected_licenses=${filter_payload.selected_licenses}&licenses_condition=${filter_payload.licenses_condition}&selected_status=${filter_payload.selected_status}&selected_experience_condition=${filter_payload.selected_experience_condition}`
    ,{email_body:filter_payload.email_body, email_subject: filter_payload.email_subject}
    ,config)
  
  return data
}

export const get_autocomplete_cities = async (provinces, city_pattern) => {
    const {data} = await axios.get(`${base_url}autocomplete-city/?provinces=${provinces}&city_pattern=${city_pattern}`)
    return data
}

export const get_autocomplete_provinces = async (countries, province_pattern) => {
    const {data} = await axios.get(`${base_url}autocomplete-province/?countries=${countries}&province_pattern=${province_pattern}`)
    return data
}

export const get_autocomplete_languages = async (pattern) => {
  const {data} = await axios.get(`${base_url}autocomplete-languages/?language_pattern=${pattern}`)
  return data
}

export const get_autocomplete_certifications = async (pattern) => {
  const {data} = await axios.get(`${base_url}autocomplete-certifications/?certification_pattern=${pattern}`)
  return data
}

export const get_autocomplete_licenses = async (pattern) => {
  const {data} = await axios.get(`${base_url}autocomplete-licenses/?license_pattern=${pattern}`)
  return data
}
