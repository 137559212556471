import React, { useEffect, useRef, useState } from 'react'
import useCommissionHistory from '../../../../customHooks/Jobs/useCommissionHistory';
import moment from 'moment';
import useUpdateListing from '../../../../customHooks/Jobs/useUpdateListing';
import HistoryIcon from '@material-ui/icons/History';


const EditListingModal = ({curr_listing}) => {

    const [error, set_error] = useState(false)
    const do_update_listing = useUpdateListing()

    const {data: commission_history, isLoading: loading_commission_history} = useCommissionHistory(curr_listing.id)
    const [show_history, set_show_history] = useState(false)
    const [new_commision, set_new_commission] = useState(0);
    const close_button_ref = useRef(null)

    const check_form_valid = () => {
        const valid = new_commision >= 0 && new_commision <= 1;
        set_error(!valid);
        return (valid)
    }

    const handleUpdate = (e) => {
        e.preventDefault();

        const job_data = {platform_commision: new_commision};
        const payload = {job_id: curr_listing.id, job_data}

        if (check_form_valid()){
            do_update_listing.mutate(payload)
            close_button_ref.current.click()
        }
    }

    useEffect(() => {
        if (curr_listing)
            set_new_commission(curr_listing?.platform_commision);

    }, [curr_listing]);


    const tooggle_history = (e) => {
        e.preventDefault();
        set_show_history(!show_history)
    }

    if (curr_listing?.position){
      return (
        <div className="modal fade" id="editListingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div  className="modal-content custom-modal-content">
                <form onSubmit={handleUpdate}>

                <div className="modal-body">
                    <div>

                        {commission_history?.length > 0 &&
                            <span 
                                onClick={tooggle_history} 
                                className='float-right span-button'
                                title="History"
                            >
                            <HistoryIcon fontSize='medium' />
                            </span>
                        }
                        <p className='input-label'>{curr_listing.position.name} - {curr_listing.company.name}  </p>

                        {(loading_commission_history || commission_history.length === 0 || show_history === false) ? null : (
                        <div className='history-table-div'>
                          <table>
                            <thead>
                                <tr>
                                    <th>Staff</th>
                                    <th className='align-right-col'>Date</th>
                                    <th className='align-right-col'>Old Rate</th>
                                    <th className='align-right-col' >New Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                              {commission_history.map( history => {
                                return (
                                  <tr key={history.id}>
                                    <td>{history.recruiter}</td>
                                    <td className='align-right-col'>{moment(history.date).format('ll')}</td>
                                    <td className='align-right-col'>{(history.old_commission_value * 100).toFixed(0)} %</td>
                                    <td className='align-right-col'>{(history.new_commission_value * 100).toFixed(0)} %</td>
                                  </tr>
                                )
                              })}
                                
                            </tbody>
                          </table>
                        </div>
                        )}
                        
                        <p className='input-label'>Platform commision</p>
                        <input 
                          className='custom-modal-input-field'
                          value={new_commision} 
                          onChange={(e)=>set_new_commission(e.target.value)} 
                        />
                         - <span>{ (new_commision * 100).toFixed(1)}% </span>
                    </div>  
                    {error && <span style={{float:'right'}} className='error-label'>Commision should be between 0 and 1</span>}
                </div>
                <p></p>
                <div className="modal-footer">
                    <button ref={close_button_ref} type="button" className="custom-modal-reset-button" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className='custom-modal-ok-button'>Update</button>
                </div>
            </form>
            </div>
        </div>
        </div>
    )
    }
    
    else return null
}


const styles = {
    child:{
        flex:1,
    },
 
  
    
}

export default EditListingModal;