import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from '../../global_state'
import { handle_register_admin } from "../../services/user";


const useRegisterAdmin= (payload) => {

  const set_error = useSetRecoilState(error_message)
  const set_success = useSetRecoilState(success_message)

  return  useMutation((payload)=>handle_register_admin(payload), {
      onSuccess: data => {
        set_success('Successfully registered as Admin!')
        // tokenize_meme(data.token)
        setTimeout(() => {
          set_success('')
        }, 4000);
      },
      onError: () => {
        set_error('Invitation is not valid!')
        setTimeout(() => {
          set_error('')
        }, 4000);
      }
  })
}

export default useRegisterAdmin;