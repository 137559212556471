import React from 'react';

// import './style.css'

const ModalListJS = ({modal_title, users}) => {


  return (
    <div
      className="modal fade"
      id="modalListJS"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div
          style={{borderRadius: 18, marginTop: '20%', padding: 10}}
          className="modal-content">
          
          <p className='form-header'>{modal_title}</p>

          <ul>
              {users?.map(u => (
                  <li key={u.id}>
                      <a className='text-decoration-none text-dark' target="_blank" href={`/js/approvallist/${u.id}`}> {u.name}</a>
                  </li>
              ))}
          </ul>

          <div className="modal-footer">
            <button
            style={styles.reset}
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal">
            Close
            </button>
        </div>
        </div>
        
      </div>
    </div>
  );
};

const styles = {
  ok: {
    backgroundColor: '#249afa',
    border: 'none',
    width: '50%',
    color: 'white',
    borderRadius: 4,
    fontWeight: 500,
    height: 40,
  },
  info: {
    color: '#445566',
    fontWeight: 'bold',
  },
  input_field: {
    border: 'solid 1px #aabbcc',
    borderRadius: 18,
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 10,
    width: '100%',
    marginBottom: 20,
  },
  reset: {
    background: 'none',
    border: 'none',
    width: '25%',
    fontWeight: 500,
    height: 40,
    color: '#839eaf',
  },
};

export default ModalListJS;
