
import { useMutation } from "react-query"
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from "../../../global_state";
import { continue_later } from "../../../services/approvals";


const useContinueLater = (notes) => {
    const set_success = useSetRecoilState(success_message)
    const set_error = useSetRecoilState(error_message)
    return useMutation( (id)=>continue_later(id, notes), {
        onSuccess: data => {
            set_success('Saved')
            setTimeout(() => {
                set_success('')
              }, 5000);
        },
        onError: () => {
            set_error('Problem Saving!')
            setTimeout(() => {
              set_error('')
            }, 4000);
          }
    })
}

export default useContinueLater;