
import { useMutation, useQueryClient } from "react-query"
import { useSetRecoilState } from "recoil";
import { reset_rating } from "../../../services/approvals";
import { error_message, success_message } from "../../../global_state";


const useResetRating = () => {
  const queryClient = useQueryClient()

    const set_success = useSetRecoilState(success_message)
    const set_error = useSetRecoilState(error_message)
    return useMutation( (exprience_id)=>reset_rating(exprience_id), {
        onSuccess: data => {
            set_success('Successfully resetted rating!')
            setTimeout(() => {
                set_success('')
              }, 5000);

            queryClient.invalidateQueries('get_job_seeker_experiences')
        },
        onError: () => {
            set_error('There was a problem! Please try again later!')
            setTimeout(() => {
              set_error('')
            }, 4000);
          }
    })
}

export default useResetRating;