import React, { useEffect, useState } from 'react';
import useDeactivateRecruiter from '../../customHooks/Admin/useDeactivateRecruiter';
import useActivateRecruiter from '../../customHooks/Admin/useActivateRecruiter';
import useEditStaff from '../../customHooks/Admin/useEditStaff';
import useResendInviation from '../../customHooks/Admin/useResendInvitation';
// import './style.css'

const EditStaff = ({invitation}) => {
  const [role, set_role] = useState('');
  const [staff_level, set_staff_level] = useState(1);
  const do_edit_staff = useEditStaff()
  const do_resend_invitation = useResendInviation();
  const do_deactivate_recruiter = useDeactivateRecruiter();
  const do_activate_recruiter = useActivateRecruiter();
  const handle_edit = e => {
    e.preventDefault();
    const payload = {
        is_superuser: role === 'Admin' ,
        is_recruiter: role === 'Recruiter' ,
        email: invitation.email,
        staff_level
    }
    do_edit_staff.mutate(payload)
  };

  const handle_deactivate = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure to deactivate recruiter account?');
    if (ok){
      do_deactivate_recruiter.mutate(invitation.email);
    }
  }

  const handle_activate = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure to activate recruiter account?');
    if (ok){
      do_activate_recruiter.mutate(invitation.email);
    }
  }
 
  const handle_resend = e => {
    e.preventDefault();
    do_resend_invitation.mutate(invitation.id);
  };

  useEffect(()=>{
    if(invitation){
      set_role(invitation.role);
      set_staff_level(invitation.staff_level);
    }
  },[invitation])

  return (
    <div
      className="modal fade"
      id="editStaffModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div
          style={{borderRadius: 18, marginTop: '20%', padding: 10}}
          className="modal-content">
          <form onSubmit={handle_edit}>
            <div className="modal-body">
              <p className="input-label">Email</p>
              {!invitation.user && (
                <button
                  style={{float: 'right'}}
                  disabled={do_resend_invitation.isLoading}
                  onClick={e => handle_resend(e, invitation.id)}
                  className="custom_button">
                  {do_resend_invitation.isLoading ? 'Resending...' : 'Resend'}
                </button>
              )}
              <p style={{color: 'grey'}}>
                {invitation?.user ? invitation.user : 'Not joined'}
              </p>
              
              {
              invitation?.status === 'Deactivated'
                ?
                <button
                  onClick={handle_activate}
                  type="button"
                  className="btn btn-success btn-sm float-right"
                  // data-bs-dismiss="modal"
                >
                  Activate recruiter
                </button>
                :
                <button
                  onClick={handle_deactivate}
                  type="button"
                  className="btn btn-danger btn-sm float-right"
                  // data-bs-dismiss="modal"
                >
                  Deactivate recruiter
                </button>
              }

              <p className="input-label">Role ({invitation?.user_role}) </p>
              {/* <input style={styles.input_field} /> */}
              <select
                onChange={e => set_role(e.target.value)}
                value={role}
                style={styles.input_field}>
                <option value="Recruiter">Recruiter</option>
                <option value="Admin">Admin</option>
              </select>
              {role !== 'Admin' && 
                <div>
                  <label className='input-label'>Recruiter Level</label>
                  <select
                    onChange={e => set_staff_level(Number(e.target.value))}
                    value={staff_level}
                    style={styles.input_field}
                  >
                  <option value="1">1</option>
                  <option value="2">2</option>
              </select>
                </div>
              }
              
            </div>
            <div className="modal-footer">             
              <button
                style={styles.reset}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                >
                Close
              </button>
              {invitation.user &&
              <button type="submit" style={styles.ok} className="custom_button">
                Save
              </button>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  ok: {
    backgroundColor: '#249afa',
    border: 'none',
    width: '50%',
    color: 'white',
    borderRadius: 4,
    fontWeight: 500,
    height: 40,
  },
  info: {
    color: '#445566',
    fontWeight: 'bold',
  },
  input_field: {
    border: 'solid 1px #aabbcc',
    borderRadius: 18,
    padding: 10,
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 10,
    width: '100%',
    marginBottom: 20,
  },
  reset: {
    background: 'none',
    border: 'none',
    width: '25%',
    fontWeight: 500,
    height: 40,
    color: '#839eaf',
  },
};

export default EditStaff;
