
import { useQuery } from "react-query"
import { get_all_countries } from "../../services/jobs";


const useAllCountries = () => {
return useQuery('get_all_countries', () => get_all_countries())
}

export default useAllCountries;
