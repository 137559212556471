import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import { error_message, success_message } from '../../global_state'
import { add_new_license } from "../../services/jobs";


const useAddLicense = () => {
  const set_error = useSetRecoilState(error_message)
  const set_success = useSetRecoilState(success_message)

  return  useMutation((license_name)=>add_new_license(license_name), {
      onSuccess: data => {
        set_success('Successfully added license!')
        setTimeout(() => {
          set_success('')
        }, 4000);
      },
      onError: () => {
        set_error(`License already exists!`)
        setTimeout(() => {
          set_error('')
        }, 4000);
      }
  })
}

export default useAddLicense;