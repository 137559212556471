import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import useSendInvitation from '../../customHooks/Admin/useSendInvitation';
import useUser from '../../customHooks/AuthHooks/useUser';
import CornerMenu from '../Auth/CornerMenu';
import TableLoading from '../Other/Skeletons/TableLoading';
import EditStaff from './EditStaff';
import InviteStaff from './InviteStaff';
import InviteStaffTable from './InviteStaffTable';

const InviteStaffPage = () => {
  const [page, set_page] = useState(1);
  const {data: user_data, isLoading} = useUser();
  const [editing, set_editing] = useState({});
  const do_send_invitation = useSendInvitation();
  if (isLoading) {
    return <TableLoading/>;
  }

  if (user_data.is_superuser === true) {
    return (
      <div className="logged-in-container">
        <CornerMenu />

        <p className="form-header">Admin Settings</p>
        <button
          data-bs-toggle="modal"
          data-bs-target="#inviteModal"
          style={{paddingLeft: 20, paddingRight: 20, width: 200}}
          className="custom_button">
          Invite
        </button>

        <br />
        <br />

        <InviteStaffTable
          page={page}
          set_page={set_page}
          set_editing={set_editing}
        />
        <InviteStaff do_send_invitation={do_send_invitation} />
        <EditStaff invitation={editing} />
      </div>
    );
  } else {
    return <Redirect push to="/" />;
  }
};

export default InviteStaffPage;
