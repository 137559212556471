import React, {useEffect, useState} from 'react'

const DeclinedProblems = ({notes}) => {
    const [problems, set_problems] = useState([])
  
    useEffect(() => {
      if (notes) {
        let temp_problems = []
        for (let [key, value] of Object.entries(notes)) {
          if (value.status === 0) {
            // if key exists, push value to problems
            temp_problems.push(value.title)            
          }
        }
        set_problems(temp_problems)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes])
    
    if (problems.length > 0)return (
      <div style={{backgroundColor:"rgb(221, 96,105)", padding:20, color:'white', borderRadius:10, marginBottom:25}}>
        <p className='h4'>Problems</p>
        <ul>
          {problems.map(problem => {
            return (
              <li key={problem}>{problem}</li>
            )
          })}
        </ul>
      </div>
    )
    return null
}


export default DeclinedProblems