import { useQuery } from "react-query"
import { get_job_seekers_communication } from "../../services/communication";


const useFilterJSCommunication = (
    // interested positions
    position_condition,
    interested_positions,
    salary_comparison,
    salary_value,
    // address
    address_condition,
    cities,
    provinces,
    countries,
    // languages
    selected_languages,
    languages_condition,
    // certifications
    selected_certifications,
    certifications_condition,
    // licenses
    selected_licenses,
    licenses_condition,
    // status
    selected_status,
    selected_experience_condition
    ) => {
    return useQuery(
        ['get_job_seekers_communication', 
        
            // interested positions
            position_condition,
            interested_positions,
            salary_comparison,
            salary_value,
            // address
            address_condition,
            cities,
            provinces,
            countries,
            // languages
            selected_languages,
            languages_condition,
            // certifications
            selected_certifications,
            certifications_condition,
            // licenses
            selected_licenses,
            licenses_condition,
            // status
            selected_status,
            selected_experience_condition
        ], 
        
        ()=>get_job_seekers_communication(// interested positions
                position_condition,
                interested_positions,
                salary_comparison,
                salary_value,
                // address
                address_condition,
                cities,
                provinces,
                countries,
                // languages
                selected_languages,
                languages_condition,
                // certifications
                selected_certifications,
                certifications_condition,
                // licenses
                selected_licenses,
                licenses_condition,
                // status
                selected_status,
                selected_experience_condition
            )
        )
}

export default useFilterJSCommunication;
